import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getItem } from 'helpers/localstorage.helpers'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting.thunk'
import { SHOW_SIDE_NAV } from 'constants/index'
import { brandLogoNew, earthIcon } from 'components/ui-kit/Icons/png/index'
import './footer.scss'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { siteInfo } = useSelector(state => state.user)
  const showSideNav = getItem(SHOW_SIDE_NAV)

  useEffect(() => {
    dispatch(getAllSiteinfo())
  }, [])

  return (
    <footer className={`mt-3 ${!showSideNav ? 'sidebar-close' : ''}`}>
      <div className='footer-top'>
        <div className='content-wrapper custom-spacer'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-12 order-3 order-lg-0'>
              <div className='footer-about my-3'>
                <div className='footer-logo'>
                  <img src={brandLogoNew} />
                </div>
                <p className='m-0'>
                  {t('copyrightText')}
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 order-0 order-lg-1'>
              <div className='footer-linking d-flex justify-content-between my-3'>
                <h4 className='m-0'>{t('platform')}</h4>
                <ul className='m-0'>
                  <li>
                    <NavLink to='#'>{t('support')}</NavLink>
                  </li>
                  <li>
                    <NavLink to='#'>{t('faq')}</NavLink>
                  </li>
                  <li>
                    <NavLink to='#'>{t('partnershipProgram')}</NavLink>
                  </li>
                  <li>
                    <a target='_blank' href='https://medium.com/@Wofe' rel='noreferrer'>{t('blog')}</a>
                  </li>
                  <li>
                    <NavLink to='#'>{t('helpCenter')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 order-1 order-lg-2'>
              <div className='footer-linking d-flex justify-content-between my-3'>
                <h4 className='m-0'>{t('aboutus')}</h4>
                <ul className='m-0'>
                  <li>
                    <a href='https://whitepaper.wofe.io/' target='_blank' rel='noreferrer'>Whitepaper</a>
                  </li>
                  {siteInfo?.filter(el => el.cmsSection === 'aboutUs')?.map(el =>
                    <li key={el.id}>
                      <NavLink to={`/site-info/${el.slug}`}>{el.title}</NavLink>
                    </li>)}
                </ul>
              </div>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 order-2 order-lg-3'>
              <div className='footer-linking d-flex justify-content-between my-3'>
                <h4 className='m-0'>{t('community')}</h4>
                <ul className='m-0'>
                  <li>
                    <a target='_blank' href='https://x.com/wofeio' rel='noreferrer'>{t('twitter')}</a>
                  </li>
                  <li>
                    <a target='_blank' href='https://t.me/wofe_announcements/7' rel='noreferrer'>{t('Telegram')}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='language-wrap d-flex justify-content-lg-end'>
            <div className='dropdown language-btn'>
              <button
                className='btn btn-secondary dropdown-toggle shadow-none'
                type='button'
                id='dropdownMenuButton1'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <img src={earthIcon} alt='Earth' height='15' width='15' />
                {t('english')}
              </button>
              <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                <li>
                  <a className='dropdown-item' href='#'>
                    {t('hindi')}
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    {t('french')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom-linking d-md-flex justify-content-center align-items-center'>
        <p className='m-0'>
          {t('support')}
          <NavLink to='#'>support@wofe.app</NavLink>
        </p>
        <p className='m-0'>
          {t('partners')}
          <NavLink to='#'>partners@wofe.app</NavLink>
        </p>
        <p className='m-0'>
          {t('legal')}
          <NavLink to='#'>legal@wofe.app</NavLink>
        </p>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
