import { linesNum } from './core/gameUI'

const secondIndex = (arr) => Math.floor(Math.random() * (arr.length - 1))

// const numberOfRows = 8

const data = [
  [
    [
      197.59999999999997,
      65,
      -23.799999999999997,
      -19,
      174.8,
      -5.200000000000003,
      -18.400000000000006
    ],
    [
      -100,
      194.59999999999997,
      95.6,
      39.19999999999999,
      193.39999999999998,
      -30.400000000000006,
      194
    ],
    [
      179.59999999999997,
      174.2,
      0.7999999999999972,
      0.20000000000000284,
      -0.4000000000000057,
      1.3999999999999915,
      68
    ],
    [
      141.2,
      -31.60000000000001,
      181.39999999999998,
      -22,
      -3.4000000000000057,
      -58.6,
      199.39999999999998
    ],
    [
      19.39999999999999,
      180.8,
      182,
      155,
      23,
      44.599999999999994,
      186.8
    ],
    [
      25.39999999999999,
      143,
      167,
      -96.4,
      -4,
      180.2,
      197
    ],
    [
      6.200000000000003,
      -19.60000000000001,
      26,
      6.799999999999997,
      24.19999999999999,
      24.799999999999997,
      167.59999999999997
    ],
    [
      142.39999999999998,
      169.39999999999998,
      188.59999999999997,
      20.599999999999994,
      104,
      188,
      109.4
    ],
    [
      177.2,
      12.799999999999997,
      -58,
      5.599999999999994,
      107.6,
      23.599999999999994,
      17.599999999999994
    ]
  ],
  [
    [
      65.6,
      156.2,
      -19,
      37.400000000000006,
      -52.6,
      -1,
      168.2
    ],
    [
      65,
      -5.200000000000003,
      -26.799999999999997,
      176,
      -6.400000000000006,
      171.8,
      -51.4
    ],
    [
      140.6,
      -32.2,
      29.599999999999994,
      23.599999999999994,
      83.6,
      -72.4,
      -9.400000000000006
    ],
    [
      -15.400000000000006,
      -26.200000000000003,
      98,
      2,
      155.6,
      -83.2,
      99.19999999999999
    ],
    [
      170,
      -82,
      -11.200000000000003,
      35,
      -99.4,
      -23.799999999999997,
      -98.2
    ],
    [
      179.59999999999997,
      10.399999999999991,
      -85,
      -55,
      -11.799999999999997,
      -53.2,
      170.59999999999997
    ],
    [
      180.2,
      22.39999999999999,
      -10.600000000000009,
      99.79999999999998,
      172.39999999999998,
      11,
      11.599999999999994
    ],
    [
      -34,
      143,
      38.599999999999994,
      -33.400000000000006,
      84.19999999999999,
      -1.6000000000000085,
      -97.6
    ],
    [
      94.4,
      42.19999999999999,
      26.599999999999994,
      24.799999999999997,
      2.5999999999999943,
      102.79999999999998,
      15.199999999999989
    ],
    [
      93.19999999999999,
      -2.799999999999997,
      15.799999999999997,
      27.19999999999999,
      25.39999999999999,
      36.19999999999999,
      -8.799999999999997
    ]
  ],
  [
    [
      57.19999999999999,
      -35.2
    ],
    [
      119.6,
      55.400000000000006,
      -49,
      -17.799999999999997,
      -82.6,
      -23.200000000000003,
      -19
    ],
    [
      86.6,
      139.39999999999998,
      -50.800000000000004,
      -52,
      -70.6,
      -92.8,
      -81.4
    ],
    [
      -14.799999999999997,
      144.2,
      -2.200000000000003,
      -1.6000000000000085,
      77.6,
      90.19999999999999,
      15.799999999999997
    ],
    [
      -72.4,
      155,
      133.39999999999998,
      -28,
      150.79999999999998,
      137.6,
      -19.60000000000001
    ],
    [
      137,
      -95.2,
      95,
      88.4,
      21.19999999999999,
      -71.8,
      -20.799999999999997
    ],
    [
      78.19999999999999,
      14.599999999999994,
      147.79999999999998,
      3.799999999999997,
      -29.799999999999997,
      76.4,
      -89.2
    ],
    [
      93.19999999999999,
      -73.6,
      94.4,
      72.79999999999998,
      -47.800000000000004,
      149.6,
      145.39999999999998
    ],
    [
      -80.2,
      155.6,
      141.79999999999998,
      3.200000000000003,
      152,
      22.39999999999999,
      170.59999999999997
    ],
    [
      33.19999999999999,
      12.200000000000003,
      27.19999999999999,
      8,
      -93.4,
      82.4,
      -74.2
    ],
    [
      113.6,
      143.6,
      30.799999999999983,
      -58,
      159.8,
      144.79999999999998,
      18.19999999999999
    ]
  ],
  [
    [
      -85.6,
      143,
      -85,
      -82.6,
      -14.200000000000003
    ],
    [
      -46,
      74,
      77.6,
      155,
      -25.60000000000001,
      132.79999999999998,
      125
    ],
    [
      14,
      -43.6,
      -2.200000000000003,
      18.799999999999997,
      81.19999999999999,
      -38.2,
      129.79999999999998
    ],
    [
      153.2,
      12.799999999999997,
      87.79999999999998,
      -0.4000000000000057,
      -31.60000000000001,
      0.7999999999999972,
      123.19999999999999
    ],
    [
      135.2,
      2.5999999999999943,
      -83.8,
      81.79999999999998,
      156.8,
      20.599999999999994,
      -1
    ],
    [
      -94,
      -28,
      -27.400000000000006,
      -5.799999999999997,
      38.599999999999994,
      158.59999999999997,
      124.4
    ],
    [
      45.79999999999998,
      146.6,
      -9.400000000000006,
      141.79999999999998,
      -82,
      47,
      154.39999999999998
    ],
    [
      -4.6000000000000085,
      -52,
      27.799999999999997,
      72.79999999999998,
      -19.60000000000001,
      132.2,
      -73.6
    ],
    [
      110,
      -6.400000000000006,
      -29.200000000000003,
      160.39999999999998,
      139.39999999999998,
      134.6,
      20
    ],
    [
      177.2,
      -86.8,
      126.79999999999998,
      8.599999999999994,
      175.39999999999998,
      24.19999999999999,
      115.4
    ],
    [
      83,
      -60.4,
      15.799999999999997,
      78.79999999999998,
      -26.200000000000003,
      29.599999999999994,
      29
    ],
    [
      79.4,
      23.599999999999994,
      73.4,
      66.19999999999999,
      -49,
      -17.200000000000003,
      123.79999999999998
    ]
  ],
  [
    [
      -73.6,
      198.2,
      -54.4,
      56,
      -86.2,
      -59.2,
      -25
    ],
    [
      47.599999999999994,
      44.599999999999994,
      -26.799999999999997,
      48.19999999999999,
      -53.2,
      -79.6,
      -13.600000000000009
    ],
    [
      -76,
      132.2,
      68,
      116.6,
      111.19999999999999,
      123.79999999999998,
      122.6
    ],
    [
      -89.2,
      -61,
      50,
      -32.2,
      36.19999999999999,
      -29.799999999999997,
      -97
    ],
    [
      152,
      127.4,
      -24.400000000000006,
      -83.8,
      47,
      120.19999999999999,
      -23.799999999999997
    ],
    [
      87.19999999999999,
      78.79999999999998,
      71,
      -17.200000000000003,
      129.79999999999998,
      -12.400000000000006,
      126.19999999999999
    ],
    [
      109.4,
      145.39999999999998,
      96.19999999999999,
      -45.4,
      122,
      -14.799999999999997,
      -19
    ],
    [
      -98.2,
      -15.400000000000006,
      17.599999999999994,
      65.6,
      125.6,
      4.3999999999999915,
      -13
    ],
    [
      74.6,
      62,
      99.19999999999999,
      -27.400000000000006,
      20.599999999999994,
      188.59999999999997,
      128
    ],
    [
      114.19999999999999,
      130.39999999999998,
      126.79999999999998,
      -5.200000000000003,
      -79,
      15.799999999999997,
      -23.200000000000003
    ],
    [
      129.2,
      52.400000000000006,
      146.6,
      75.79999999999998,
      150.79999999999998,
      30.19999999999999,
      75.19999999999999
    ],
    [
      -47.800000000000004,
      -49,
      15.199999999999989,
      138.79999999999998,
      78.19999999999999,
      135.79999999999998,
      9.799999999999997
    ],
    [
      136.39999999999998,
      11,
      26.599999999999994,
      25.39999999999999,
      66.19999999999999,
      29,
      -7
    ]
  ],
  [
    [
      11,
      89,
      -7,
      109.4,
      114.19999999999999
    ],
    [
      -77.2,
      1.3999999999999915,
      30.19999999999999,
      -35.2,
      -32.2,
      -14.200000000000003,
      -29.200000000000003
    ],
    [
      10.399999999999991,
      -63.4,
      -44.2,
      -46.6,
      -62.800000000000004,
      132.2,
      -2.799999999999997
    ],
    [
      -94,
      -38.800000000000004,
      -11.200000000000003,
      73.4,
      -33.400000000000006,
      -16.60000000000001,
      0.7999999999999972
    ],
    [
      132.79999999999998,
      -39.400000000000006,
      -40.6,
      -85.6,
      -92.8,
      56.599999999999994,
      -23.200000000000003
    ],
    [
      24.19999999999999,
      -3.4000000000000057,
      -22.60000000000001,
      -13,
      16.39999999999999,
      -7.6000000000000085,
      -37
    ],
    [
      14.599999999999994,
      -23.799999999999997,
      141.2,
      -19,
      110,
      4.3999999999999915,
      113
    ],
    [
      179,
      119.6,
      135.2,
      14,
      99.19999999999999,
      137,
      108.19999999999999
    ],
    [
      120.19999999999999,
      74,
      -83.2,
      139.39999999999998,
      25.39999999999999,
      138.2,
      127.4
    ],
    [
      -19.60000000000001,
      31.400000000000006,
      -16,
      60.19999999999999,
      135.79999999999998,
      -25,
      107.6
    ],
    [
      -40,
      32,
      138.79999999999998,
      -12.400000000000006,
      130.39999999999998,
      18.799999999999997,
      72.19999999999999
    ],
    [
      122,
      102.79999999999998,
      -93.4,
      -24.400000000000006,
      -13.600000000000009,
      59.599999999999994,
      0.20000000000000284
    ],
    [
      126.79999999999998,
      122.6,
      -25.60000000000001,
      66.19999999999999,
      -4.6000000000000085,
      111.79999999999998,
      -10
    ],
    [
      170.59999999999997,
      189.2,
      38.599999999999994,
      62,
      137.6
    ]
  ],
  [
    [
      -76,
      -32.8,
      -29.799999999999997,
      -7.6000000000000085,
      -26.200000000000003,
      -58.6
    ],
    [
      -29.200000000000003,
      92,
      -79.6,
      -83.2,
      93.19999999999999,
      58.400000000000006,
      -11.799999999999997
    ],
    [
      -62.2,
      -72.4,
      -60.4,
      92.6,
      101,
      6.200000000000003,
      109.4
    ],
    [
      87.19999999999999,
      -15.400000000000006,
      107.6,
      -13.600000000000009,
      111.19999999999999,
      107,
      -51.4
    ],
    [
      -98.2,
      83.6,
      168.2,
      -56.2,
      -71.2,
      -11.200000000000003,
      14
    ],
    [
      66.79999999999998,
      -17.799999999999997,
      -53.2,
      4.3999999999999915,
      -61.6,
      74,
      12.200000000000003
    ],
    [
      88.4,
      -97.6,
      50,
      101.6,
      -17.200000000000003,
      114.19999999999999,
      14.599999999999994
    ],
    [
      189.8,
      105.79999999999998,
      -27.400000000000006,
      66.19999999999999,
      177.2,
      114.79999999999998,
      -67
    ],
    [
      67.4,
      11,
      -63.4,
      41.599999999999994,
      54.79999999999998,
      -21.400000000000006,
      182.59999999999997
    ],
    [
      15.199999999999989,
      23,
      25.39999999999999,
      183.8,
      11.599999999999994,
      10.399999999999991,
      176
    ],
    [
      -23.200000000000003,
      118.4,
      167,
      113,
      72.79999999999998,
      -10.600000000000009,
      -73.6
    ],
    [
      98,
      -61,
      121.4,
      183.2,
      -53.800000000000004,
      -58,
      -14.799999999999997
    ],
    [
      32,
      56,
      29,
      197.59999999999997,
      82.4,
      166.39999999999998,
      -66.4
    ],
    [
      31.400000000000006,
      79.4,
      37.400000000000006,
      7.3999999999999915,
      50.599999999999994,
      -98.8,
      119
    ],
    [
      65,
      30.799999999999983,
      -93.4,
      -86.2,
      28.400000000000006,
      23.599999999999994
    ]
  ],
  [
    [
      84.79999999999998,
      41,
      61.400000000000006
    ],
    [
      158.59999999999997,
      114.19999999999999,
      -51.4,
      -27.400000000000006,
      60.19999999999999,
      -91,
      -29.799999999999997
    ],
    [
      -95.2,
      69.79999999999998,
      86,
      155.6,
      -23.799999999999997,
      -31,
      -98.2
    ],
    [
      -54.4,
      54.79999999999998,
      47,
      87.19999999999999,
      106.4,
      -30.400000000000006,
      104.6
    ],
    [
      113.6,
      102.19999999999999,
      100.4,
      -64,
      -23.200000000000003,
      -98.8,
      -4
    ],
    [
      -1.6000000000000085,
      -81.4,
      24.799999999999997,
      107,
      -90.4,
      98.6,
      17
    ],
    [
      110,
      176,
      -94,
      -94.6,
      -19,
      -65.2,
      37.400000000000006
    ],
    [
      11.599999999999994,
      -99.4,
      182,
      -35.8,
      95.6,
      121.4,
      104
    ],
    [
      21.799999999999997,
      153.2,
      23,
      120.79999999999998,
      99.79999999999998,
      22.39999999999999,
      51.79999999999998
    ],
    [
      8,
      23.599999999999994,
      99.19999999999999,
      3.799999999999997,
      102.79999999999998,
      2.5999999999999943,
      -100
    ],
    [
      -97.6,
      31.400000000000006,
      -3.4000000000000057,
      97.4,
      -21.400000000000006,
      3.200000000000003,
      166.39999999999998
    ],
    [
      62,
      184.39999999999998,
      66.19999999999999,
      -20.200000000000003,
      -87.4,
      -17.200000000000003,
      -86.8
    ],
    [
      108.79999999999998,
      81.79999999999998,
      60.79999999999998,
      -25,
      169.39999999999998,
      30.799999999999983,
      -9.400000000000006
    ],
    [
      27.799999999999997,
      168.8,
      144.79999999999998,
      161.59999999999997,
      98,
      20.599999999999994,
      -13
    ],
    [
      128,
      128.6,
      129.2,
      49.400000000000006,
      0.20000000000000284,
      65.6,
      162.2
    ],
    [
      21.19999999999999,
      143,
      123.79999999999998,
      167.59999999999997,
      96.19999999999999
    ]
  ],
  [
    [
      -63.4,
      -59.800000000000004,
      77,
      -3.4000000000000057,
      92,
      41
    ],
    [
      31.400000000000006,
      152.6,
      155.6,
      -77.8,
      -22,
      148.39999999999998,
      95
    ],
    [
      30.19999999999999,
      51.19999999999999,
      -82.6,
      87.19999999999999,
      9.799999999999997,
      -17.200000000000003,
      109.4
    ],
    [
      -16.60000000000001,
      106.4,
      -32.8,
      -58,
      -18.400000000000006,
      165.2,
      14
    ],
    [
      51.79999999999998,
      12.200000000000003,
      -47.2,
      114.19999999999999,
      -36.400000000000006,
      -54.4,
      -2.799999999999997
    ],
    [
      164,
      90.19999999999999,
      90.79999999999998,
      -50.2,
      -11.200000000000003,
      146,
      -34.60000000000001
    ],
    [
      -13,
      89.6,
      173.59999999999997,
      116,
      161,
      13.399999999999991,
      98
    ],
    [
      159.2,
      -24.400000000000006,
      57.19999999999999,
      145.39999999999998,
      -53.2,
      -1,
      -56.800000000000004
    ],
    [
      -38.2,
      57.79999999999998,
      91.4,
      -53.800000000000004,
      -14.200000000000003,
      53,
      149
    ],
    [
      -88.6,
      -89.2,
      108.79999999999998,
      23,
      50.599999999999994,
      -5.200000000000003,
      -49.6
    ],
    [
      -4,
      105.79999999999998,
      102.19999999999999,
      104,
      108.19999999999999,
      -7.6000000000000085,
      52.400000000000006
    ],
    [
      18.799999999999997,
      -97.6,
      -52,
      -1.6000000000000085,
      54.19999999999999,
      54.79999999999998,
      110.6
    ],
    [
      -52.6,
      -37.6,
      161.59999999999997,
      -51.4,
      3.200000000000003,
      9.200000000000003,
      165.8
    ],
    [
      23.599999999999994,
      6.799999999999997,
      -40,
      -40.6,
      7.3999999999999915,
      -35.2,
      -14.799999999999997
    ],
    [
      -77.2,
      101.6,
      117.79999999999998,
      124.4,
      111.79999999999998,
      -94,
      53.599999999999994
    ],
    [
      81.79999999999998,
      -32.2,
      144.2,
      159.8,
      97.4,
      -94.6,
      56.599999999999994
    ],
    [
      191,
      20,
      132.2,
      -28,
      62,
      170,
      58.400000000000006
    ]
  ]
]
export const dataForBall = (winningSlot, numberOfRows = linesNum.get()) => {
  const ballPathFirstArray = numberOfRows - 8
  const ballPathThirdArray = secondIndex(data[ballPathFirstArray][winningSlot])
  return data[ballPathFirstArray][winningSlot][ballPathThirdArray]
}

// import { Lines } from "../pixi-js-scripts/bridge"
