/* ==========================================================================
  LANGUAGES
  ========================================================================== */
export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = Object.freeze({
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
})

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'authtoken'
export const LANGUAGE = 'operator_language'
export const WALLET_SETTING = 'operator_walletSetting'
export const CHAT = 'chat'
export const LIVE_DATA = 'liveData'
export const SHOW_SIDE_NAV = 'showSideNav'
export const PROVIDER_ID = 'providerId'
export const ANNOUNCEMENTS = 'announcements'
export const LANGUAGE_ROOM_ID = 'languageRoomId'
export const CHAT_GROUP_ID = 'chatGroupId'

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = Object.freeze({
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
})

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = Object.freeze({
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader',
  content: 'contentLoader',
  liveWins: 'liveWins',
  gameBarFavourites: 'gameBarFavourites',
  categoryWiseGamesList: 'categoryWiseGamesList',
  userDetails: 'userDetails',
  walletAddress: 'walletAddress',
  chat: 'chat'
})

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = Object.freeze({
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CRASH_GAME: '/crash-game',
  DICE_GAME: '/dice-game',
  DICE_GAME_DEMO: '/dice-game/demo',
  FLIP_COIN_GAME: '/flip-coin-game',
  FLIP_COIN_GAME_DEMO: '/flip-coin-game/demo',
  MINE_GAME: '/mine-game',
  PLINKO_GAME: '/plinko-game',
  HILO_GAME: '/hilo-game',
  VERIFY_EMAIL: '/verify/:userId/:verificationToken',
  RESET_PASSWORD: '/reset-password/:userId/:resetToken',
  ABOUT: '/about',
  INFO: '/site-info',
  SITE_INFO: '/site-info/:pageName',
  GAME_CATAGORY: '/casino/group/:gameCategory',
  TERMS_AND_CONDITION: '/terms-condition',
  SPORTS_BOOK: '/sports-book',
  CASINO_PLAYGAME: '/casino/play-game/:gameName',
  CASINO: '/casino',
  CRASH_GAME_DEMO: '/crash-game/demo',
  PROMOTIONS: '/promotions',
  PROMOTION_DETAILS: '/promotions/:promotionId',
  PROFILE: '/account/profile',
  BALANCE: '/account/balances',
  DEPOSIT: '/account/deposits',
  WITHDRAWALS: '/account/withdrawals',
  REFERRALS: '/account/referrals',
  SETTINGS: '/account/settings',
  TRANSACTIONS: '/account/transactions',
  CRYPTO: '/crypto',
  CRYPTO_CATEGORY: '/crypto/:id',
  ROLLERCOASTER: '/vortex',
  PROVIDERS: '/providers',
  PROVIDERS_CATEGORY: '/providers/:providerName',
  REWARDS: '/rewards',
  ACCOUNT_VERIFICATION: '/verify-account',
  LIMITS: '/account/limits',
  PRE_LOGIN_REWARDS: '/rewards-users',
  PRE_LOGIN_AFFILIATES: '/affiliates/users'
})

/* ==========================================================================
  Table Cell Type
========================================================================== */
export const TABLE_CELL_TYPE = Object.freeze({
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  object: 'object'
})

/* ==========================================================================
  All Games default ids
========================================================================== */
export const DEFAULT_GAME_ID = Object.freeze({
  CRASH: '1',
  PLINKO: '2',
  ROLLER_COASTER: '3',
  CRYPTO_FUTURES: '4'
})

/* ==========================================================================
  Default id of sports book
========================================================================== */
export const DEFAULT_SPORTS_BOOK_GAME_ID = 3

/* ==========================================================================
  Bet Result constants
========================================================================== */
export const BET_RESULT = Object.freeze({
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
})

export const MAX_BET_COUNT = {
  CRASH: 50
}

// export const MAX_INCREASED_BY_PERCENTAGE = {
//   CRASH: 50
// }

export const DEFAULT_LIVE_STATS = { profit: 0, wins: 0, wagered: 0, losses: 0 }
/* ==========================================================================
  Table tabs constants
========================================================================== */
export const TABLE_TABS = Object.freeze({
  VIVA_ORIGINALS: 'viva-originals',
  MY_BETS: 'my-bets',
  CASINO: 'casino',
  SPORTS_BOOK: 'sports-book'
})

/* ==========================================================================
  Table transactions tabs constants
========================================================================== */
export const TRANSACTION_TABS = Object.freeze({
  ALL_BETS: 'all-bets',
  HIGH_ROLLER: 'high-roller'
})

/* ==========================================================================
  Custom games icons constants
========================================================================== */
export const CUSTOM_GAMES_ICONS = Object.freeze({
  crash: '/assets/images/casino/crash-table.svg'
})

/* ==========================================================================
  Betting modes constants
========================================================================== */
export const BET_MODES = Object.freeze({
  MANUAL: 'manual',
  AUTO: 'auto',
  STRATEGY: 'strategy'
})

/* ==========================================================================
  Minimum payout for all games
========================================================================== */
export const MIN_PAYOUT = Object.freeze({
  CRASH: 1.01
})

/* ==========================================================================
  Max increased by percentage for all games
========================================================================== */
export const MAX_INCREASED_BY_PERCENTAGE = Object.freeze({
  CRASH_AUTO_BET: 50
})

/* ==========================================================================
  Bonus status constants
========================================================================== */
export const BONUS_STATUS = Object.freeze({
  ACTIVE: 'active',
  CLAIMED: 'claimed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  FORFEIT: 'forfeit',
  READY_TO_CLAIM: 'readyToClaim'
})

/* ==========================================================================
  Provable Fair Text
========================================================================== */
export const PROVABLE_FAIR_TEXT = '"Provably fair" is a term that refers to a system or algorithm used in online gambling or gaming that can be mathematically verified to ensure that the results are fair and not manipulated in any way. It is a way for players to know that the game or gambling platform they are using is legitimate and not rigged against them.'

/* ==========================================================================
  Transaction status and type constants
========================================================================== */
export const TRANSACTIONS_STATUS = Object.freeze({
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILED: 'failed'
})

export const TRANSACTIONS_TYPE = Object.freeze({
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  CASINO: 'CASINO',
  ORIGINALS: 'ORIGINALS',
  SPORTS_BOOK: 'SPORTS_BOOK',
  CRYPTO: 'CRYPTO'
})

/* ==========================================================================
  Casino Page Tabs
========================================================================== */
export const CASINO_TABS = Object.freeze({
  LOBBY: 'lobby',
  FAVOURITE: 'favourite',
  ORIGINALS: 'originals',
  GAME_SEARCH: 'game-search',
  ALL_PROVIDERS: 'all-providers'
})

export const GAME_CATEGORY = Object.freeze({
  FAVOURITE: 'favourite'
})

export const LIVE_WINS_TABS = Object.freeze({
  LIVE_WINS: 'live-wins',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
})

export const SPORTS_BOOK_PATHS_SIDEBAR = Object.freeze({
  MY_BETS: 'bets',
  LIVE: 'live',
  FAVOURITES: 'favorites',
  SOCCER: 'soccer-1',
  TENNIS: 'tennis-5',
  BASKETBALL: 'basketball-2',
  VOLLEYBALL: 'volleyball-23',
  TABLE_TENNIS: 'table-tennis-20',
  AMERICAN_FOOTBALL: 'american-football-16',
  BASEBALL: 'baseball-3',
  GOLF: 'golf-9'
})

export const SPORTSBOOK_PATHS = {
  ...SPORTS_BOOK_PATHS_SIDEBAR,
  FIFA: '300',
  ICE_HOKEY: 'ice-hockey-4',
  RUGBY: 'rugby-12',
  BOXING: 'boxing-10',
  LEAGUE_OF_LEGENDS: 'league-of-legends-110',
  FORMULA_1: 'formula-1-40',
  PREMIER_LEAGUE: 'soccer%2Fengland%2Fpremier-league-1669818860469096448',
  UFC_MMA: 'mma-117',
  EA_SPORTS_FIFA: 'fifa-300',
  PGA_TOUR_GOLF: 'golf/men/pga-championship-2024-2272801992931938313',
  COUNTER_STRIKE: 'eshooter/counter-strike-go-2292677732049489929',
  RUGBY_UNION: 'rugby/rugby-union-1666080381855731712',
  RUGBY_LEAGUE: 'rugby-league-1669818854496407552',
  VALORANT: 'valorant%2Fvalorant-2173383703982641156',
  CRICKET: 'cricket-21',
  DOTA_2: 'darts-22'
}

export const LIVE_WINS_TYPES = {
  CASINO_WIN: 'casino-win',
  CASINO_BET: 'casino-bet'
}

/* ==========================================================================
  Other constants
========================================================================== */
export const DEFAULT_PAGE_CALLS = 10
export const DEFAULT_PRECISION = 5
export const DEFAULT_PRECISION_MONEY = 8
export const PAGINATION_LIMIT = 10
export const PAGINATION_LIMIT_FOR_ALLBETS = 10
export const DEFAULT_AUTO_BET_COUNTER = 1
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const TAB_SELECTION = Object.freeze({
  placed: Symbol('placed'),
  previous: Symbol('previous')
})
export const ROLLOVER = 'rollOver'
export const STRATEGY = 'strategiesSaved'
export const USER_DISCLAIMER = 'userDisclaimer'
export const LIVE_WINS_RECORDS_LIMIT = 10

// ==============================================================
// Bet Transaction Types
// ==============================================================
export const transactionType = {
  PUBLIC_BETS: 'publicBets',
  LEADERBOARD: 'leaderboard',
  ACTIVE_BETS: 'activeBets',
  CLOSED_BETS: 'closedBets'
}

// =======================================================================================
export const perArr = [-100, -50, -10, -5, -1, -0.5, -0.10, -0.05, -0.01, 0, 0.01, 0.05, 0.10, 0.48, 0.95, 4.75, 9.50, 47.50, 95, 475, 950]
// =======================================================================================

/* ==========================================================================
  Mine Game Constants
========================================================================== */
export const MIN_MINE_COUNT = 1
export const MAX_MINE_COUNT = 24
export const MIN_TILE_COUNT = 1
export const MAX_TILE_COUNT = 25
export const DEFAULT_MINE_COUNT = 3
export const DEFAULT_MINE_GAME_AUTOBET_ROUNDS = 3
export const DEFAULT_MINE_GAME_AUTOBET_MAX_ROUNDS = 50

/* ==========================================================================
  Login Method Types
========================================================================== */
export const LOGIN_METHODS = Object.freeze({
  GOOGLE: 'google',
  TWITCH: 'twitch',
  METAMASK: 'metamask',
  PHANTOM: 'phantom'
})
/* =====================================================================
  Cryptocoin-id
======================================================================== */
export const CRYPTO_NAME = {
  1: 'Bitcoin',
  2: 'Ethereum',
  3: 'Dogecoin',
  4: 'Litecoin'
}

export const CRYPTO_COIN = {
  BITCOIN: 'Bitcoin',
  ETHEREUM: 'Ethereum',
  DOGECOIN: 'Dogecoin',
  LITECOIN: 'Litecoin'
}

export const CRYPTO_COINS = Object.freeze({
  'USDC.TRC20': 'USDC',
  'BNB.BSC': 'BNB',
  'MATIC.POLY': 'MATIC',
  'USDT.ERC20': 'USDT',
  USD: 'USD',
  LTCT: 'LTC',
  LTC: 'LTC',
  BTC: 'BTC',
  ETH: 'ETH',
  SOL: 'SOL',
  EUR: 'EUR'
})

export const FIAT_CURRENCY = [
  {
    id: '1',
    code: 'EUR',
    img: 'https://metaversity-dev-storage.s3.amazonaws.com/unnamed_d261de0d-8c6e-4618-8110-0949a0e599af.png',
    symbol: '€'
  },
  {
    id: '3',
    code: 'USD',
    img: 'https://metaversity-dev-storage.s3.amazonaws.com/usdc_4897a2ec-a1a2-4638-ae0b-0f93750097c2.png',
    symbol: '$'
  }
]

export const LIMIT_TYPE = {
  WAGER: 'wager',
  LOSS: 'loss',
  DEPOSIT: 'deposit',
  SESSION: 'session',
  TAKE_A_BREAK: 'TAKE_A_BREAK',
  SELF_EXCLUSION: 'SELF_EXCLUSION'
}
/* ==========================================================================
  PLINKO GAME CONSTANTS
========================================================================== */
export const PLINKO_MIN_ROWS = 8
export const PLINKO_MAX_ROWS = 16
export const DEFAULT_PLINKO_PINS = 8
export const DEFAULT_PLINKO_RISK_LEVEL = 1
export const DEFAULT_PLINKO_GAME_AUTOBET_MAX_ROUNDS = 50

export const PLINKO_GAME_RISK_LEVEL = [
  {
    label: 'Normal',
    value: 1
  },
  {
    label: 'Medium',
    value: 2
  },
  {
    label: 'High',
    value: 3
  }
]

export const PLINKO_FIXED_ODDS = {
  8: [[5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6], [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13], [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29]],
  9: [[5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6], [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18], [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43]],
  10: [[8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9], [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22], [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76]],
  11: [[8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4], [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24], [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120]],
  12: [[10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10], [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33], [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170]],
  13: [[8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1], [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43], [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260]],
  14: [[7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1], [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58], [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420]],
  15: [[15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15], [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88], [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620]],
  16: [[16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16], [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110], [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000]]
}

export const DEFAULT_PLINKO_LIGHTNING_MODE_BOARD = {
  betMultipliers: [{ position: [7, 8], multiplier: '2x' }, { position: [13, 10], multiplier: '15x' }, { position: [2, 2], multiplier: '40x' }],
  payouts: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000]
}

export const BOX_COLORS = {
  red: 'red',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  blue: 'blue',
  grey: 'grey',
  green: 'green'
}

export const BOX_COLORS_NUMBERS = {
  red: 1,
  orange: 2,
  pink: 3,
  purple: 4,
  blue: 5,
  grey: 6,
  green: 7
}

export const PIN_IN_FIRST_LINE = 3

export const TOOLTIP_VARIANT = Object.freeze({
  SIDE_BAR: 'sidebar',
  SIDEBAR_BUTTON: 'sidebarButton'
})

/* ==========================================================================
  HiLo Card Deck
========================================================================== */
export const HI_LO_CARD_DECK = {
  1: [1, 'spade', 'Ace of spade', 'A-1'],
  2: [2, 'spade', '2 of spade', '2-1'],
  3: [3, 'spade', '3 of spade', '3-1'],
  4: [4, 'spade', '4 of spade', '4-1'],
  5: [5, 'spade', '5 of spade', '5-1'],
  6: [6, 'spade', '6 of spade', '6-1'],
  7: [7, 'spade', '7 of spade', '7-1'],
  8: [8, 'spade', '8 of spade', '8-1'],
  9: [9, 'spade', '9 of spade', '9-1'],
  10: [10, 'spade', '10 of spade', '10-1'],
  11: [11, 'spade', 'Jack of spade', 'J-1'],
  12: [12, 'spade', 'Queen of spade', 'Q-1'],
  13: [13, 'spade', 'King of spade', 'K-1'],
  14: [1, 'club', 'Ace of club', 'A-3'],
  15: [2, 'club', '2 of club', '2-3'],
  16: [3, 'club', '3 of club', '3-3'],
  17: [4, 'club', '4 of club', '4-3'],
  18: [5, 'club', '5 of club', '5-3'],
  19: [6, 'club', '6 of club', '6-3'],
  20: [7, 'club', '7 of club', '7-3'],
  21: [8, 'club', '8 of club', '8-3'],
  22: [9, 'club', '9 of club', '9-3'],
  23: [10, 'club', '10 of club', '10-3'],
  24: [11, 'club', 'Jack of club', 'J-3'],
  25: [12, 'club', 'Queen of club', 'Q-3'],
  26: [13, 'club', 'King of club', 'K-3'],
  27: [1, 'heart', 'Ace of heart', 'A-4'],
  28: [2, 'heart', '2 of heart', '2-4'],
  29: [3, 'heart', '3 of heart', '3-4'],
  30: [4, 'heart', '4 of heart', '4-4'],
  31: [5, 'heart', '5 of heart', '5-4'],
  32: [6, 'heart', '6 of heart', '6-4'],
  33: [7, 'heart', '7 of heart', '7-4'],
  34: [8, 'heart', '8 of heart', '8-4'],
  35: [9, 'heart', '9 of heart', '9-4'],
  36: [10, 'heart', '10 of heart', '10-4'],
  37: [11, 'heart', 'Jack of heart', 'J-4'],
  38: [12, 'heart', 'Queen of heart', 'Q-4'],
  39: [13, 'heart', 'King of heart', 'K-4'],
  40: [1, 'diamond', 'Ace of diamond', 'A-2'],
  41: [2, 'diamond', '2 of diamond', '2-2'],
  42: [3, 'diamond', '3 of diamond', '3-2'],
  43: [4, 'diamond', '4 of diamond', '4-2'],
  44: [5, 'diamond', '5 of diamond', '5-2'],
  45: [6, 'diamond', '6 of diamond', '6-2'],
  46: [7, 'diamond', '7 of diamond', '7-2'],
  47: [8, 'diamond', '8 of diamond', '8-2'],
  48: [9, 'diamond', '9 of diamond', '9-2'],
  49: [10, 'diamond', '10 of diamond', '10-2'],
  50: [11, 'diamond', 'Jack of diamond', 'J-2'],
  51: [12, 'diamond', 'Queen of diamond', 'Q-2'],
  52: [13, 'diamond', 'King of diamond', 'K-2']
}

/* ==========================================================================
  HILO Operation Type
========================================================================== */
export const HI_LO_GAME_BET_TYPE = {
  SAME_OR_ABOVE: 1,
  SAME: 2,
  SAME_OR_BELOW: 3,
  ABOVE: 4,
  BELOW: 5
}

export const TITLE_FOR_MORE = {
  title: 'moreFromWofe'
}
