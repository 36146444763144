import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
// import { CalendawhiteIcon } from 'components/ui-kit/Icons/svg/index'
import { ReawardIcon, InfoNewIcon } from 'components/ui-kit/Icons/svg/index'
import { BONUS_STATUS, ROUTE_PATHS } from 'constants/index'
import { RewardBox } from 'components/ui-kit/Icons/png/index'
import { useDispatch, useSelector } from 'react-redux'
import { claimBonus, getUserBonus } from 'redux-thunk/thunk/bonus.thunk'
import { startCase } from 'lodash'
import ContentNotFound from 'components/ui-kit/ContentNotFound/index'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
import Tooltip from 'rc-tooltip'
import { toUtcTime } from 'utils/helperFunctions.utils'

const RewardsMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { loginData } = useSelector(state => state.auth)
  const { userBonus } = useSelector(state => state.bonus)

  const getButtonText = useCallback((bonusStatus) => {
    if (bonusStatus === BONUS_STATUS.READY_TO_CLAIM) {
      return t('claim')
    } else if (bonusStatus === BONUS_STATUS.CLAIMED) {
      return t('claimed')
    } else if (bonusStatus === BONUS_STATUS.EXPIRED) {
      return t('expired')
    }
  }, [])

  useEffect(() => {
    if (loginData?.accessToken) {
      dispatch(getUserBonus())
    }
  }, [loginData?.accessToken])

  return (
    <div className='dropdown reward-menu-wrap'>
      <button
        className='btn btn-primary account-btn d-flex align-items-center dropdown-toggle py-1'
        type='button'
        id='dropdownMenuButton1'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <ReawardIcon className='me-1' />
        <div className='account-btn-mid-content mx-1 d-none d-lg-block'>
          <p className='m-0 text-uppercase'>{t('rewards')}</p>
        </div>
        <div className='dropdown-icon ms-1'>
          <i className='fa fa-caret-down' aria-hidden='true' />
        </div>
      </button>
      <div
        className='dropdown-menu'
        aria-labelledby='dropdownMenuButton1'
      >
        <ul className='reward-menu dropdown-menu-center'>
          {(userBonus?.userbonusDetails?.length === 0 || !userBonus)
            ? <ContentNotFound message={t('noRewardsFound')} />
            : userBonus?.userbonusDetails?.filter(bonus => (bonus?.status === BONUS_STATUS.READY_TO_CLAIM))?.map(bonus =>
              <li key={bonus.id}>
                <a
                  className='dropdown-item'
                  href='#'
                  onClick={e => {
                    e.preventDefault()
                    history.push(ROUTE_PATHS.REWARDS)
                  }}
                >
                  <div className='reward-menu-left d-flex'>
                    <div className='reward-menu-icon'>
                      <img height={35} width={35} src={RewardBox} alt='reward-box' />
                    </div>
                    <div className='reward-menu-content me-1'>
                      <h4 className='m-0'>{(bonus.bonusType === 'other') ? bonus.bonus.promotionTitle : bonus.bonusType}</h4>
                      {bonus.status === BONUS_STATUS.READY_TO_CLAIM
                        ? <p className='m-0'>{t('pending')}: ${(+bonus.bonusAmount).toFixed(2)}</p>
                        : <p className='m-0'> {startCase(bonus.status)}</p>}
                    </div>
                    <Tooltip
                      placement='topLeft'
                      trigger={['hover']}
                      overlayStyle={{
                        fontSize: '0.7rem',
                        maxWidth: '300px',
                        opacity: '1'
                      }}
                      overlay={
                        <span>
                          {bonus.bonus.termsAndConditions}
                        </span>
              }
                    >
                      <InfoNewIcon />
                    </Tooltip>
                  </div>
                  <Tooltip
                    placement='top'
                    trigger={['hover']}
                    overlayStyle={{ fontSize: '0.7rem' }}
                    overlay={
                      <div>
                        {t('expiredAt')}: {toUtcTime(bonus?.expiresAt)}
                      </div>
              }
                  >
                    <button
                      type='button'
                      disabled={bonus.status === BONUS_STATUS.CLAIMED || bonus.status === BONUS_STATUS.EXPIRED}
                      className='btn btn-secondary text-uppercase btn-green'
                      onClick={(e) => {
                        e.stopPropagation()
                        if (moment((bonus.expiresAt)).diff(moment(new Date())) >= 0) {
                          dispatch(claimBonus({ bonusId: bonus.id, bonusType: bonus.bonusType }))
                        } else {
                          dispatch(setUserBonus(userBonus?.userbonusDetails?.map(userBonus =>
                            (bonus?.id === userBonus?.id) ? { ...userBonus, status: BONUS_STATUS.EXPIRED } : userBonus)))
                          openInfoToaster({ message: t('bonusExpired') })
                        }
                      }}
                    >
                      {getButtonText(bonus.status)}
                    </button>
                  </Tooltip>
                </a>
              </li>
            )}
        </ul>
        <div className='dropdown-btn-wrap text-center px-3 pt-3 pb-0'>
          <button
            type='button'
            className='btn btn-primary text-uppercase w-100'
            onClick={() => {
              history.push(ROUTE_PATHS.REWARDS)
            }}
          >
            {t('viewDashboard')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(RewardsMenu)
