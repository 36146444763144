import { Howl } from 'howler'
import bgmMusic from '../audios/BGM.mp3'
import leftDropMusic from '../audios/Left-Drop.wav'
import rightDropMusic from '../audios/Right-Drop.wav'
import orangeBoxMusic from '../audios/Orange-Box.wav'
import redBoxMusic from '../audios/Red-Box.wav'
import yellowBoxMusic from '../audios/Yellow-Box.wav'

const BGMSound = new Howl({
  src: [bgmMusic],
  loop: true
})

const leftDropSound = new Howl({
  src: [leftDropMusic],
  loop: false
})

const rightDropSound = new Howl({
  src: [rightDropMusic],
  loop: false
})

const orangeBoxSound = new Howl({
  src: [orangeBoxMusic],
  loop: false
})

const redBoxSound = new Howl({
  src: [redBoxMusic],
  loop: false
})

const yellowBoxSound = new Howl({
  src: [yellowBoxMusic],
  loop: false
})

export function soundVolume (value) {
  rightDropSound?.volume(value)
  leftDropSound?.volume(value)
  yellowBoxSound?.volume(value)
  orangeBoxSound?.volume(value)
  redBoxSound?.volume(value)
}

export function playBGMSound () {
  BGMSound?.play()
}
export function playRightDropSound () {
  rightDropSound?.play()
}
export function playLeftDropSound () {
  leftDropSound?.play()
}
export function playYellowBoxSound () {
  yellowBoxSound?.play()
}
export function playOrangeBoxSound () {
  orangeBoxSound?.play()
}
export function playRedBoxSound () {
  redBoxSound?.play()
}

export function playSoundForBoxDrop (index, totalBox) {
  if (index <= totalBox * 0.1 || index >= totalBox * 0.9) {
    playRedBoxSound()
  } else if (index <= totalBox * 0.3 || index >= totalBox * 0.7) {
    playOrangeBoxSound()
  } else {
    playYellowBoxSound()
  }
}
