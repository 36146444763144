
import { METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getPreviousRoundState = () => {
  return axiosInstance(METHOD_TYPES.get, 'mine-game/get-unfinished-bet-state', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getLiveStats = () => {
  return axiosInstance(METHOD_TYPES.get, 'mine-game/live-stats', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getBetHistory = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'mine-game/get-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const placeBet = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'mine-game/place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const autoBet = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'mine-game/auto-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const openTile = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'mine-game/open-tile', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const cashOutBet = () => {
  return axiosInstance(METHOD_TYPES.post, 'mine-game/cash-out-bet', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const verifyProvableFairMine = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'mine-game/check-provable-fair', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getTopBets = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/mine-game/top-bets', {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}
