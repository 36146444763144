import React from 'react'
import './mainLoader.scss'

const MainLoader = () => {
  return (
    <div className='main-loader'>
      <img src={require('../../Icons/gif/WofeLoader.gif')} />
    </div>
  )
}

export default MainLoader
