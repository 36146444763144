import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '../Footer/index'
import { useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import './mainLayout.scss'

const MainLayout = ({ showFooter, children }) => {
  const { showSideNav, showChat, showNotification } = useSelector(
    (state) => state?.settings
  )// isChatCollapse
  const location = useLocation()
  const showContentInBetween =
  location.pathname === ROUTE_PATHS.CASINO ||
  location.pathname === ROUTE_PATHS.HOME ||
  location.pathname.match(`${ROUTE_PATHS.CASINO}/group/`) ||
  location.pathname === ROUTE_PATHS.PROMOTIONS ||
  location.pathname.match(`${ROUTE_PATHS.PROMOTIONS}/`) ||
  location.pathname.match(`${ROUTE_PATHS.INFO}/`) ||
  location.pathname === ROUTE_PATHS.SIGNUP

  return (
    <>
      <div
        className={`page-container ${!showSideNav ? 'sidebar-close' : ''} ${(showChat || showNotification) ? 'chat-parent chat-active' : ''}`}
        // className={`page-container ${!showSideNav ? 'sidebar-close' : ''} ${
        //  isChatCollapse ? '' : 'chat-active'
        // } ${(showChat || showNotification) ? 'chat-parent' : ''}`}
      >
        <div
          className={`${
            showContentInBetween ? 'custom-spacer' : ''
          }  content-wrapper ${
            location.pathname === ROUTE_PATHS.SPORTS_BOOK
              ? 'sportsbookwrap'
              : ''
          }`}
          style={{ minHeight: 'calc(100vh - 370px)' }}
        >
          {children}
        </div>
        {showFooter ? <Footer /> : <></>}
      </div>
    </>
  )
}

export default MainLayout
