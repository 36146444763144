import React from 'react'
import Tooltip from 'rc-tooltip'
import { useTranslation } from 'react-i18next'
import { FavRedWhiteIcon } from 'components/ui-kit/Icons/svg/index'

const FavouriteIcon = ({ fill, searchPopup }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement='bottom'
      trigger={['hover']}
      overlayStyle={{ fontSize: '0.7rem', zIndex: searchPopup ? '9999' : '' }}
      showArrow={false}
      overlay={
        <span>{fill === 'red' ? t('removeFromFavourite') : t('addToFavourite')}</span>
}
    >
      <FavRedWhiteIcon fill={fill} />
    </Tooltip>
  )
}

export default React.memo(FavouriteIcon)
