import React, { useMemo } from 'react'
import { startCase } from 'lodash'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS, TOOLTIP_VARIANT } from 'constants/index'
import { setShowAllFavGames } from 'redux-thunk/redux/slices/games.slice'
import { setGameCategory, setProvider, setTab } from 'redux-thunk/redux/slices/lobby.slice'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import {
  CasinoMenuIcon,
  CrashGameIcon,
  PlinkoIcon
  // HiloIcon,
  // CoinFlipGameIcon,
  // BombIcon,
} from 'components/ui-kit/Icons/svg/index'
import { usePreLogin } from 'hooks/usePreLogin'

const CasinoSection = (props) => {
  const { showCasinoOptions, setShowCasinoOptions } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { casinoCategoriesList, showAllFavGames } = useSelector((state) => state.games)
  const { showSideNav } = useSelector((state) => state?.settings)
  const { handleRegister } = usePreLogin()

  const customGamesOptions = useMemo(
    () => [
      // {
      //   label: t('mines'),
      //   id: uuidv4(),
      //   path: ROUTE_PATHS.MINE_GAME,
      //   iconComponent: BombIcon
      // },
      {
        label: t('Plinko'),
        id: uuidv4(),
        path: ROUTE_PATHS.PLINKO_GAME,
        iconComponent: PlinkoIcon
      },
      // {
      //   label: t('hilo'),
      //   id: uuidv4(),
      //   path: ROUTE_PATHS.HILO_GAME,
      //   iconComponent: HiloIcon
      // },
      {
        label: t('crash'),
        id: uuidv4(),
        path: ROUTE_PATHS.CRASH_GAME,
        iconComponent: CrashGameIcon
      }
      // {
      //   label: t('flipCoin'),
      //   id: uuidv4(),
      //   path: ROUTE_PATHS.FLIP_COIN_GAME,
      //   iconComponent: CoinFlipGameIcon
      // }
    ], [])

  return (
    <>
      <li>
        <NavLink
          to={ROUTE_PATHS.PLINKO_GAME}
          activeClassName=''
          // activeClassName={pathname === ROUTE_PATHS.PLINKO_GAME ? 'active' : ''}
        >
          <CustomTooltip
            variant={TOOLTIP_VARIANT.SIDE_BAR}
            message={t('casino')}
            showTooltip={!showSideNav}
          >
            <div className='menu-left d-flex align-items-center'>
              <div className='menu-icon'>
                <CasinoMenuIcon />
              </div>
              <p className='m-0'>{t('wofeOriginals')}</p>
            </div>
          </CustomTooltip>
          <span
            className='menu-arrow'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowCasinoOptions((prev) => !prev)
            }}
          >
            <i
              className={`fa fa-caret-${showCasinoOptions ? 'up' : 'down'
                        }`}
              aria-hidden='true'
            />
          </span>
        </NavLink>
        {showCasinoOptions && showSideNav && (
          <ul className='submenu-wrap'>
            {/* {loginData?.accessToken && (
              <li>
                <NavLink
                  to={ROUTE_PATHS.CASINO}
                  activeClassName={
                            gameCategory === GAME_CATEGORY.FAVOURITE
                              ? 'active'
                              : ''
                          }
                  onClick={(e) => {
                    dispatch(setTab(CASINO_TABS.GAME_SEARCH))
                    dispatch(setShowAllFavGames(true))
                  }}
                >
                  <img src={heart} alt='favourites-icon' />
                  <span>{t('favourites')}</span>
                </NavLink>
              </li>
            )} */}

            {customGamesOptions.map((item) => (
              <li key={item.id}>
                <NavLink
                  to={item.path}
                  activeClassName='active'
                  onClick={() => handleRegister({ showLoginPopup: true })}
                >
                  <item.iconComponent />
                  <span>{item.label}</span>
                </NavLink>
              </li>))}

            {casinoCategoriesList?.map((item) => (
              <li
                key={uuidv4()}
                className={
                          pathname ===
                            `${ROUTE_PATHS.CASINO}/group/${item?.gameCategory}`
                            ? 'active'
                            : ''
                        }
              >
                <NavLink
                  to={`${ROUTE_PATHS.CASINO}/group/${item?.gameCategory}`}
                  onClick={(e) => {
                    showAllFavGames &&
                              dispatch(setShowAllFavGames(false))
                    dispatch(setTab('game-search'))
                    dispatch(setGameCategory(item?.gameCategory))
                    dispatch(setProvider(''))
                  }}
                >
                  <img src={item?.image} alt={item?.gameCategory} />
                  <span>
                    {isMobile
                      ? startCase(item?.gameCategory)
                      : item?.gameCategory?.length > 14
                        ? `${startCase(
                                  item?.gameCategory.substring(0, 14)
                                )}...`
                        : startCase(item?.gameCategory)}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
      {showCasinoOptions && <hr className='theme-divider' />}
    </>
  )
}

export default CasinoSection
