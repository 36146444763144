import { createSlice } from '@reduxjs/toolkit'
import { getDemoGameIframe, getGameIframe, getHighRollers, getLiveTransactions } from 'redux-thunk/thunk/casino.thunk'

const initialState = {
  gameIframeLink: null,
  liveData: [],
  liveTransactionsData: null,
  highRollers: null
}

const {
  actions: { setLiveData, setGameIframeLink },
  reducer
} = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    setLiveData: (state, action) => ({
      ...state,
      liveData: [action.payload, ...state.liveData]
    }),
    setGameIframeLink: (state, action) => ({
      ...state,
      gameIframeLink: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          gameIframeLink: action?.payload
        }
      })
      .addCase(getDemoGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          gameIframeLink: action?.payload
        }
      })
      .addCase(getLiveTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          liveTransactionsData: action?.payload
        }
      })
      .addCase(getHighRollers.fulfilled, (state, action) => {
        return {
          ...state,
          highRollers: action?.payload
        }
      })
  }
})

export { setLiveData, setGameIframeLink }
export default reducer
