import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateReferralCode } from 'redux-thunk/thunk/user.thunk'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import { handleCopyCode } from 'utils/helperFunctions.utils'
import './referralSection.scss'

const CashierReferrals = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const { inviteFriendsStatus } = useSelector(state => state.settings)

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? 'show active' : ''}`}
      id='pills-cashier-referrals'
      role='tabpanel'
      aria-labelledby='pills-cashier-referrals-tab'
      tabIndex='0'
    >
      <div className='coupon-section referral-section'>
        <img
          src='/assets/images/homePage/coingold.svg'
          alt='Gold'
        />
        <h4 className='text-uppercase'>{t('applyReferralCode')}</h4>
        <div>
          {user?.referralCode &&
            <div className='change-username'>
              <div className='mb-3 d-flex align-items-end justify-content-center form-group'>
                <div className='text-start me-3'>
                  <label htmlFor='newUser' className='form-label'>
                    {startCase(t('referralCode'))}
                  </label>
                  <input
                    type='text'
                    className='form-control form-copy shadow-none'
                    id='newUser'
                    value={user?.referralCode}
                    placeholder={t('referralCode')}
                    readOnly
                  />
                </div>
                <button
                  type='button'
                  className='btn btn-secondary text-uppercase m-0'
                  onClick={(e) =>
                    handleCopyCode(
                      user?.referralCode,
                      t('referralCodeCopied')
                    )}
                >
                  {t('copyCode')}
                </button>
              </div>
            </div>}
          {user?.referralLink &&
            <div className='change-username'>
              <div className='mb-3 d-flex align-items-end justify-content-center form-group'>
                <div className='text-start me-3'>
                  <label htmlFor='newUser' className='form-label'>
                    {startCase(t('referralLink'))}
                  </label>
                  <input
                    type='text'
                    className='form-control form-copy shadow-none'
                    id='newUser'
                    value={user?.referralLink}
                    placeholder={t('referralLink')}
                    readOnly
                  />
                </div>
                <button
                  type='button'
                  className='btn btn-secondary text-uppercase m-0'
                  onClick={(e) =>
                    handleCopyCode(
                      user?.referralLink,
                      t('referralLinkCopied')
                    )}
                >
                  {t('copyLink')}
                </button>
              </div>
            </div>}
        </div>
        {!(user?.referralCode || user?.referralLink) &&
          <>
            <p>{t('doNotHaveReferral')}</p>
            <button
              type='button'
              className='btn btn-secondary text-uppercase'
              onClick={() => dispatch(generateReferralCode())}
            >
              {t('apply')}
            </button>
          </>}
      </div>
    </div>
  )
}

export default React.memo(CashierReferrals)
