import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUserInfoService,
  updateUserInfoService,
  // withdrawRequest
  getTransactions,
  generateQRCodeFor2fa,
  updatePhoneNumber,
  firstTime2faAuthenticateToken,
  uploadProfilePhotoService,
  getPlacedBets,
  generateWalletAddressService,
  deactivate2faAuthenticateTokenService,
  generateReferralCodeService,
  getActiveReferralBonusService,
  getActiveJoiningBonusService,
  depositRequest,
  withdrawAmountRequest,
  getDepositTransactionsService,
  getWithdrawTransactionsService,
  getUsersCustomGameTransactionsService,
  getUsersSportsbookTransactionsService,
  getUsersCasinoTransactionsService,
  getUsersTotalBetsService,
  getUsersCryptoTransactionService,
  setPrimaryWalletService,
  getReferralUsersService,
  getCryptoTransactionLinkService,
  getUsersCryptoTransactionsService
} from 'network/services/users.service'
import { getMetaUser, loginWithOtpService, verifyMetaSignature } from 'network/services/auth.service'
import { signIn } from 'helpers/cookie.helpers'
import { setQrcodeUrl, setTopBetTransactions, setUserData, setWalletData } from 'redux-thunk/redux/slices/user.slice'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setSpecificUserData, updateChat } from 'redux-thunk/redux/slices/chat.slice'
import { setShowProfileChangePopup } from 'redux-thunk/redux/slices/settings.slice'
/**
 * Fetch User info who is logged in
 */
export const fetchUserInformation = createAsyncThunk(
  'user/user-detail',
  async (_, thunkApi) => {
    try {
      const res = await getUserInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMetaUserRecord = createAsyncThunk(
  'user/get-MetaUserRecord', async ({ payload }, thunkApi) => {
    try {
      const res = await getMetaUser(payload)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const verifyMetaAccountSignature = createAsyncThunk(
  'user/verify-Signature',
  async (_, thunkApi) => {
    try {
      const res = await verifyMetaSignature()
      signIn({
        token: res.accessToken
      })

      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const loginWithOtp = createAsyncThunk(
  'user/verify-otp',
  async ({ param, showLoginOtpHandle }, thunkApi) => {
    try {
      const res = await loginWithOtpService(param)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(showLoginOtpHandle(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
  'user/update-profile',
  async ({ params }, thunkApi) => {
    try {
      const res = await updateUserInfoService(params)
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateProfilePic = createAsyncThunk(
  'user/update-profile-pic',
  async ({ data }, thunkApi) => {
    try {
      const { user } = thunkApi?.getState()?.user
      const { chat, userData } = thunkApi?.getState()?.chat
      const res = await uploadProfilePhotoService(data)
      thunkApi?.dispatch(updateChat(chat?.rows?.map(data =>
        (user?.id?.toString() === data?.userId?.toString())
          ? ({ ...data, profileImageUrl: res?.location })
          : data)))
      thunkApi?.dispatch(setShowProfileChangePopup(false))
      thunkApi?.dispatch(setUserData({ ...user, profileImageUrl: res?.location }))
      thunkApi?.dispatch(setSpecificUserData({ ...userData, profileImageUrl: res?.location }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePhone = createAsyncThunk(
  'user/update-phone',
  async (params, thunkApi) => {
    try {
      const res = await updatePhoneNumber(params)
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const firstTime2faAuthentication = createAsyncThunk(
  'user/generate-secret-code',
  async ({ token, handleClose }, thunkApi) => {
    try {
      const res = await firstTime2faAuthenticateToken(token)
      thunkApi.dispatch(fetchUserInformation())
      handleClose(false)
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deactivateFirstTime2faAuthentication = createAsyncThunk(
  'user/2FA-Authentication-deactivated',
  async (thunkApi) => {
    try {
      const res = await deactivate2faAuthenticateTokenService()
      thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const generateQRCode = createAsyncThunk(
  'user/generate-secret-code',
  async (_, thunkApi) => {
    try {
      const res = await generateQRCodeFor2fa()
      thunkApi.dispatch(setQrcodeUrl(res?.dataURL))
      // onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const createWithdrawRequest = createAsyncThunk(
  'user/create-withdraw',
  async ({ params }, thunkApi) => {
    try {
      const res = await withdrawAmountRequest(params)
      // onSuccess()
      // setWithdrawRequestData(res)
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserTransactions = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    try {
      const res = await getTransactions(params)
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const generateWalletAddress = createAsyncThunk(
  'user/generateWalletAddress',
  async ({ params }, thunkApi) => {
    try {
      const res = await generateWalletAddressService(params)
      const { user } = thunkApi?.getState()?.user
      if (res) {
        const updatedUserDetails = {
          ...user,
          wallets: user.wallets.map(wallet => {
            if (wallet.currencyId === (params.currencyId).toString()) {
              return { currency: wallet.currency, ...res }
            }
            return wallet
          })
        }
        thunkApi.dispatch(setWalletData(updatedUserDetails))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const generateReferralCode = createAsyncThunk(
  'user/generateReferralCode',
  async (_, thunkApi) => {
    try {
      const res = await generateReferralCodeService()
      const { user } = thunkApi?.getState()?.user
      thunkApi.dispatch(setUserData(
        {
          ...user,
          referralCode: res?.user?.referralCode,
          referralLink: res?.user?.referralLink
        }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getActiveJoiningBonus = createAsyncThunk('bonus/get-active-joining-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveJoiningBonusService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getActiveReferralBonus = createAsyncThunk('bonus/get-active-referral-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveReferralBonusService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUserPlacedBets = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    let baseUrl
    const { offset, transactionType, limit } = params
    if (transactionType === 'wallet') {
      baseUrl = 'user/wallet'
    } else if (transactionType === 'cashout') {
      baseUrl = 'user/cashout'
    } else if (transactionType === 'deposit') {
      baseUrl = 'user/deposit'
    } else if (transactionType === 'jackpot') {
      baseUrl = 'user/jackpot'
    } else if (transactionType === 'mybets') {
      params = {
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/my-bets'
    } else if (transactionType === 'topbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/top-bets'
    } else if (transactionType === 'allbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/all-bets'
    }
    try {
      const res = await getPlacedBets(params, baseUrl)
      if (transactionType === 'topbets') {
        thunkApi.dispatch(setTopBetTransactions(res))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const depositAmountRequest = createAsyncThunk(
  'wallet/deposit-amount',
  async ({ values }, thunkApi) => {
    try {
      const res = await depositRequest(values)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const withdrawRequest = createAsyncThunk(
  'wallet/withdraw-amount',
  async ({ values }, thunkApi) => {
    try {
      const res = await withdrawAmountRequest(values)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserDepositTransactions = createAsyncThunk(
  'user/deposit',
  async (params, thunkApi) => {
    try {
      const res = await getDepositTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserWithdrawDepositTransactions = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    try {
      const res = await getWithdrawTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserCasinoTransactions = createAsyncThunk(
  'user/casino-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserCustomGameTransactions = createAsyncThunk(
  'user/custom-game-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersCustomGameTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserSportsbookTransactions = createAsyncThunk(
  'user/sportsbook-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersSportsbookTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserCryptoCoinTransactions = createAsyncThunk(
  'user/crypto-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersCryptoTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUsersTotalBets = createAsyncThunk(
  'user/total-bet-amounts',
  async (params, thunkApi) => {
    try {
      const res = await getUsersTotalBetsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUsersCryptoTransaction = createAsyncThunk(
  'user/crypto-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersCryptoTransactionService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const setPrimaryWallet = createAsyncThunk(
  'user/set-primary-wallet',
  async ({ walletId }, thunkApi) => {
    try {
      const res = await setPrimaryWalletService({ walletId })
      const { user } = thunkApi?.getState()?.user
      if (res && user) {
        const updatedUserDetails = {
          ...user,
          wallets: user?.wallets?.map(wallet => ({ ...wallet, primary: wallet.currencyId === walletId }))
        }
        thunkApi.dispatch(setWalletData(updatedUserDetails))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getReferralUsers = createAsyncThunk(
  'user/referral-users',
  async (params, thunkApi) => {
    try {
      const res = await getReferralUsersService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCryptoTransactionLink = createAsyncThunk(
  'crypto-transaction/link',
  async (params, thunkApi) => {
    try {
      const res = await getCryptoTransactionLinkService(params)
      window.open(res?.url, '_blank')
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
