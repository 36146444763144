import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import createSocketConnection from 'socket-resources/socket-connection/index'
import NAMESPACES from 'socket-resources/namespaces/index'
import { LIVE_CHAT } from 'socket-resources/event-constants/chat/index'
let liveChatSocketInstance

const useLiveChatSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth)

  useEffect(() => {
    if (!liveChatSocketInstance) {
      liveChatSocketInstance = createSocketConnection(NAMESPACES.LIVE_CHATS, loginData?.accessToken)
    }
  }, [loginData?.accessToken])

  const listenLiveChatsUpdateEvent = (cb) => {
    if (liveChatSocketInstance && cb) {
      liveChatSocketInstance?.on(LIVE_CHAT, cb)
      return () => {
        liveChatSocketInstance.off(LIVE_CHAT, cb)
      }
    }
  }

  return { listenLiveChatsUpdateEvent }
}

export default useLiveChatSocketNameSpace
