import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'

export const getPromotionsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/promotions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content,
    params
  })
}
