const basePath = '/assets/pixi-assets/plinko/'

export const path = {
  imagePath: basePath,
  spriteSheetsPath: basePath + 'spriteSheets/'
}

export const settings = {
  originalWidth: 2000,
  originalHeight: 2000,
  backgroundColor: 0x00000,
  backgroundAlpha: 0,
  div: () => document.getElementById('pixi-plinko-game'),
  lines: {
    initialNumber: 8
  },
  points: {
    shine: {
      animationSpeed: 1.3
    }
  },
  box: {
    boxSpeed: 2,
    height: 50,
    width: 90,
    fontSizeInitial: 50
  },
  point: {
    initialRadius: 16
  },
  matter: {
    gravity: 1// 3
  }
}
// ----------------------8----9----10------11-----12---13-----14-------15 -- 16
export const xForce = [2.36, 2.05, 2.15, 2, 1.8, 1.73, 1.588, 1.53, 1.45]
export const yForce = [4.95, 5.3, 4.62, 4.63, 4.53, 4.32, 4.32, 4.31, 4.11]
