import { useEffect } from 'react'
import NAMESPACES from 'socket-resources/namespaces/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import createSocketConnection from 'socket-resources/socket-connection/index'
import { useSelector } from 'react-redux'
import { ROLLER_COASTER_AUTOCASH_BET, ROLLER_COASTER_PRIZE, ROLLER_COASTER_PUBLIC_BET } from 'socket-resources/event-constants/cryptoFuture/socket.cryptoEvent'

let rollerCoasterSocketInstance

const useRollerCoasterSocketNameSpace = () => {
  const authToken = getAuthToken()
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    if (!rollerCoasterSocketInstance) {
      rollerCoasterSocketInstance = createSocketConnection(NAMESPACES.ROLLER_COASTER_GAME, authToken)
    }
  }, [authToken])

  const listenRollerCoasterUpdateEvent = (cb) => {
    if (rollerCoasterSocketInstance && cb) {
      rollerCoasterSocketInstance?.on(ROLLER_COASTER_PRIZE, cb)
      return () => {
        rollerCoasterSocketInstance.off(ROLLER_COASTER_PRIZE, cb)
      }
    }
  }

  const listenRollerCoasterAutoCashOutBetEvent = (cb) => {
    if (rollerCoasterSocketInstance && cb) {
      rollerCoasterSocketInstance?.on(`${ROLLER_COASTER_AUTOCASH_BET}:${user?.id}`, cb)
      return () => {
        rollerCoasterSocketInstance.off(`${ROLLER_COASTER_AUTOCASH_BET}:${user?.id}`, cb)
      }
    }
  }

  const listenPublicBetEvent = (cb) => {
    if (rollerCoasterSocketInstance && cb) {
      rollerCoasterSocketInstance?.on(ROLLER_COASTER_PUBLIC_BET, cb)
      return () => {
        rollerCoasterSocketInstance.off(ROLLER_COASTER_PUBLIC_BET, cb)
      }
    }
  }

  return { listenRollerCoasterUpdateEvent, listenRollerCoasterAutoCashOutBetEvent, listenPublicBetEvent }
}

export default useRollerCoasterSocketNameSpace
