import React from 'react'
import CryptoFutures from './components/CryptoFutures/index'
import Nft from './components/Nft/index'
import HomePromotions from './components/HomePromotions/index'
import ExploreSection from './components/ExploreSection/index'
import BannerSection from './components/BannerSection/index'
// import Leaderboard from 'pages/Crypto/components/BetTransaction/Transactions/Leaderboard'
// import { transactionType } from 'constants/index'
// import LiveWins from './components/LiveWins/index'
// import CasinoSection from './components/CasinoSection/index'
// import SportsbookSection from './components/SportsbookSection/index'
// import ClutchTrading from './components/ClutchTrading/index'
// import Poker from './components/Poker/index'
// import TransactionsTable from './components/TransactionsTable/index'
// import PaymentSection from './components/PaymentSection/index'
// import ThirdPartyLogin from './components/ThirdPartyLogin/index'
import OriginalsGames from 'pages/Casino/components/Lobby/Components/OriginalsGames/index'
// import { useSelector } from 'react-redux'
import './home.scss'

function Home () {
  // const { loginData } = useSelector(state => state.auth)

  return (
    <div>
      <BannerSection />
      {/* <ClutchEcosystem /> */}
      <HomePromotions />
      <OriginalsGames />
      <CryptoFutures />
      <ExploreSection />
      <Nft />
      {/* <Leaderboard betTransactionType={transactionType.LEADERBOARD} /> */}
      {/* <LiveWins /> */}
      {/* <PaymentSection /> */}
      {/* <SportsbookSection /> */}
      {/* <CasinoSection /> */}
      {/* <ClutchTrading /> */}
      {/* <Poker /> */}
      {/* {!(loginData?.accessToken) && <ThirdPartyLogin />} */}
      {/* <TransactionsTable /> */}
    </div>
  )
}

export default Home
