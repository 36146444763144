import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LIVE_STATS } from 'constants/index'
import {
  escapeCrashBet,
  getAllBetData,
  getAllRoundHistory,
  getAllbetOfOneRound,
  getCrashGameRoundDetails,
  getCrashHighRollerTransactions,
  getMyBets,
  getTotalWinningAmount
} from 'redux-thunk/thunk/crashGame.thunk'

const initialState = {
  loading: false,
  roundBetResult: null,
  betHistoryData: [],
  roundBetHistory: null,
  resultData: [],
  diceGameBetHistoryData: [],
  currentRoundId: '',
  placedCrashBetId: '',
  allRoundHistoryData: [],
  crashGameRoundDetailsData: [],
  crashGameRoundDetailsLoading: false,
  allRoundloading: false,
  myBetsData: [],
  myBetsLoading: false,
  betTopHistoryData: [],
  totalWinningAmount: null,
  totalBets: null,
  showCrashGameHistoryModal: false,
  selectedRoundHashForFairness: '',
  allBetsOneRound: null,
  crashHighRollerTransactions: null,
  liveStatsData: DEFAULT_LIVE_STATS,
  roundData: null,
  showRoundHistoryModel: false,
  showFairnessModal: false
}

const {
  actions: {
    setRoundBetResult,
    setPlacedBetsData,
    setTopBetsData,
    setResultData,
    setDiceGameBetHistoryData,
    setCurrentRoundId,
    setPlacedCrashBetId,
    setShowCrashGameHistoryModal,
    setSelectedRoundHashForFairness,
    setNewAllBets,
    setUpdateSystemMyBet,
    setLiveStatsData,
    setGameWiseSounds,
    resetLiveStatsData,
    setRoundData,
    setShowFairnessModal,
    resetLiveStates
  },
  reducer
} = createSlice({
  name: 'crashGame',
  initialState,
  reducers: {
    setRoundBetResult: (state, { payload }) => ({
      ...state,
      roundBetResult: payload
    }),
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    },
    setTopBetsData: (state, action) => {
      return {
        ...state,
        betTopHistoryData: action.payload
      }
    },

    setResultData: (state, action) => {
      return {
        ...state,
        resultData: action.payload
      }
    },
    setDiceGameBetHistoryData: (state, action) => {
      return {
        ...state,
        diceGameBetHistoryData: action.payload
      }
    },
    setCurrentRoundId: (state, action) => {
      return {
        ...state,
        currentRoundId: action.payload
      }
    },
    setPlacedCrashBetId: (state, action) => {
      return {
        ...state,
        placedCrashBetId: action.payload
      }
    },
    setShowCrashGameHistoryModal: (state, action) => {
      return {
        ...state,
        showCrashGameHistoryModal: action.payload
      }
    },
    setSelectedRoundHashForFairness: (state, action) => {
      return {
        ...state,
        selectedRoundHashForFairness: action.payload
      }
    },
    setNewAllBets: (state, action) => {
      const wonPlacedBets = action.payload
      const uniqueIds = new Set(state.customGameTransactions?.bets.map(bet => bet?.id))
      const newBets = wonPlacedBets.filter(bet => !uniqueIds.has(bet?.id))
      const updatedBets = newBets.length ? { bets: [...newBets, ...state.customGameTransactions?.bets].slice(0, -1) } : { bets: [...state.customGameTransactions?.bets] }
      return ({
        ...state,
        customGameTransactions: updatedBets
      })
    },
    setUpdateSystemMyBet: (state, action) => {
      const { latestBets } = action.payload
      const myTransactions = { bets: [...latestBets, ...state.myCustomGameTransactions?.bets].slice(0, -1) }
      return ({
        ...state,
        myCustomGameTransactions: myTransactions
      })
    },
    setLiveStatsData: (state, action) => {
      const liveData = { ...state.liveStatsData }
      const { betAmount, winningAmount, result } = action.payload || {}
      if (!result || (result !== 'won' && result !== 'lost')) {
        return // Handle invalid result values
      }

      if (result === 'won') {
        liveData.wins = (liveData.wins || 0) + 1
      } else if (result === 'lost') {
        liveData.losses = (liveData.losses || 0) + 1
      }

      if (!isNaN(parseFloat(betAmount))) {
        liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(betAmount)).toFixed(2)
      }

      if (!isNaN(parseFloat(winningAmount)) && !isNaN(parseFloat(betAmount)) && result !== 'push') {
        const profitDelta = (parseFloat(winningAmount) - parseFloat(betAmount)).toFixed(2)
        liveData.profit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
      }
      return ({
        ...state,
        liveStatsData: liveData
      })
    },
    resetLiveStates: (state, action) => ({
      ...state,
      liveStatsData: action.payload
    }),
    setGameWiseSounds: (state, action) => ({
      ...state,
      gameWiseSounds: { ...state.gameWiseSounds, [action.payload.game]: action.payload.volume }
    }),
    resetLiveStatsData: (state, action) => ({
      ...state,
      liveStatsData: action.payload
    }),
    setRoundData: (state, action) => ({
      ...state,
      roundData: action.payload,
      showRoundHistoryModel: !!action.payload
    }),
    setShowFairnessModal: (state, action) => ({
      ...state,
      showFairnessModal: action.payload
    })
  },

  extraReducers: builder => {
    builder
      .addCase(escapeCrashBet.fulfilled, (state, { payload }) => ({
        ...state,
        roundBetResult: payload
      }))
      .addCase(getAllRoundHistory.fulfilled, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload,
          allRoundloading: false
        }
      })
      .addCase(getTotalWinningAmount.fulfilled, (state, action) => {
        return {
          ...state,
          totalWinningAmount: action.payload
        }
      })
      .addCase(getAllBetData.fulfilled, (state, action) => {
        return {
          ...state,
          totalBets: action.payload
        }
      })
      .addCase(getAllbetOfOneRound.fulfilled, (state, action) => {
        return {
          ...state,
          allBetsOneRound: action.payload
        }
      })

      .addCase(getAllRoundHistory.rejected, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: [],
          allRoundloading: false
        }
      })

      .addCase(getAllRoundHistory.pending, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: { ...state.allRoundHistoryData },
          allRoundloading: true
        }
      })
      .addCase(getCrashGameRoundDetails.fulfilled, (state, action) => {
        return {
          ...state,
          crashGameRoundDetailsData: action.payload,
          crashGameRoundDetailsLoading: false

        }
      })

      .addCase(getMyBets.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(getCrashHighRollerTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getCrashHighRollerTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          crashHighRollerTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getCrashHighRollerTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
  }
})

export {
  setRoundBetResult,
  setPlacedBetsData,
  setResultData,
  setDiceGameBetHistoryData,
  setTopBetsData,
  setCurrentRoundId,
  setPlacedCrashBetId,
  setShowCrashGameHistoryModal,
  setSelectedRoundHashForFairness,
  setNewAllBets,
  setUpdateSystemMyBet,
  setLiveStatsData,
  setGameWiseSounds,
  resetLiveStatsData,
  setRoundData,
  setShowFairnessModal,
  resetLiveStates
}

export default reducer
