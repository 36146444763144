import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSideNav: true,
  showChat: false,
  isChatCollapse: true,
  showSearchPopup: false,
  showUserDetailsPopup: false,
  showTipPopup: false,
  showCashierPopup: false,
  showReportUserPopup: false,
  showNotification: false,
  inviteFriendsStatus: false,
  showProfileChangePopup: false
}

const {
  actions: {
    setShowSideNav,
    setChatCollapse,
    setShowChat,
    setShowSearchPopup,
    setShowUserDetailsPopup,
    setShowTipPopup,
    setShowCashierPopup,
    setShowReportUserPopup,
    setShowNotification,
    setInviteFriendsStatus,
    setShowProfileChangePopup
  },
  reducer
} = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setShowProfileChangePopup: (state, { payload }) => ({
      ...state,
      showProfileChangePopup: payload
    }),
    setShowNotification: (state, { payload }) => ({
      ...state,
      showNotification: payload
    }),
    setInviteFriendsStatus: (state, { payload }) => ({
      ...state,
      inviteFriendsStatus: payload
    }),
    setShowCashierPopup: (state, { payload }) => ({
      ...state,
      showCashierPopup: payload
    }),
    setShowTipPopup: (state, { payload }) => ({
      ...state,
      showTipPopup: payload
    }),
    setShowUserDetailsPopup: (state, { payload }) => ({
      ...state,
      showUserDetailsPopup: payload
    }),
    setShowSideNav: (state, { payload }) => ({
      ...state,
      showSideNav: payload
    }),
    setChatCollapse: (state, { payload }) => ({
      ...state,
      isChatCollapse: payload
    }),
    setShowChat: (state, { payload }) => ({
      ...state,
      showChat: payload
    }),
    setShowSearchPopup: (state, { payload }) => ({
      ...state,
      showSearchPopup: payload
    }),
    setShowReportUserPopup: (state, action) => ({
      ...state,
      showReportUserPopup: action.payload
    })
  }
})

export default reducer
export {
  setShowSideNav,
  setChatCollapse,
  setShowChat,
  setShowSearchPopup,
  setShowUserDetailsPopup,
  setShowTipPopup,
  setShowCashierPopup,
  setShowReportUserPopup,
  setShowNotification,
  setInviteFriendsStatus,
  setShowProfileChangePopup
}
