import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_CALLS, DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from 'constants/index'
import { getMyBetsPlinko, placedBetPlinkoGame, postLightningBoardDetails } from 'redux-thunk/thunk/plinkoGame.thunk'

const initialState = {
  placedBetData: null,
  allPlacedBets: {
    count: 0,
    rows: []
  },
  betLock: false,
  betLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  myBetsLoading: false,
  lightningBoardLoading: false,
  lightningBoardBallMultipliers: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  lightningBoardPayouts: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts,
  isError: false,
  isBetResolved: true
}

const {
  actions: {
    setBetLock,
    setLightningBoardDetails,
    appendMyBetsPlinko,
    setAllPlacedBetsPlinko,
    setBetResolve
  },
  reducer
} = createSlice({
  name: 'plinkoGame',
  initialState,
  reducers: {
    setAllPlacedBetsPlinko: (state, action) => ({
      ...state,
      allPlacedBets: action.payload
    }),
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setLightningBoardDetails: (state, action) => {
      return {
        ...state,
        lightningBoardBallMultipliers: action.payload?.lightningBoardBallMultipliers ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
        lightningBoardPayouts: action.payload?.lightningBoardPayouts ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
      }
    },
    appendMyBetsPlinko: (state, action) => {
      const { nextServerSeedHash, ...data } = state.allPlacedBets.rows[0] || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const allBetsData = [...state.allPlacedBets?.rows]
        const myBetsDataRows = [data, ...state.myBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        allBetsData?.shift()
        return {
          ...state,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          },
          allPlacedBets: {
            count: state.allPlacedBets?.count - 1,
            rows: allBetsData
          }
        }
      }
      return state
    },
    setBetResolve: (state, action) => {
      return {
        ...state,
        isBetResolved: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true,
          isError: false
        }
      })
      .addCase(placedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBetData: action.payload,
          allPlacedBets: {
            ...state.allPlacedBets,
            count: state.allPlacedBets.count + 1,
            rows: [...state.allPlacedBets.rows, action.payload]
          },
          betLoading: false,
          isError: false
        }
      })
      .addCase(placedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false,
          isError: true
        }
      })
      .addCase(getMyBetsPlinko.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true

        }
      })
      .addCase(getMyBetsPlinko.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(postLightningBoardDetails.pending, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: true

        }
      })
      .addCase(postLightningBoardDetails.fulfilled, (state, action) => {
        const lightningBoardDetails = action.payload?.lightningBoardDetails
        return {
          ...state,
          lightningBoardBallMultipliers: lightningBoardDetails?.betMultipliers,
          lightningBoardPayouts: lightningBoardDetails?.payouts,
          lightningBoardLoading: false
        }
      })
      .addCase(postLightningBoardDetails.rejected, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: false
        }
      })
  }
})

export {
  setBetLock,
  setLightningBoardDetails,
  appendMyBetsPlinko,
  setAllPlacedBetsPlinko,
  setBetResolve
}

export default reducer
