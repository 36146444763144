import { createSlice } from '@reduxjs/toolkit'
import { getPromotions } from 'redux-thunk/thunk/promotion.thunk'

const initialState = {
  allPromotions: null
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromotions.fulfilled, (state, action) => {
        return {
          ...state,
          allPromotions: action?.payload
        }
      })
  }
})

export default promotionSlice.reducer
