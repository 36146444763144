import { ArrowDownRedIcon, GreenArrowUpIcon } from 'components/ui-kit/Icons/svg/index'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useCryptoFutureNameSpace from 'socket-resources/hooks/useCryptoFutureNameSpace'

const CryptoPrice = ({ changeShow, id }) => {
  const { cryptoCoinsList } = useSelector((state) => state.crypto)
  const myDynamicObject = {}

  for (let i = 0; i <= cryptoCoinsList?.length; i++) {
    myDynamicObject[cryptoCoinsList[i]?.id] = [0]
  }

  const [cryptoPrice, setCryptoPrice] = useState(myDynamicObject)
  const { listenCryptoFuturePriceEvent } = useCryptoFutureNameSpace()

  const handleCryptoPriceUpdate = (data) => {
    setCryptoPrice((prevState) => {
      return {
        ...prevState,
        [data.data.ticker.cryptoFuturesInstrumentId]: [
          prevState[data.data.ticker.cryptoFuturesInstrumentId]?.length > 1
            ? prevState[data.data.ticker.cryptoFuturesInstrumentId][1]
            : prevState[data.data.ticker.cryptoFuturesInstrumentId]?.[0] || 0,
          data.data.ticker.price
        ]
      }
    })
  }

  useEffect(() => {
    const cleanerCryptoEvent = listenCryptoFuturePriceEvent(handleCryptoPriceUpdate)
    return () => {
      cleanerCryptoEvent()
    }
  }, [])

  return (
    <div className='d-flex align-items-center crypto-list-right'>
      <span>
        {!changeShow && '$'}{cryptoPrice[id].length > 1
          ? cryptoPrice[id][1]
          : cryptoPrice[id][0]}
      </span>
      {cryptoPrice[id].length > 1 && changeShow && (
        <span>
          {cryptoPrice[id][0] > cryptoPrice[id][1]
            ? (
              <ArrowDownRedIcon />
              )
            : (
              <GreenArrowUpIcon />
              )}
        </span>
      )}
    </div>
  )
}

export default CryptoPrice
