import { createSlice } from '@reduxjs/toolkit'
import { getGames } from 'redux-thunk/thunk/casino.thunk'

const initialState = {
  viewAllGames: null
}

const {
  actions: {
    setViewAllPopupGames
  },
  reducer
} = createSlice({
  name: 'searchPopup',
  initialState: initialState,
  reducers: {
    setViewAllPopupGames: (state, action) => ({
      ...state,
      viewAllGames: action?.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGames.fulfilled, (state, action) => {
        return {
          ...state,
          viewAllGames: action?.payload
        }
      })
  }
})

export default reducer
export { setViewAllPopupGames }
