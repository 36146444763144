import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAnnouncementsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/announcement', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.userDetails,
    params
  })
}
