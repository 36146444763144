import React from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'

const PinnedNotifications = () => {
  const { announcements } = useSelector(state => state.announcement)

  return (
    <div className='notification-pinned-wrap'>
      {announcements?.rows?.filter(el => el.isPinned)?.slice(0, 10)?.map(announcement =>
        <div
          key={announcement?.id}
          className='chat-announcement-card d-flex justify-content-between align-items-center'
        >
          <p>{`${capitalize(announcement?.title)}: ${announcement?.description}`}</p>
          {/* <a
            href='javascript:void(0);'
            className='card-close'
            onClick={() => dispatch(updateAnnouncement({ id: announcement.id }))}
          >
            <i className='fa fa-times' aria-hidden='true' />
          </a> */}
        </div>)}
    </div>
  )
}

export default React.memo(PinnedNotifications)
