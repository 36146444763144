import * as corePixiInit from "./core/initializePixi.js";

export const pixiApplicationInit = () => corePixiInit.init()

export const pixiAssetsLoad = () => corePixiInit.loadPixiAssets()

export const pixiApplicationDestroy = () => corePixiInit.destroyPixiApp()

const loadAndExecuteFunction = (func) => (...args) =>
  corePixiInit.loadCoinGameUIFunctions()
    .then((coinGameUI) => coinGameUI[func](...args))
    .catch((error) => {
      console.error(`Error using coinGameUI.${func}():`, error);
      throw error;
    });

export const pickAllHeads = loadAndExecuteFunction('pickAllHeads');
export const pickAllTails = loadAndExecuteFunction('pickAllTails');
export const rotateShowCoins = loadAndExecuteFunction('rotateShowCoins');
export const showCoins = loadAndExecuteFunction('showCoins');
export const startFlip = loadAndExecuteFunction('startFlip');
export const stoppingSpin = loadAndExecuteFunction('stoppingSpin');
