import * as PIXI from 'pixi.js'
import Matter from 'matter-js'
import { pixiApp } from './initializePixi.js'
import {
  changeLinesNumber,
  // createPoints,
  createPointsAndBoxes, destroyAllBoxes, destroyAllPoints, setupPayoutText
} from './pointAndBox.js'
import { createBall, destroyAllBalls } from './ball.js'
import { matterEngineIgnition, setupMatterJS } from './appMatter.js'
import { setTickerRun } from './gameLogic.js'
// import { data } from './dataForBall.js'
// import { secondIndex } from './utility.js'
import { settings } from '../settings.js'
import { destroyMatterWorld } from './appMatter.js'
// import { dispatching } from '../bridge.js'
// import { dataForBall } from '../pixiHelper.js'
// import { useSelector } from 'react-redux'
// import { useEffect } from 'react'
// import { dataEight } from '../dataEight.js'
export let deviceFPS = 0
let uiCreated = false
// const lineNum = 8
// const dataAnalysis = []
const dataAnalysis = [
  [[], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
]

const matterAssets = {
  engine: Matter.Engine.create(),
  world: null,
  ball: null,
  ball2: null
}

const containers = {
  rootContainer: new PIXI.Container(),
  pointsContainer: new PIXI.Container()
}

const assets = {
  points: [],
  activeBalls: [],
  boxes: []
}

let _lineNum = settings.lines.initialNumber
export const linesNum = {
  get: () => _lineNum,
  set: (newLineNum) => { _lineNum = newLineNum }
}

function runTicker () {
  matterEngineIgnition()
  setTickerRun(true)
}

function createUI () {
  setupMatterJS()
  setupContiners()
  // instantiatePoints()
  createPointsAndBoxes()
  runTicker()
  uiCreated = true
}

function setupContiners () {
  pixiApp.app.stage.addChild(containers.rootContainer)
  containers.rootContainer.addChild(containers.pointsContainer)
}

function simulateBallCreation (dataForBall, winningSlot, dropDetails) {
  console.log('box text', assets.boxes[winningSlot].boxText.text)
  // const ballPathFirstArray = linesNum.get() - settings.lines.initialNumber
  // const ballPathThirdArray = secondIndex(data[ballPathFirstArray][winningSlot])
  console.log('winning slot ', winningSlot)
  console.log('path ', dropDetails)
  createPath(dropDetails, dataForBall)
}
let ballPath = []
let ballStartPos = 1

function createPath (dropDetails, dataForBall) {
  const digitsString = dropDetails.toString()

  // Create an array to store the digits
  const pathArray = []

  // Iterate over each character in the string
  for (let i = 0; i < digitsString.length; i++) {
    // Convert each character back to a number and add it to the array
    pathArray.push(parseInt(digitsString[i]))
  }
  // console.log("path ar", dropDetails)
  // Return the array of digits
  generatePath(pathArray, dataForBall)
}

function generatePath (pathArray, dataForBall) {
  const rand = Math.round(Math.random() * 2)
  if (rand === 0) {
    ballStartPos = 0
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === 0) {
        pathArray[i] = 1
        break
      }
    }
  //  ballPath = pathArray
    // console.log("path ar 2", pathArray)
    // console.log("path ar 1", pathArray)
  } else if (rand === 2) {
    ballStartPos = 2
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === 1) {
        pathArray[i] = 0
        break
      }
    }
  //  ballPath = pathArray
    // console.log("path ar 2", pathArray)
  } else if (rand === 1) {
    ballStartPos = 1
  }
  createBall(dataForBall, 0 , pathArray , ballStartPos)
}

export const faultyArray = []
// const liness = 15
export const testIndex = 0
// function testSimulation () {
//   simulateBallCreation(0, testIndex)
// }

export {
  createUI,
  uiCreated,
  dataAnalysis,
  matterAssets,
  containers,
  assets,
  destroyAllBalls,
  destroyAllPoints,
  destroyAllBoxes,
  simulateBallCreation,
  setupPayoutText,
  changeLinesNumber,
  destroyMatterWorld

}
