export default {
  404: '404',
  register: 'Register',
  login: 'Login',
  grand: 'Grand',
  major: 'Major',
  minor: 'Minor',
  mini: 'Mini',
  crashGameTitle: 'Crash',
  betAmount: 'Bet Amount',
  profit: 'Profit',
  payout: 'Target Payout',
  chance: 'Win Chance',
  betBtn: 'Bet',
  autoCashout: 'Auto Cashout',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Wagered',
  lost: 'Lost',
  won: 'Won',
  streak: 'Streak',
  highestWin: 'Highest Win',
  highestLost: 'Highest Lost',
  highestBet: 'Highest Bet',
  maxStreak: 'Max Streak',
  maxProfit: 'Max Profit: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Your bets history is empty',
  messagesCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.\nPlease try to copy the code manually.',
  messagesCopied: 'Address copied.',
  deposit: 'Deposit',
  statReset: 'Reset stats',
  rollOver: 'Roll Over',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'Max',
  buttonTextPlaceBet: 'Place Bet',
  buttonTextCancelBet: 'Cancel Bet',
  buttonTextEscapeBet: 'Cashout',
  buttonTextStartAutoBet: 'Start Autobet',
  buttonTextCancelAutoBet: 'Cancel Autobet',
  buttonTextLoading: 'Loading',
  tabsMyBets: 'My Bets',
  tabsDeposit: 'Deposit',
  tabsCashout: 'Cashouts',
  tabsAllbets: 'All Bets',
  tabsTopbets: 'Top Bets',
  tabsBonus: 'Bonus',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Bet History',
  timerTextCurrentPayout: 'Current payout',
  timerTextStarting: 'Starting in',
  timerTextSec: 's',
  timerTextFlewAway: 'Crashed',
  timerTextWait: 'Please wait..',
  timerTextPreparingRound: 'Preparing round',
  timerTextLoadingRound: 'Loading Round',
  comingSoon: 'Coming Soon',
  labelsProfitOnWin: 'Profit on win',
  inputsNoOfBets: 'Number of bets',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Cancel',
  buttonsMin: 'Min',
  buttonsMax: 'Max',
  prediction: 'Prediction',
  luckyNumber: 'Lucky Number',
  multiplier: 'Multiplier',
  chanceToWin: 'Chance to win',
  bets: 'Bets',
  depositBtn: 'Deposit',
  rollUnder: 'Roll Under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'History',
  navBarMyBets: 'My Bets',
  navBarAllBets: 'All Bets',
  navBarHighRollers: 'High Rollers',
  navBarRoundHistory: 'Round History',
  navBarTotal: 'Total Bets',
  topHeaderBetId: 'Bet ID',
  topHeaderUser: 'User',
  topHeaderPayout: 'Payout',
  topHeaderAmount: 'Amount',
  topHeaderTime: 'Time',
  topHeaderProfit: 'Profit',
  topHeaderRoundCrashed: 'Crashed',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Bet',
  topHeaderRoundProfit: 'Profit',
  topHeaderRoundSignature: 'Signature',
  roundHistoryTitle: 'GAME INFORMATION',
  roundHistoryDescGame: 'Game #',
  roundHistoryDescCrashAt: 'Crashed At:',
  roundHistoryDescDate: 'Date :',
  roundHistoryDescBetHeader: 'Bet#',
  roundHistoryTableTitlePlayer: 'PLAYER',
  roundHistoryTableTitleBet: 'BET',
  roundHistoryTableTitleCashedOut: 'CASHED OUT',
  roundHistoryTableTitleProfit: 'PROFIT',
  roundHistoryTableTitleBetId: 'BET ID',
  roundHistoryMessagesNoBetHistory: 'Your bets history is empty',
  placedBetsTabPlaced: 'Placed Bet',
  placedBetsTabPrev: 'Previous Bet',
  placedBetsTitleUser: 'User',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Bet',
  placedBetsTitleProfit: 'Profit',
  placedBetsTitleCashout: 'Cashout',
  placedBetsNoPlacedBet: 'No placed Bets.',
  provablyFairContentCheckBtn: 'Check Provable Fair',
  provablyFairContentTitle: 'Provably Fair',
  provablyFairContentDesc: 'Four side lead’s outcome can be proven as fair. Here you can verify the game hashes and calculate the results.',
  provablyFairContentInputFieldsCrashRateTitle: 'Crash Rate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Crash rate without x',
  provablyFairContentInputFieldsRoundHashTitle: 'Round Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'e.g. 0xaaaaaa',
  provablyFairContentInputFieldsSubmitBtn: 'Generate Signature',
  provablyFairContentSignature: 'Round Signature',
  provablyFairContentErrorsCrashRate: 'please input  number in the crash rate field',
  resetPasswordTitle: 'Reset Password',
  resetPasswordNewPasswordTitle: 'New Password',
  resetPasswordNewPasswordErrorsRequired: '*password is required.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 characters allowed.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximum 16 characters allowed.',
  resetPasswordNewPasswordErrorsPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  resetPasswordConfirmPasswordTitle: 'Confirm Password',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwords do not match.',
  resetPasswordResetBtn: 'Reset',
  passwordResetSuccess: 'Password successfully changed.',
  resetPasswordWentWrong: 'Something went wrong!',
  levelGrand: 'GRAND',
  levelMajor: 'MAJOR',
  levelMinor: 'MINOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Win Daily $1000 ',
  bannerContentContest: 'Contests now ',
  bannerContentjoin: 'Join our daily wagering contest and become the Hero! higher your position in the ranking when the bigger your prize is! ',
  bannerContentPlayNow: 'Play Now',
  bannerContentTitle: 'Win Jackpot',
  bannerContentContent: 'Play more & more games and get a chance to win jackpot. The more you wager the highest chance of your for jackpot winning.',
  bannerContentGameName: 'CRASH GAME',
  bannerContentGameDesc: 'Play more & more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  partners: 'Partners',
  gamesGoldenDragon: 'Golden Dragon',
  gamesCrashGame: 'Crash Game',
  gamesDice: 'Dice',
  gamesHilo: 'Hilo',
  allBetsTitle: 'All Bets',
  allBetsBetId: 'Bet ID',
  allBetsUser: 'User',
  allBetsPayout: 'Payout',
  allBetsAmount: 'Amount',
  allBetsTime: 'Time',
  allBetsProfit: 'Profit',
  LoginwithOtp: 'Login with Otp',
  EnterLoginOtp: 'Enter Login Otp',
  submit: 'Submit',
  footerAboutUs: 'About Us',
  footerFaq: 'FAQs',
  footerSitePolicy: 'Site Policy',
  footerResponsibleGambling: 'Responsible Gambling',
  footerTerms: 'Terms and Conditions',
  footerCopywrite: '2022 CRYPTO LINE. All rights reserved',
  signinTitle: 'Login',
  signinUsername: 'Username / Email',
  signinPassword: 'Password',
  signinLoginBtn: 'Login',
  signinSignup: 'Sign Up!',
  signinForgetPassword: 'Forgot Password?',
  signinDontHaveAccount: 'Don’t have an account? ',
  signinCreateAccount: 'Create account',
  signinLoginDescription: 'Wofe Gaming might on occasion send you free offers and special promotions by email or SMS. You can opt out of receiving these at any time in your account settings.',
  signinErrorsUserNameRequired: '*Please enter Username/Email.',
  signinErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signinErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signinErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signinErrorsPasswordRequired: '*Password is required.',
  signinErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signinErrorsPasswordMaxLength: 'Maximum 16 characters allowed.',
  signinErrorsPasswordPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  signinSuccessfullyLoggedIn: 'Successfully logged in.',
  signinResetMailSent: 'Reset password email sent.',
  signinTryAgain: 'Something went wrong!, Please try again.',
  signinResetPasswordUsernameErr: 'Please enter username/email to reset password.',
  signinResetMailSentSuccessContent: 'We sent you a reset password link on your email. Please check your email and reset your password.',
  signupTitle: 'Register',
  signupUsername: 'Username',
  signupEmail: 'Email',
  signupTermsConditions: 'Terms & Conditions',
  signupWalletConnect: 'Login with Wallet ',
  signupPassword: 'Password',
  signupEighteenPlus: 'I’m over 18 years of age and I agree to the Terms of Service',
  signupRegisterBtn: 'SignUp',
  signupCreateNewAccount: 'Create New Account',
  signupFullName: 'Full Name',
  signupReferralCode: 'Referral Code',
  signupConfirmPassword: 'Confirm Password',
  signupUseSocialAccount: 'Or Use Social Account',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Have an account? ',
  signupLoginBtn: 'Login',
  signupErrorsUserNameRequired: '*Please enter username.',
  signupErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signupErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signupErrorsFullNameRequired: '*Please enter full name.',
  signupErrorsFullNamePattern: 'Please enter valid full name, only letters and numbers are allowed.',
  signupErrorsEmailRequired: '*Please enter email.',
  signupErrorsEmailMinLength: 'Minimum 4 characters allowed.',
  signupErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsEmailPattern: 'Please enter valid email.',
  signupErrorsPasswordRequired: '*Password is required.',
  signupErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signupErrorsPasswordMaxLength: 'Maximum Deposit16 characters allowed.',
  signupErrorsPasswordPattern: '*Password should be 8 to 16 alphanumeric(upper and lower case) and special characters. It is case-sensitive.',
  signupErrorsConfirmPasswordMatch: 'Password must match.',
  signupPlaceholderUserName: 'Min. 4 character and only number & letters',
  signupPlaceholderEmail: 'e.g. abc@gmail.com',
  signupPlaceholderPassword: 'Min. 8 character with a number or symbol',
  signupSomethingWentWrong: 'Something went wrong!',
  signupSignupGoogle: 'Successfully signup with Google.',
  signupRegistered: 'Successfully registered.',
  signupConfirmAge: 'Please confirm you are 18 years old.',
  signupSignupFacebook: 'Successfully signup with Facebook.',
  signupCookiesConsent: 'Please allow third party cookies from your browser’s settings to use Social login.',
  signupRegisterEmailPopupContent: 'We sent you a verification email. Please check your email and verify your account.',
  accountMenuDeposit: 'Deposit',
  accountMenuCashout: 'Cashout',
  accountMenujackpotWinners: 'Jackpot Winners',
  accountMenuTransactions: 'Transactions',
  accountMenuPromotions: 'Promotions',
  accountMenuProfile: 'Profile',
  accountMenuLogout: 'Logout',
  accountMenuSound: 'Sound',
  accountMenuMusic: 'Music',
  accountMenuWithdraw: 'Withdraw',
  pageNotFound: 'We can’t find the page you are looking for!',
  error: 'Error',
  somethingWentWrong: 'Something went wrong!',
  verifyEmailThankYou: 'Thank You!',
  verifyEmailEmailIsVerified: 'Your email is verified.',
  verifyEmailGoToLogin: 'Go to Login page',
  verifyEmailWentWrong: 'Something went wrong!',
  verifyEmailTokenExpired: 'Email already verified or link expired',
  verifyEmailGoToHome: 'Go to Home page',
  verifyEmailSuccessMsg: 'Email successfully verified.',
  gameCardTitle: 'Wofe Gaming Provably Fair Crypto Gambling Site',
  bonusSectionTitle: 'WOFE GAMING',
  bonusSectionSpanText: 'presents the most generous and various',
  bonusSectionBonuses: 'Bonuses',
  bonusSectionSubtitleText: 'Play with pleasure and get additional rewards during the game. Choose the most attractive bonuses or use them all at once.',
  bonusSectionHeadingText: 'Bonuses from Wofe Gaming',
  bonusSectionDesc: 'As the best Bitcoin casino, Wofe Gaming has an elaborate system of generous bonuses. Let’s look at the main categories of Bonuses.',
  JoiningBonusTitle: 'Joining Bonus',
  JoiningBonusButton: 'Claim Now',
  JoiningBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  depositBonusTitle: 'Deposit Bonus',
  depositBonusButton: 'Deposit',
  depositBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  refferBonusTitle: 'Reffer Bonus',
  refferBonusButton: 'Deposit',
  refferBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  aboutSectionTitle: 'What Is Wofe Gaming?',
  aboutSectionSpanText1: ' Wofe Gaming is an innovative Bitcoin casino site, created with new concepts of online gambling in mind. We have designed this site following the latest industry standards. Moreover, we have taken into account the players wishes and needs.',
  aboutSectionSpanText2: 'The online casino allows you to play your favorite games either for free or for cryptocurrency. Also, the player gets a lot of additional bonuses and gifts. Rewards are granted for creating an account, for a successful game, and for upgrading your account/character.',
  aboutSectionSpanText3: ' In this virtual world of Bitcoin gambling, you can enjoy many opportunities — playing your favorite casino games, getting bonuses, participating in contests and promotions.',
  verifiedSectionTitle: 'Verified and Licensed Software',
  verifiedSectionSpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  faqSectionTitle: 'FAQ from Players',
  faqSectionForgetPasswordTitle: 'I forgot my password.',
  faqSectionForgetPasswordDesc: 'You did not provide a recovery email address for your account. Unfortunately it is not possible to reset an account’s password if you did not provide a recovery email address for it.',
  faqSectionMyDepositCreditedTitle: 'My deposit hasn’t been credited.',
  faqSectionMyDepositCreditedDesc: ' Placeholder content for this accordion, which is intended to demonstrate the class. This is the second item’s accordion body. Let’s imagine this being filled with some actual content.',
  SupportedCurrencyTitle: 'Supported Currency',
  SupportedCurrencySpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  CarouselSectionTitle: 'HODL CRASH',
  CarouselSectionSubTitle: ' Play more more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  WalletSettingTitle: 'Wallet Setting',
  WalletSettingHideZero: 'Hide zero balances',
  WalletSettingHideZeroInfo: 'Your zero balance won’t appear in your wallet',
  WalletSettingDisplayFiat: 'Display crypto in fiat',
  WalletSettingDisplayFiatInfo: 'All bets & transactions will be settled in the crypto equivalent',
  WalletSettingNoteForApprox: 'Please note that these are currency approximations.',
  WalletSettingSave: 'Save',
  WalletSettingNoWalletFound: 'No wallet Found',
  jackpotWinnersNoWinnerAvailable: 'No winners Available',
  jackpotWinnersNew: 'New',
  jackpotWinnersHeader: 'Jackpot Winner',
  jackpotDetailsHeader: 'Jackpot detail',
  jackpotDetailsCampaignPeriod: 'Campaign Period',
  jackpotDetailsFrom: 'From',
  jackpotDetailsTo: 'To',
  jackpotDetailsBettingRule: 'Betting Rule',
  jackpotDetailsMinBetAmount: 'Min bet amount',
  jackpotDetailsMaxBetAmount: 'Max bet amount',
  jackpotDetailsBetPercentage: 'Bet percentage',
  jackpotDetailsWinningRuleHeader: 'Winning Rule',
  jackpotDetailsWinningRule: 'Placing bets with higher bet amount will increase chances of winning.',
  jackpotDetailsProfitRule: 'If bet amount is between minBetAmount and maxBetAmount then betPercentage% of bet amount will be added to jackpot amount.',
  profileTitle: 'Personal Details',
  profileFName: 'First Name',
  profileLname: 'Last Name',
  profileUserName: 'User Name',
  profileDob: 'Date of birth',
  profileEmail: 'Email Address',
  profilePhone: 'Phone Number',
  profileChange: 'Change',
  profileSave: 'Save Change',
  profilePassword: 'Password',
  profileSubmit: 'Submit',
  profileUploadProfile: 'Upload Photo',
  profileCurrentPassword: 'Current Password',
  profileNewPassword: 'New Password',
  profileConfirmPassword: 'Confirm Password',
  profileScanQRCode: 'Scan QR Code',
  profileEnterSecretCode: 'Enter Secret Code',
  profileErrorsFirstNameRequired: 'First name is required.',
  profileErrorsFirstNamePattern: 'Please enter valid name.',
  profileErrorsFirstNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsFirstNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsLastNameRequired: 'Last name is required.',
  profileErrorsLastNamePattern: 'Please enter valid name.',
  profileErrorsLastNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsLastNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsChangePasswordOldRequired: 'Old password is required.',
  profileErrorsChangePasswordOldPattern: 'Please enter valid password.',
  profileErrorsChangePasswordOldMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordOldMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewRequired: 'New password is required.',
  profileErrorsChangePasswordNewPattern: 'Please enter valid password.',
  profileErrorsChangePasswordNewMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordNewMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Passwords do not match.',
  profileErrorsChangePasswordNewPasswordMatched: 'New password can not be the same as current password.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Confirm password is required.',
  profileErrorsUserNameRequired: 'User name is required.',
  profileErrorsUserNamePattern: 'Please enter valid name.',
  profileErrorsUserNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsDobRequired: 'Date of birth is required.',
  profileErrorsEmailRequired: 'Email is required.',
  profileErrorsEmailPattern: 'Please enter valid email.',
  profileErrorsEmailMinLength: 'Minimum 3 characters allowed.',
  profileErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  profileMessagesProfileUpdate: 'Profile updated successfully.',
  profileMessagesChangePassword: 'Password updated successfully.',
  profileProfilePicProfilePicUpload: 'Upload profile image',
  profileNoNewChanges: 'Couldn’t update profile! No new changes.',
  profileLoggedInWithSocial: 'You have logged in with social login.',
  profileSideNavigatorSound: 'Sound',
  profileSideNavigatorMusic: 'Music',
  profileSideNavigatorProfile: 'My Profile',
  profileSideNavigatorBetHistory: 'Bet History',
  profileSideNavigatorTransactions: 'Transactions',
  profileSideNavigatorDeposit: 'Deposit',
  profileSideNavigatorWithdraw: 'Withdraw',
  profileSideNavigatorGameLimits: 'Game Limits',
  profileSideNavigatorReferral: 'Referral',
  profileSideNavigatorChangePassword: 'Change Password',
  profileSideNavigatorTermsConditions: 'Terms & Conditions',
  profileSideNavigatorLogOut: 'Log Out',
  profileSideNavigatorProvablyFair: 'Provably Fair',
  WalletInfoSectionNotAvailable: 'Not Available',
  WalletInfoSectionRealBalance: 'Real Balance',
  GameLimitsMinimumBet: 'Minimum bet',
  GameLimitsMaximumBet: 'Maximum bet',
  GameLimitsMaxWinFor1Bet: 'Maximum win fir one bet',
  ProvablyFairSubTitle: 'This game uses Provably Fair technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.',
  ProvablyFairMaximumBet: 'Maximum bet:',
  ProvablyFairMaxWinFor1Bet: 'Maximum win fir one bet',
  promotionsTitle: 'Promotions',
  promotionsNoLosingData: 'Sorry, No Losing bonus promotions available for ',
  promotionsNoDepositData: 'Sorry, No Deposit bonus promotions available for ',
  promotionsCurrency: ' currency.',
  promotionsViewBtn: 'View',
  promotionsClaimSuccess: 'You have successfully claimed',
  promotionsAvailability: 'Availability: ',
  promotionsAvailabilityTabLosing: 'Losing bonus',
  promotionsAvailabilityTabDeposit: 'Deposit bonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. Deposit',
  promotionsDepositBonusTablePercentage: 'Percentage',
  promotionsDepositBonusTableMaxBonus: 'Max Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover multiplier',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover per bet',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Time to achieve rollover target',
  promotionsBonusCode: 'BONUS CODE: ',
  promotionsLossesClaim: 'Losses can be claimed for(within campaign period): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. losing amount',
  promotionsBonusStatus: 'Bonus Status: ',
  promotionsBonusAmount: 'Bonus Amount: ',
  promotionsRolloverTarget: 'Rollover target: ',
  promotionsRolloverAchieved: 'Rollover achieved: ',
  promotionsActivatebtn: 'ACTIVATE',
  promotionsClaimBtn: 'CLAIM',
  promotionsCancelBtn: 'CANCEL',
  promotionsBackBtn: 'BACK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Terms & Conditions',
  promotionsClaimDepositBonusTitle: 'Steps for claiming deposit bonus',
  promotionsClaimDepositBonusFirst: 'Activate Bonus code',
  promotionsClaimDepositBonusSecond: 'Deposit amount to wallet',
  promotionsClaimDepositBonusThird: 'Achieve rollover target for claiming bonus',
  promotionsClaimLosingBonusTitle: 'Steps for claiming losing bonus',
  promotionsClaimLosingBonusFirst: 'Activate Bonus code',
  promotionsClaimLosingBonusSecond: 'Play your favorite games',
  promotionsClaimLosingBonusThird: 'Click ’Claim losses’ to claim losses within campaign period',
  promotionsWentWrong: 'Something went wrong!',
  transactionTitle: 'Transactions',
  transactionBetId: 'Bet ID',
  transactionUser: 'User',
  transactionPayout: 'Payout',
  transactionAmount: 'Amount',
  transactionTime: 'Date',
  transactionProfit: 'Profit',
  transactionCashout: 'Cashout',
  transactionBet: 'Bet',
  transactionMultix: 'Multi.x',
  transactionWin: 'Win',
  transactionFairness: 'Fairness',
  transactionReferFriend: 'Refer a Friend',
  transactionTotal: 'Total Bets',
  transactionWins: 'Wins',
  transactionRefresh: 'Refresh',
  transactionFilterTopMultipliers: 'Top Multipliers',
  transactionFilterHugeWins: 'Huge Wins',
  transactionFilterBiggestWins: 'Biggest Wins',
  transactionFilterMultipliers: 'Multipliers',
  transactionRealBalance: 'Real Balance',
  depositWithdrawTXDate: 'Date',
  depositWithdrawTXAmount: 'Amount',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transaction type',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'No data available.',
  depositTitle: 'Deposit',
  depositHistory: 'History',
  depositSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  depositCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.\nPlease try to copy the code manually.',
  depositCopied: 'Address copied.',
  depositAddressCreated: 'Address created successfully.',
  depositGenerateAddress: 'Generate address',
  depositWarningText: 'Please do NOT deposit via cross-chain transfer',
  withdrawTitle: 'Withdraw',
  withdrawSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  withdrawNoteDesc: 'Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a LTC withdraw address wallets',
  withdrawNoteSublist: ' Please do NOT withdraw as cross-chain transfer',
  withdrawHistory: 'History',
  withdrawWithdrawBtn: 'Withdraw Request',
  withdrawFees: 'Withdrawal fee {{fees}} {{token}}',
  withdrawWithdrawPending: 'Your withdraw request is pending. Admin will Appprove it soon.',
  withdrawEnterFields: 'Enter withdraw amount and your wallet address.',
  withdrawSelectWallet: 'Please select wallet to withdraw.',
  withdrawAmountFieldTitle: 'Amount (Min. 0.03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Your {{wallet.currency.code}} address',
  withdrawAddressFieldPlaceholder: 'Type your address',
  withdrawNoBalance: 'Not enough balance.',
  withdrawMinAmount: 'Please enter amount greater than or equal to {{min}} .',
  fiatCurrencyInfo: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  paginationNextBtn: 'Next',
  paginationPrevBtn: 'Previous',
  wentWrong: 'Something went wrong.',
  referralTitle: 'Referral',
  referralSubTitle: 'Refer your Friends',
  referralEarn: ' Earn ₹150 each',
  referralReferAndEarn: 'Refer and earn bonus for each referral',
  referralReferPara: ' If you refer this code to any user & user signup with your referral code then you will get 100% bonus in your account',
  referralReferLink: 'Refer Link',
  referralCopyReferralCode: 'Copy Referral Code',
  referralTotalRewards: 'Total Reward',
  TwoWayAuthentication: 'Two Way Authentication',
  LoginRequired: 'Login required!',
  BetPlacedSuccessfully: 'Bet Placed  successfully',
  BetAddedInQueueSuccessfully: 'Bet added in queue successfully',
  BetRemovedFromQueueSuccessfully: 'Bet removed from queue successfully',
  BetCancelledSuccessfully: 'Bet cancelled  successfully',
  PlayerEscapedSuccessfully: 'Player escaped  successfully',
  CopiedSuccessfull: 'Copied successfull',
  NotEnoughBalance: 'Not enough balance',
  AutoBetStarted: 'Auto bet started ',
  AutoBetFinished: 'Auto bet finished ',
  IdCopied: 'Id copied',
  usersConsent: 'By accessing this site, I confirm that I am 18 years old, and have reviewed, \n understood and consented to the',
  termsOfService: 'Terms of Service',
  email: 'Email',
  playNow: 'Play Now',
  orContinueWith: 'Or Continue With',
  metamaskExtensionNotAvailable: 'Please install the metaMask extension and refresh the page.',
  cashier: 'CASHIER',
  account: 'ACCOUNT',
  hello: 'Hello',
  balance: 'Balance',
  referrals: 'Referrals',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  demoWallet: 'Demo Wallet',
  myAccount: 'My Account',
  wallet: 'Wallet',
  user: 'User',
  USD: 'USD',
  amountIsRequired: 'Amount is required',
  minDepositAmount: 'Deposit amount must be greater than {{min}}.',
  maxDepositAmount: 'Deposit amount must be less than {{max}}.',
  minWithdrawAmount: 'Withdraw amount must be greater than {{min}}',
  withdrawMustLessThanWallet: 'Withdraw amount must be less than your wallet amount',
  accountTypeIsRequired: 'Account Type is required.',
  mobileNumberLength: 'Mobile number must be of {{length}} digits',
  phoneIsRequired: 'Phone is required',
  cpfError: 'CPF must be of {{length}} characters',
  cpfRequiredError: 'CPF is required',
  cnpjError: 'CNPJ must be of {{length}} characters',
  mainMenu: 'MAIN MENU',
  casino: 'Casino',
  wofeOriginals: 'Wofe Originals',
  sports: 'Sports',
  crypto: 'Crypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'EARN REWARDS',
  rankUp: 'Rank Up',
  inviteFriends: 'Invite Friends',
  partnerProgram: 'Partner Program',
  home: 'Home',
  sportsbook: 'Sportsbook',
  menu: 'Menu',
  liveBets: 'LIVE BETS',
  allBets: 'All bets',
  highRollers: 'High Rollers',
  luckyBets: 'Lucky Bets',
  trade: 'Trade',
  game: 'GAME',
  player: 'PLAYER',
  time: 'TIME',
  wager: 'WAGER',
  multiplierBan: 'MULTIPLIER',
  payoutText: 'PAYOUT',
  bannerHeadOne: 'Experience true innovation with the highest',
  bannerHeadTwo: 'rewards program within the industry.',
  registerNow: 'Register Now',
  liveWins: 'LIVE WINS',
  monthlyStatus: 'M',
  weeklyStatus: 'W',
  dailyStatus: 'D',
  wofeEcosystem: 'wofe ecosystem',
  allGames: 'All Games',
  allSports: 'All Sports',
  cryptoFutures: 'crypto futures',
  tradeNow: 'Trade Now',
  $wofe: '$wofe',
  allTables: 'All Tables',
  nft: 'NFT',
  viewAll: 'View All',
  rewards: 'REWARDS',
  profile: 'Profile',
  wofeProfile: 'WOFE PROFILE',
  totalBets: 'Total Bets',
  totalWagered: 'Total Wagered',
  joinDate: 'Join Date',
  displaySocialLinks: 'Display Linked Social Accounts',
  privateProfile: 'Private Profile',
  rank: 'Rank',
  nextRank: 'Next Rank',
  changeUsername: 'Change username',
  newPassword: 'New password',
  changeName: 'Change name',
  linkSocialAccount: 'Link social account',
  privateProfileTooltip: "After making your profile private, other users won't see your user name alongside bets, races, etc.",
  socialLinksTooltip: 'Content not available',
  rankTooltip: 'Content not available',
  balances: 'balances',
  createReferralBalance: 'Create referral balance',
  total: 'Total',
  switchBalance: 'Switch Balance',
  mainAccount: 'Main Account',
  tips: 'Tips',
  receivedAmount: 'Received amount',
  dateOrTime: 'Date/Time',
  view: 'View',
  allChains: 'All Chains',
  creditAmount: 'Credit amount',
  setCustomReferralCode: 'Set custom referrals code',
  changeEmail: 'Change email',
  newEmail: 'New email',
  createPassword: 'Create password',
  newUsername: 'New username',
  username: 'Username',
  changePassword: 'Change password',
  twoFactorAuthentication: 'two-factor authentication',
  twoFactorAuthenticationTitle: 'Using two-factor authentication is highly recommended because it protects your account with both your password and your phone.',
  twoFactorAuthenticationSubTitle: 'An email and password must be set to activate 2FA. While 2FA is enabled, you will not be able to login via Steam.',
  enable2fa: 'ENABLE 2FA',
  verifyIdentityByKyc: 'Verify your identity (kyc)',
  oldPassword: 'Old password',
  live: 'Live',
  soccer: 'Soccer',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ice Hokey',
  volleyball: 'Volleyball',
  tableTennis: 'Table Tennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boxing',
  myBets: 'My Bets',
  favourites: 'Favourites',
  americanFootball: 'American Football',
  buyCrypto: 'Buy Crypto',
  oldEmail: 'Old Email',
  loggedInSuccess: 'Logged in successfully',
  loggedOutSuccess: 'Logged out successfully',
  signup: 'User has signed up successfully',
  tokenVerify: 'Token has been sent to your Email, please verify',
  emailVerify: 'Email had been verified',
  emailChangedSuccess: 'Email has been changed successfully',
  profileUpdatedSuccess: 'Profile updated',
  otpVerified: 'OTP verified',
  twoFADeactivated: '2FA Authentication deactivated',
  internalServerError: 'Internal server error',
  unAuthorized: 'Unauthorized',
  dataNotFound: 'Data Not Found',
  setReferralCode: 'Set referral code...',
  ultimate: 'ULTIMATE',
  notFound: 'Not Found',
  casinoTransactions: 'Casino Transactions',
  sportsbookTransactions: 'Sportsbook Transactions',
  cryptoTransactions: 'Crypto Transactions',
  status: 'Status',
  success: 'Success',
  failed: 'Failed',
  pending: 'Pending',
  date: 'Date',
  to: 'To',
  gameName: 'Game Name',
  transactionId: 'Transaction Id',
  gameId: 'Game Id',
  betId: 'Bet Id',
  roundId: 'Round ID',
  bet: 'Bet',
  win: 'Win',
  transactionType: 'Transaction Type',
  at: 'at',
  downloadAll: 'Download All',
  gameText: 'Game',
  actionType: 'Action Type',
  searchGames: 'Search Games',
  provider: 'Provider',
  search: 'Search',
  all: 'All',
  sortBy: 'Sort by',
  popular: 'Popular',
  challengePool: 'Challenge Pool',
  loadMore: 'Load More',
  bitcoin: 'Bitcoin',
  btc: 'BTC',
  chat: 'Chat',
  trades: 'Trades',
  battles: 'Battles',
  loginToChat: 'Login to chat',
  rules: 'Rules',
  send: 'Send',
  expand: 'Expand',
  signIn: 'Sign In',
  cross: 'Cross',
  collapse: 'Collapse',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'Remove from favourites',
  addToFavourite: 'Add to favourites',
  verify: 'Verify',
  change: 'Change',
  accountVerification: 'Account verification',
  gamesNotFound: 'Games Not Found',
  recentlyPlayed: 'Recently Played',
  moreFrom: 'More from',
  demoMode: 'Demo Mode',
  providersList: 'Providers List',
  games: 'Games',
  providers: 'Providers',
  close: 'Close',
  joinedOn: 'Joined on',
  rewarded: 'Rewarded',
  favouriteGame: 'Favourite game',
  saySomething: 'Say something',
  onlySendToAddress: 'Only send {{currencyCode}} to this address',
  yourAddress: 'Your address',
  addressIsRequired: 'Address is required',
  withdrawRequestSent: 'Withdraw request sent to Admin, Please wait for approval!',
  noMatchesFound: 'No matches found',
  tip: 'TIP',
  sendTip: 'Send tip',
  minAmount: 'Amount must be greater than {{min}}',
  maxAmount: 'Amount must be less than {{max}}',
  doNotShowTip: "Don't show tip in chat",
  demoAvailable: 'Demo available',
  denoNotAvailable: 'Demo not available',
  goFullscreen: 'Go fullscreen',
  endsAt: 'Ends at',
  inProgress: 'In progress',
  eventEnd: 'Event end',
  latestPromotions: 'Latest Promotions',
  archived: 'Archived',
  noPromotionsFound: 'No promotions found',
  claim: 'Claim',
  add: 'Add',
  viewDashboard: 'View Dashboard',
  prize: 'Prize',
  timeLeft: 'Time Left',
  viewTransactions: 'View transactions',
  sendAmountInCashier: 'Send the amount of {{currency}} of your choice to the following address to receive the equivalent in Coins.',
  yourPersonalDepositAddress: 'Your personal {{currency}} deposit address',
  copyAddress: 'Copy address',
  theValueOfCurrencyMayChange: 'The value of {{currency}} may change between now and the time we receive your payment',
  doNotHaveAnyCurrency: "Don't have any {{currency}}?",
  depositOptions: 'Deposit options',
  withdrawalOptions: 'Withdrawal options',
  withdrawCurrency: 'Withdraw {{currency}}',
  enterAddressToReceiveFunds: '  Please enter the {{currency}} wallet address you wish to receive the funds on. Once confirmed, the withdrawal is usually processed within a few minutes.',
  receivingCurrencyAddress: 'Receiving {{currency}} address',
  withdrawalAmount: 'Withdrawal amount',
  pasteYourCurrencyAddressHere: 'Paste your {{currency}} wallet address here...',
  requestWithdrawal: 'Request withdrawal',
  receiveTheSpecifiedAmount: '*You will receive the specified {{currency}} amount to your withdrawal address',
  valueMayVary: '*The value subtracted from your balance may vary between now and the time we process your withdrawal',
  coupons: 'Coupons',
  depositAddress: 'Deposit {{currency}} address',
  updateEmail: 'Update email',
  update: 'Update',
  loginHistory: 'Login history',
  showLoginHistory: 'Show login history',
  currencyErr: 'Currency Id is required',
  wagerErr: 'Wager Amount is required',
  minWagerErr: 'Minimum wager amount is 1',
  mulValErr: 'Multiplier value between 1 - 1000',
  closeBetErr: 'Enter valid close bet amount',
  profitErr: 'Enter valid take profit amount',
  multiplierErr: 'Multiplier Amount is required',
  euro: 'Euro',
  manual: 'MANUAL',
  auto: 'AUTO',
  pnLFee: 'PnL Fee',
  flatFee: 'Flat Fee',
  takeProfitLabel: 'TAKE PROFIT AT PRICE',
  closeBetLabel: 'CLOSE BET AT PRICE',
  priceUpLabel: 'WILL THE PRICE GO UP OR DOWN?',
  up: 'UP',
  down: 'DOWN',
  payoutMul: 'PAYOUT MULTIPLIER',
  bustPrice: 'Bust Price',
  safe: 'Safe',
  wild: 'Wild',
  placeBet: 'Place Bet',
  roundEnd: 'ROUND ENDS IN',
  timerInfo: 'All Rollercoaster bets will be closed and price will reset to $1,000 each day at 00:00 UTC',
  month: 'month',
  PNL: 'PNL',
  ROI: 'ROI',
  roi: 'roi',
  funding: 'Funding/H',
  exitPriceLabel: 'Exit Price',
  cashedOut: 'Cashed Out',
  open: 'Open',
  chartPreferences: 'Chart Preferences',
  yAxis: 'Y-Axis Preferences',
  additionalFeatures: 'Additional Features',
  themes: 'Themes',
  locale: 'Locale',
  changeTimezone: 'Change Timezone',
  changeLanguage: 'Change Language',
  tooltip: 'Tooltip',
  field: 'Field',
  value: 'Value',
  dateTime: 'Date/Time',
  simulator: 'simulator',
  random: 'RANDOM',
  myMarket: 'My_Market',
  marketCount: 'America/Chicago',
  mountain: 'mountain',
  candle: 'candle',
  winningAmount: 'winningAmount',
  activeBets: 'Active bets',
  closedBets: 'Closed bets',
  publicBets: 'Public bets',
  leaderboard: 'Leaderboard',
  howToplay: 'How To Play',
  rOICalculator: 'ROI Calculator',
  priceFormulation: 'Price Formulation',
  howToplayPriceFormulation: 'How To playPrice Formulation',
  allInstruments: 'All Instruments',
  week: 'Week',
  monthLabel: 'Month',
  day: 'Day',
  betLabel: 'BET',
  entryPrice: 'ENTRY PRICE',
  bustPriceLabel: 'Bust Price',
  multiplierLabel: 'MULTIPLIER',
  exitPrice: 'EXIT PRICE',
  pnl: 'P&L',
  manualType: 'manual',
  pnlType: 'pnl',
  takeProfitAmount: 'takeProfitAmount',
  profitType: 'profit',
  stopLossAmount: 'stopLossAmount',
  stopLoss: 'stopLoss',
  details: 'details',
  profitLoss: 'Profit & Loss',
  noBalance: 'Not enough balance.',
  maxBetAmount: 'Max bet amount',
  limit: 'limit exceeded.',
  minBetAmount: 'Min bet amount',
  rollsLimitMsg: 'Number of rolls must be less than or equal to 100.',
  mines: 'Mines',
  loginFailed: 'Login failed',
  noEntries: 'No entries',
  descriptionIsRequired: 'Description is required',
  description: 'Description',
  report: 'Report',
  userReportedSuccessfully: 'User reported successfully',
  tipSendSuccessfully: 'Tip send successfully',
  walletSettings: 'Wallet Settings',
  hideZero: 'Hide zero balances',
  hideZeroInfo: "Your zero balance won't appear in your wallet",
  displayFiat: 'Display crypto in fiat',
  displayFiatInfo: 'All bets & transactions will be settled in the crypto equivalent',
  noteForApprox: 'Please note that these are currency approximations.',
  save: 'Save',
  noWalletFound: 'No wallet Found',
  unableToConvert: 'Unable to convert',
  limits: 'Limits',
  timePeriodPlaceholder: 'Number of Days',
  wagerLimit: 'Wager Limit',
  lossLimit: 'Loss Limit',
  depositLimit: 'Deposit Limit',
  takeABreak: 'Take A Break',
  customTimePeriod: 'Custom Time Period',
  timePeriod: 'Time Period',
  selfExclusion: 'Self Exclusion',
  confirm: 'Confirm',
  setLimit: 'Set Limit',
  removeLimit: 'Remove Limit',
  enterAmount: 'Enter Amount',
  notSet: 'Not Set',
  hours: 'Hours',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  cancel: 'Cancel',
  usedLimit: 'Used Limit',
  type: 'Type',
  updatedAt: 'Updated At',
  editLimit: 'Edit Limit',
  limitRemovedMessage: 'Your Following Limit Will Be Removed.',
  setLimitMessage: 'You Are About To Set The Following Limit To Your Account. Please Note, That In Case You Want To Change The Limit, You Can Do That After 24hrs.',
  setBreakMessage: 'You Are About To Block The Access To Your Account For A Preset Period Of Time. Please Know That Once You Use The Option, You Will Not Be Able To Log In To Your Account To Change The Block Period. If You Would Like To Choose A Different Block Period Or Extend The Existing One, Please Contact Us Via Live Support.',
  setSessionLimitMessage: 'You Are About To Set The Following Limit To Your Account. Please Note, That In Case You Want To Change The Limit, It Will Take 24 Hours For The Limit To Be Changed After You Set Another Limit Amount.',
  removeSessionLimitMessage: 'Your Session Limit Will Be Removed',
  notAchieved: 'not achieved',
  flexibleRegistration: 'Flexible Registration!',
  mostPlayed: 'Most played',
  badge: 'Badges',
  allBadges: 'All Badges',
  socialMedia: 'Social media',
  allLinks: 'All Links',
  statistics: 'Statistics',
  newMessage: 'New message',
  tipFor: 'tip for',
  play: 'Play',
  gameOn: 'Game on!',
  betOn: 'Bet on',
  ecosystemUnbeatableOdds: 'Bet Better - Access unbeatable odds on exciting events with our top-tier betting platform!',
  startBetting: 'Start Betting',
  ecosystemJoinTheFun: 'Join the Fun - Explore Wofe’s exclusive games and over +9,000 casino favorites!',
  futures: 'Futures',
  ecosystemTrade: 'Trade Smarter, Maximize your Wins - Experience up to 1,000x leverage!',
  joinNow: 'Join Now',
  wofeCoaster: 'Vortex',
  searchCurrencies: 'Search Currencies',
  noNotificationsFound: 'No notifications found',
  notifications: 'Notifications',
  lobby: 'Lobby',
  rollercoaster: 'Vortex',
  applyReferralCode: 'Apply referral code',
  doNotHaveReferral: 'Don’t have a code? Enter "Wofe "',
  apply: 'Apply',
  referralCode: 'Referral code',
  copyCode: 'Copy code',
  referralLink: 'Referral link',
  copyLink: 'Copy link',
  referralCodeCopied: 'Referral code copied',
  referralLinkCopied: 'Referral link copied',
  withdrawAddressCannotBeSame: 'Withdraw address cannot be same as yours address',
  userId: 'User id',
  active: 'Active',
  inactive: 'Inactive',
  currency: 'Currency',
  hostGames: 'Host games',
  earnCrypto: 'Earn crypto',
  inviteHostAndEarn: 'Invite, Host & Earn - Run your own Poker Events',
  startEarning: 'Start Earning',
  wofe: 'Wofe',
  whitePaper: 'Whitepaper',
  deepDiveInto: 'Dive Deep into the Wofe Coin Economy',
  learnMore: 'Learn More',
  sport: 'sport',
  vipRanked: 'Vip ranked',
  betMoreEarnMore: 'Bet More, Earn More - Maximize your Potential',
  claimBonus: 'Claim bonus',
  referredUserId: 'Referred user id',
  bonusId: 'Bonus id',
  bonusAmount: 'Bonus amount',
  expiresAt: 'Expires on',
  claimedOn: 'Claimed on',
  preview: 'Preview',
  upload: 'Upload',
  pleaseSelectImage: 'Please select image',
  imageSizeMustLessThan: 'Image size must be less than {{size}} mb',
  imageSizeFormat: 'Use png, jpeg, jpg image format.',
  maxFileSize: 'Max. File Size: 25 MB',
  dragPosition: 'Drag to change position',
  onlyNumbers: 'Only Numbers',
  cannotBeRemoved: 'Cannot be removed',
  amountInBetween: 'Amount should be in between daily and monthly(inclusive)',
  amountEqualOrGreater: 'Amount equal or greater than weekly',
  amountEqualOrLess: 'Amount should be equal or less than weekly and monthly',
  cannotBeIncreased: 'Cannot be increased',
  enterPassword: 'Enter password',
  sessionLimit: 'Session Limit',
  timePeriodRequired: 'Time period is required',
  amountGTZero: 'Amount greater than zero',
  mma: 'MMA',
  eaSportsFifa: 'fifa',
  counterStrike: 'Counter Strike',
  rugbyUnion: 'Rugby Union',
  valorant: 'Valorant',
  cricket: 'Cricket',
  dota: 'DOTA-2',
  rugbyLeague: 'Rugby League',
  hilo: 'Hilo',
  noRewardsFound: 'No Rewards Found',
  expiredAt: 'Expires At',
  resizeErr: 'ResizeObserver loop completed with undelivered notifications.',
  debit: 'debit',
  credit: 'credit',
  bonusExpired: 'Bonus Expired',
  nonCashAmount: 'Non-Cash Amount',
  crash: 'Crash',
  cancelBetSuccessfully: 'Bet cancelled  successfully',
  start: 'Start',
  invalidBetAmount: 'Bet amount is invalid',
  zeroError: 'Bet number should be more than 0',
  loading: 'Loading',
  flipCoin: 'Coin Flip',
  customs: 'Customs',
  redeemCode: 'REDEEM COUPON CODE',
  couponText: 'We regularly post these on our',
  copyrightText: 'Copyright © 2023 wofe.app. All rights reserved. Wofe is a brand name of Wofe B.V. Company Address: Abraham de Veerstraat 9, Willemstad, Curaçao. Registration #165595.',
  platform: 'Platform',
  support: 'Support',
  faq: 'FAQ',
  partnershipProgram: 'Partnership Program',
  blog: 'Blog',
  helpCenter: 'Help Center',
  aboutus: 'About us',
  community: 'Community',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  discord: 'Discord',
  english: 'English',
  hindi: 'Hindi',
  french: 'French',
  legal: 'Legal',
  moreFromWofe: 'MORE FROM WOFE'
}
