import { ArrowRightShortIcon, ChatBadgeIconIcon, ChatSportsIcon } from 'components/ui-kit/Icons/svg/index'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Badges = () => {
  const { t } = useTranslation()

  return (
    <div className='chat-modal-content'>
      <div className='chat-badge-wrap'>
        <div className='chat-inner-header d-flex align-items-center justify-content-between'>
          <div className='chat-badge-header-left d-flex align-items-center'>
            <ChatBadgeIconIcon className='mb-1' /> <h4 className='m-0'>{t('badge')}</h4>
          </div>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center'
          >
            {t('allBadges')}
            <ArrowRightShortIcon />
          </a>
        </div>
        <div className='chat-badge-lising-wrap pt-2 d-flex align-items-center'>
          <ChatSportsIcon />
          <ChatSportsIcon />
          <ChatSportsIcon />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Badges)
