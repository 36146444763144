import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPromotionsService } from 'network/services/promotion.service'

export const getPromotions = createAsyncThunk(
  'get/promotions',
  async (data, thunkApi) => {
    try {
      const res = await getPromotionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
