import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import createSocketConnection from 'socket-resources/socket-connection/index'
import NAMESPACES from 'socket-resources/namespaces/index'
import { LIVE_ANNOUNCEMENT } from 'socket-resources/event-constants/announcements'
let liveAnnouncementInstance

const useLiveAnnouncementSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth)

  useEffect(() => {
    if (!liveAnnouncementInstance) {
      liveAnnouncementInstance = createSocketConnection(NAMESPACES.ANNOUNCEMENT, loginData?.accessToken)
    }
  }, [])

  const listenLiveAnnouncementUpdateEvent = (cb) => {
    if (liveAnnouncementInstance && cb) {
      liveAnnouncementInstance?.on(LIVE_ANNOUNCEMENT, cb)
      return () => {
        liveAnnouncementInstance.off(LIVE_ANNOUNCEMENT, cb)
      }
    }
  }

  return { listenLiveAnnouncementUpdateEvent }
}

export default useLiveAnnouncementSocketNameSpace
