import { useEffect } from 'react'
import NAMESPACES from 'socket-resources/namespaces/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import { WALLET_UPDATE_EVENT } from 'socket-resources/event-constants/wallet/index'
import createSocketConnection from 'socket-resources/socket-connection/index'

let walletSocketInstance

const useWalletSocketNameSpace = () => {
  const authToken = getAuthToken()

  useEffect(() => {
    if (!walletSocketInstance) {
      walletSocketInstance = createSocketConnection(NAMESPACES.WALLET, authToken)
    }
  }, [authToken])

  const listenWalletUpdateEvent = (cb) => {
    if (walletSocketInstance && cb) {
      walletSocketInstance?.on(WALLET_UPDATE_EVENT, cb)
      return () => {
        walletSocketInstance.off(WALLET_UPDATE_EVENT, cb)
      }
    }
  }

  return { listenWalletUpdateEvent }
}

export default useWalletSocketNameSpace
