import MainLayout from 'components/layout/MainLayout/index'
import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = ({ Component, isAuthenticated, child, showFooter }) => {
  return isAuthenticated
    ? (
      <MainLayout showFooter={showFooter}>
        <Component child={child} />
      </MainLayout>
      )
    : (
      <MainLayout showFooter={showFooter}>
        <Redirect to={{ pathname: '/' }} />
      </MainLayout>
      )
}

export default PrivateRoute
