import React from 'react'
import Tooltip from 'rc-tooltip'
import { TOOLTIP_VARIANT } from 'constants/index'

const CustomTooltip = (props) => {
  const { variant, message, children, showTooltip } = props

  switch (variant) {
    case TOOLTIP_VARIANT.SIDE_BAR:
      return showTooltip
        ? (
          <Tooltip
            placement='right'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem' }}
            overlay={<span>{message}</span>}
          >
            {children}
          </Tooltip>
          )
        : children
    case TOOLTIP_VARIANT.SIDEBAR_BUTTON:
      return showTooltip
        ? (
          <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem' }}
            overlay={<span>{message}</span>}
          >
            {children}
          </Tooltip>
          )
        : children

    default:
      break
  }
}

export default CustomTooltip
