import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  clutchWhitepaper,
  vipRanked,
  ecoFutures
} from 'components/ui-kit/Icons/png/index'
import { useHistory } from 'react-router-dom'
import { upperCase } from 'lodash'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { ROUTE_PATHS } from 'constants/index'
import { NavLink } from 'react-router-dom'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import { useDispatch } from 'react-redux'

const HomePromotions = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const handleCryptoHandler = () => {
    dispatch(setCryptoCoin({ name: 'Roller Coaster', id: '' }))
    history.push(ROUTE_PATHS.ROLLERCOASTER)
  }
  return (
    <>
      <section className='clutch-ecosystem-section home-promotion'>
        <div className='row g-2 clutch-ecosystem-row'>
          {/* <div className='col-12 col-lg-4'>
            <div className='clutch-ecosystem-card mx-0'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='cluth-ecosystem-left'>
                  <div className='casino-badge'>{t('casino')}</div>
                  <h3 className='text-uppercase'>
                    {t('hostGames')}, <br />
                    {t('earnCrypto')}
                  </h3>
                  <p>{t('inviteHostAndEarn')}</p>
                </div>
                <div className='cluth-ecosystem-right align-self-end'>
                  <img src={hotGames} alt='Hot Games' />
                </div>
              </div>

              <a href='javascript:void(0);' className='ecosystem-link-two'>
                {t('startEarning')}
              </a>
            </div>
          </div> */}
          <div className='col-12 col-lg-4'>
            <div
              className='clutch-ecosystem-card'
              onClick={handleCryptoHandler}
            >
              <div className='d-flex justify-content-between'>
                <div className='cluth-ecosystem-left'>
                  <h3>
                    1,000x <br /> {upperCase(t('crypto'))}
                    <span className='text-gradient-three text-uppercase'>{t('futures')} </span>
                  </h3>
                </div>
                <div className='cluth-ecosystem-right mt--14'>
                  <img src={ecoFutures} alt='Sports' />
                </div>
              </div>
              <p>
                {t('ecosystemTrade')}
              </p>
              <NavLink
                to={ROUTE_PATHS.ROLLERCOASTER}
                onClick={() => {
                  dispatch(setCryptoCoin({ name: 'Roller Coaster', id: '' }))
                }}
                className='ecosystem-link-three'
              >
                Start betting
              </NavLink>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div onClick={() => window.open('https://whitepaper.wofe.io/', "_blank", "noreferrer")} className='clutch-ecosystem-card mx-0'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='cluth-ecosystem-left'>
                  <div className='crypto-badge'>{t('crypto')}</div>
                  <h3 className='text-uppercase'>
                    {t('wofe')} <br />
                    {t('whitePaper')}
                  </h3>
                  <p>{t('deepDiveInto')}</p>
                </div>
                <div className='cluth-ecosystem-right align-self-end'>
                  <img src={clutchWhitepaper} alt=' Wofe Whitepaper' />
                </div>
              </div>
              <a href='https://whitepaper.wofe.io/' target='_blank' rel='noreferrer' className='ecosystem-link-three'>
                {t('learnMore')}
              </a>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className='clutch-ecosystem-card mx-0'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='cluth-ecosystem-left'>
                  <div className='sports-badge'>{t('sport')}</div>
                  <h3 className='text-uppercase'>
                    {t('vipRanked')} <br />
                    {t('rewards')}
                  </h3>
                  <p>{t('betMoreEarnMore')}</p>
                </div>
                <div className='cluth-ecosystem-right align-self-end'>
                  <img src={vipRanked} alt='Vip ranked' />
                </div>
              </div>
              <a href='javascript:void(0);' className='ecosystem-link-one'>
                {t('playNow')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomePromotions
