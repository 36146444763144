import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUsersTotalLimitsService,
  setUsersWagerLimitService,
  setUsersLossLimitService,
  setUsersDepositLimitService,
  setUsersBreakOrExclusionService,
  setUsersSessionLimitService,
  setUsersResetLimitService
} from 'network/services/limits.service'

// Get user limit details
export const getUsersTotalLimits = createAsyncThunk(
  'fetch/get-user-limit',
  async (params, thunkApi) => {
    try {
      const res = await getUsersTotalLimitsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

// Set user wager/bet limit
export const setUsersWagerLimits = createAsyncThunk(
  'user/wager-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersWagerLimitService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

// Set user loss limit
export const setUsersLossLimits = createAsyncThunk(
  'user/loss-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersLossLimitService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

// Set user deposit limit
export const setUsersDepositLimits = createAsyncThunk(
  'user/deposit-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersDepositLimitService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

// Set user break or self-exclusion
export const setUsersBreakLimits = createAsyncThunk(
  'user/break-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersBreakOrExclusionService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

// Set user session limit
export const setUsersSessionLimits = createAsyncThunk(
  'user/session-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersSessionLimitService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const setUsersResetLimits = createAsyncThunk(
  'user/reset-limit',
  async ({ params, closeModals }, thunkApi) => {
    try {
      const res = await setUsersResetLimitService(params)
      closeModals()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
