import { createAsyncThunk } from '@reduxjs/toolkit'
import { BONUS_STATUS } from 'constants/index'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { activateWageringService, claimBonusService, claimReferralBonusService, forfeitBonusService, getAllBonusService, getAllRanksDetailsOfUserService, getReferralBonusService, getSplittedBonusService, getTotalRewardsService, getUserBonusService } from 'network/services/bonus.service'
import { setReferralBonus, setSplittedBonus, setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'

export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const activateWagering = createAsyncThunk(
  'activate-bonus/wagering',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await activateWageringService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const forfeitBonus = createAsyncThunk(
  'forfiet/bonus',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await forfeitBonusService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserBonus = createAsyncThunk(
  'fetch/user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimReferralBonus = createAsyncThunk(
  'claim/referral-bonus',
  async (data, thunkApi) => {
    const { referralBonus } = thunkApi?.getState()?.bonus
    try {
      const res = await claimReferralBonusService(data)
      thunkApi?.dispatch(setReferralBonus(referralBonus?.map(bonus =>
        (bonus?.bonusId === data?.bonusId)
          ? {
              ...bonus,
              status: res?.wageredUserBonus?.status,
              claimedAt: res?.wageredUserBonus?.claimedAt
            }
          : bonus)))
      openSuccessToaster({ message: res?.message })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getReferralBonus = createAsyncThunk(
  'fetch/referral-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getReferralBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimBonus = createAsyncThunk(
  'claim/bonus',
  async (data, thunkApi) => {
    try {
      const { userBonus, splittedBonus } = thunkApi?.getState()?.bonus
      const res = await claimBonusService(data)
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus) : []
      if (splittedBonusKeys?.length > 0) {
        const updatedSplittedBonus = {}
        splittedBonusKeys?.forEach(key => {
          const updatedList = splittedBonus[key]?.map(bonus =>
            (bonus?.id === res?.userBonus?.id)
              ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: new Date() }
              : bonus
          )
          updatedSplittedBonus[key] = updatedList
        })
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      thunkApi?.dispatch(setUserBonus(userBonus?.userbonusDetails?.map(bonus =>
        ((+bonus?.id) === (+res?.userBonus?.id)) ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: new Date() } : bonus)))
      openSuccessToaster({ message: 'Bonus claimed successfully' })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getTotalRewards = createAsyncThunk(
  'total/reward',
  async (_, thunkApi) => {
    try {
      const res = await getTotalRewardsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllRanksDetailsOfUser = createAsyncThunk(
  'all-ranks-details',
  async (_, thunkApi) => {
    try {
      const res = await getAllRanksDetailsOfUserService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSplittedBonus = createAsyncThunk(
  'fetch-splitted-bonus-details',
  async (_, thunkApi) => {
    try {
      const res = await getSplittedBonusService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
