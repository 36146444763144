import React from 'react'
import { useTranslation } from 'react-i18next'
import { openInfoToaster } from 'helpers/toaster.helpers'

const CashierCoupons = () => {
  const { t } = useTranslation()

  return (
    <div
      className='tab-pane fade'
      id='pills-cashier-coupons'
      role='tabpanel'
      aria-labelledby='pills-cashier-coupons-tab'
      tabIndex='0'
    >
      <div className='coupon-section'>
        <img
          src='/assets/images/homePage/coingold.svg'
          alt='Gold'
        />
        <h4 className='text-uppercase'>{t('redeemCode')}</h4>
        <div className='form-group'>
          <input
            type='text'
            className='form-control shadow-none'
            id='newUser'
            placeholder='Enter Coupon code...'
          />
        </div>
        <p>
          {t('couponText')}
          <a href='javascript:void(0);'>Twitter</a>
        </p>
        <button
          type='button'
          className='btn btn-secondary text-uppercase'
          onClick={() => openInfoToaster({ message: 'Coming Soon!' })}
        >
          {t('claim')}
        </button>
      </div>
    </div>
  )
}

export default React.memo(CashierCoupons)
