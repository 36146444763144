import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { updateAnnouncement } from 'redux-thunk/redux/slices/announcement.slice'

const Announcements = () => {
  const dispatch = useDispatch()
  const { announcements } = useSelector(state => state.announcement)

  return (
    <div className='chat-announcement-wrap'>
      {announcements?.rows?.filter(el => el.status)?.slice(0, 3)?.map(announcement => !announcement?.seen &&
        <div
          key={announcement?.id}
          className='chat-announcement-card d-flex justify-content-between align-items-center'
        >
          <p>{`${capitalize(announcement?.title)}: ${announcement?.description}`}</p>
          <a
            href='javascript:void(0);'
            className='card-close'
            onClick={() => dispatch(updateAnnouncement({ id: announcement.id }))}
          >
            <i className='fa fa-times' aria-hidden='true' />
          </a>
        </div>)}
    </div>
  )
}

export default Announcements
