import React from 'react'
import MainLayout from 'components/layout/MainLayout/index'

const PublicRoute = ({ Component, isAuthenticated, child, showFooter = false }) => {
  return (
    <MainLayout showFooter={showFooter}>
      <Component child={child} />
    </MainLayout>
  )
}

export default PublicRoute
