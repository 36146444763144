import Tooltip from 'rc-tooltip'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseWhiteIcon } from '../Icons/svg/index'

const CommonPopup = (props) => {
  const {
    children,
    modalCloseHandler,
    classes,
    id,
    modalBodyClasses,
    modalDialogClass,
    modalContentClass
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    const body = document.body
    body?.classList.add('overflowHidden')
    return () => {
      body?.classList.remove('overflowHidden')
    }
  }, [])

  return (
    <div
      className={`modal fade show ${classes || ''}`}
      style={{ display: 'block' }}
      id={id}
      onClick={modalCloseHandler}
    >
      <div className={`modal-dialog modal-dialog-centered ${modalDialogClass || ''}`}>
        <div
          className={`modal-content ${modalContentClass || ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Tooltip
            placement='bottomRight'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem', zIndex: 9999 }}
            overlay={<span>{t('close')}</span>}
            showArrow={false}
          >
            <button
              type='button'
              className='btn-close'
              onClick={modalCloseHandler}
            >
              <CloseWhiteIcon />
            </button>
          </Tooltip>
          <div className={`modal-body ${modalBodyClasses || ''}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonPopup
