import React, { useState } from 'react'

export const GameImg = ({ imgSrc }) => {
  const [load, setLoad] = useState(true)
  return (
    <img
      src={imgSrc}
      loading='lazy'
      style={{
        filter: load ? 'blur(20px)' : 'none',
        transition: load ? 'none' : 'filter 0.1s ease-out'
      }}
      onLoad={() => {
        setLoad(false)
      }}
    />
  )
}
