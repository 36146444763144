import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import { LeftArrowIcon } from 'components/ui-kit/Icons/svg/index'
import { goldICon } from 'components/ui-kit/Icons/png/index'
import { cryptoToFiat, handleCopyCode } from 'utils/helperFunctions.utils'
import { generateWalletAddress } from 'redux-thunk/thunk/user.thunk'
import { CRYPTO_COINS, LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'

const DepositCurrencySection = ({ selectedWallet, setSelectedWallet }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { [LOADER_HANDLER_TYPES.walletAddress]: loading } = useSelector(
    (state) => state.loader
  )
  const [fiatAmount, setFiatAmount] = useState('')
  const [cryptoAmount, setCryptoAmount] = useState('')
  const PRIMARY_FIAT = 'USD'

  const generateAddress = (wallet) => {
    if (!wallet?.walletAddress) {
      const params = {
        currencyCode: wallet.currency.code,
        currencyId: Number(wallet.currency.id)
      }
      dispatch(generateWalletAddress({ params }))
    }
  }

  const handleChangeCrypto = (e) => {
    setCryptoAmount(e.target.value)
    cryptoToFiat(CRYPTO_COINS[selectedWallet?.currency?.code], PRIMARY_FIAT, e.target.value)
      .then((res) => {
        setFiatAmount(!res ? 0 : res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleChangeFiat = (e) => {
    setFiatAmount(e.target.value)
    cryptoToFiat(PRIMARY_FIAT, CRYPTO_COINS[selectedWallet?.currency?.code], e.target.value)
      .then((res) => {
        setCryptoAmount(!res ? 0 : res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (selectedWallet) {
      generateAddress(selectedWallet)
    }
  }, [selectedWallet])

  if (loading) {
    return <Loader variant={LOADER_HANDLER_TYPES.content} />
  }

  return (
    <div className='deposit-step-two'>
      <div className='cashier-heading'>
        <h3 className='text-uppercase'>
          <NavLink
            to='#'
            className='left-arrow'
            onClick={() => {
              setSelectedWallet(null)
            }}
          >
            <LeftArrowIcon />
          </NavLink>
          <div className='currency-icon'>
            <img src={selectedWallet?.currency?.image} alt='Bitcoin' />
          </div>
          {t('deposit')} {selectedWallet?.currency?.name}
        </h3>
        <NavLink
          to={ROUTE_PATHS.DEPOSIT}
          onClick={() => {
            dispatch(setShowCashierPopup(false))
          }}
        >
          {t('viewTransactions')}
        </NavLink>
      </div>
      <div className='modal-inner-card mb-2'>
        <div className='d-md-flex'>
          <p>
            {t('sendAmountInCashier', {
              currency: selectedWallet?.currency?.name
            })}
          </p>
          {selectedWallet?.walletAddress && (
            <div className='qr-code'>
              <QRCode value={selectedWallet?.walletAddress} />
            </div>
          )}
        </div>
        <div className='change-username'>
          <div className='mb-3 form-group'>
            <label htmlFor='newUser' className='form-label'>
              {t('yourPersonalDepositAddress', {
                currency: selectedWallet?.currency?.name
              })}
            </label>
            <input
              type='text'
              className='form-control form-copy shadow-none'
              id='newUser'
              value={selectedWallet?.walletAddress}
              placeholder={t('yourPersonalDepositAddress', {
                currency: selectedWallet?.currency?.name
              })}
              readOnly
            />
            <button
              type='button'
              className='btn btn-secondary text-uppercase'
              onClick={(e) =>
                handleCopyCode(
                  selectedWallet?.walletAddress,
                  t('depositCopied')
                )}
            >
              {t('copyAddress')}
            </button>
          </div>
        </div>
      </div>
      <div className='modal-inner-card mb-2'>
        <div className='d-md-flex align-items-center justify-content-center currency-converter'>
          <div className='form-group w-100'>
            <input
              type='number'
              className='form-control shadow-none'
              id='fiat'
              placeholder={t('topHeaderAmount')}
              value={fiatAmount}
              onChange={(e) => {
                handleChangeFiat(e)
              }}
            />
            <img src={goldICon} alt='Coin' className='me-2' />
          </div>
          <span>=</span>
          <div className='form-group w-100'>
            <input
              type='number'
              className='form-control shadow-none'
              id='crypto'
              placeholder={t('topHeaderAmount')}
              value={cryptoAmount}
              onChange={(e) => {
                handleChangeCrypto(e)
              }}
            />
            <img
              src={selectedWallet?.currency?.image}
              alt='Coin'
              className='me-2'
            />
          </div>
        </div>
        <p className='text-center p-0 mt-2'>
          {t('theValueOfCurrencyMayChange', {
            currency: selectedWallet?.currency?.code
          })}
        </p>
      </div>
      <div className='modal-inner-card d-md-flex align-items-center justify-content-between'>
        <div className='payment-left-card d-flex align-items-center mb-3 mb-lg-0'>
          <h4 className='m-0'>
            {t('doNotHaveAnyCurrency', {
              currency: selectedWallet?.currency?.name
            })}
          </h4>
          <button type='button' className='btn btn-info text-uppercase ms-2'>
            {t('buyCrypto')}
          </button>
        </div>
        <div className='payment-card-right'>
          <NavLink to='#'>
            <img
              src='/assets/images/homePage/master-card.png'
              alt='Master Card'
            />
          </NavLink>
          <NavLink to='#'>
            <img src='/assets/images/homePage/i-pay.png' alt='I pay' />
          </NavLink>
          <NavLink to='#'>
            <img src='/assets/images/homePage/visa.png' alt='Visa' />
          </NavLink>
          <NavLink to='#'>
            <img
              src='/assets/images/homePage/samsung-pay.png'
              alt='Samsung Pay'
            />
          </NavLink>
          <NavLink to='#'>
            <img src='/assets/images/homePage/g-pay.png' alt='Google pay' />
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default DepositCurrencySection
