import { useSelector } from 'react-redux'
// import { WalletIcoIcon } from 'components/ui-kit/Icons/svg/index'
import { useTranslation } from 'react-i18next'
import { getPrecision } from 'utils/helperFunctions.utils'
// import { CRYPTO_COINS } from 'constants/index'
// import { SearchIconIcon } from 'components/ui-kit/Icons/svg/index'
import './CurrencyDropdown.scss'
import { clutchWalletIcon } from 'components/ui-kit/Icons/png/index'

const CurrencyDropdown = (props) => {
  // const { setShowWalletSetting } = props
  const { t } = useTranslation()
  const {
    selectedWallet
    // user,
    // hideZeroCurrency,
    // displayFiatCurrency,
    // selectedFiat
  } = useSelector((state) => state.user)
  // const [filteredWallet, setFilteredWallet] = useState(user?.wallets)
  // const [inputCurrency, setInputCurrency] = useState('')
  // const selectedFiatSymbol = user?.wallets?.find(
  //   (e) => e?.currency?.code === selectedFiat
  // )?.currency?.symbol
  const PRECISION_COUNT = 2

  // const handleChangeSelectedWallet = (wallet) => {
  //   dispatch(setSelectedWallet(wallet))
  //   dispatch(setPrimaryWallet({ walletId: wallet.id }))
  // }

  // const convertToFiat = async (wallet) => {
  //   cryptoToFiat(
  //     CRYPTO_COINS[wallet?.currency?.code],
  //     selectedFiat,
  //     Number(wallet.amount)
  //   )
  //     .then((res) => {
  //       setFilteredWallet((prevState) =>
  //         prevState.map((e) =>
  //           e?.currency?.code === wallet?.currency?.code
  //             ? { ...e, convertedValue: res || 0 }
  //             : e
  //         )
  //       )
  //     })
  //     .catch((e) => {
  //       setFilteredWallet((prevState) =>
  //         prevState.map((e) =>
  //           e?.currency?.code === wallet?.currency?.code
  //             ? { ...e, convertedValue: t('unableToConvert') }
  //             : e
  //         )
  //       )
  //     })
  // }

  // const updatePrice = async () => {
  //   const wallets = hideZeroCurrency
  //     ? user?.wallets?.filter((wallet) => wallet.amount > 0)
  //     : user?.wallets
  //   setFilteredWallet(
  //     inputCurrency?.length > 0
  //       ? wallets?.filter(wallet => (lowerCase(wallet.currency.code)?.includes(lowerCase(inputCurrency))))
  //           ?.map((wallet) => ({
  //             ...wallet,
  //             convertedValue: convertToFiat(wallet)
  //           }))
  //       : wallets?.map((wallet) => ({
  //         ...wallet,
  //         convertedValue: convertToFiat(wallet)
  //       }))
  //   )
  // }

  // useEffect(() => {
  //   if (user) {
  //     if (displayFiatCurrency) {
  //       updatePrice()
  //       const updateInterval = setInterval(() => {
  //         updatePrice()
  //       }, 10000)
  //       return () => clearInterval(updateInterval)
  //     }
  //     if (hideZeroCurrency) {
  //       setFilteredWallet(
  //         inputCurrency?.length > 0
  //           ? user?.wallets?.filter((wallet) => lowerCase(wallet?.currency?.code)
  //               ?.includes(lowerCase(inputCurrency)) && wallet?.amount > 0)
  //           : user?.wallets?.filter((wallet) => wallet?.amount > 0))
  //     } else {
  //       setFilteredWallet(
  //         inputCurrency?.length > 0
  //           ? user?.wallets?.filter((wallet) => (lowerCase(wallet?.currency?.code)
  //               ?.includes(lowerCase(inputCurrency))))
  //           : user?.wallets)
  //     }
  //   }
  // }, [displayFiatCurrency, selectedFiat, hideZeroCurrency, user, inputCurrency])

  return (
    <div className='search-filter-right d-flex align-items-center currency-dropdown-wrap'>
      <button
        className='btn btn-primary currencies-btn dropdown-toggle'
        type='button'
        id='currencies_dropdown_button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <div className='d-flex main-currency-wrapper'>
          <span className='currency-main'>
            <img className='me-1' src={clutchWalletIcon} />
            {/* {selectedWallet?.currency?.code} */}
          </span>
          <div className='currency-text'>
            {`${
              selectedWallet?.currency?.isFiat
                ? selectedWallet?.currency?.symbol
                : ''
            }${selectedWallet ? getPrecision(parseFloat(selectedWallet?.amount), PRECISION_COUNT).toFixed(PRECISION_COUNT) : '$0.00'}`}
          </div>
        </div>
      </button>
      <ul
        className='dropdown-menu provider-list currencies-options'
        aria-labelledby='currencies_dropdown'
        id='currencies_dropdown'
      >
        {/* <section className='casino-search'>
          <div className='casino-search-content w-100 me-2 mb-2 mb-md-0'>
            <input
              type='text'
              className='form-control shadow-none'
              value={inputCurrency}
              id='casinoSearch'
              placeholder={t('searchCurrencies')}
              autoComplete='off'
              onChange={(e) => {
                setInputCurrency(e.target.value)
              }}
            />
            <SearchIconIcon className='search-icon' />
          </div>
        </section> */}
        <div className='provider-list-wrap'>
          <li>
            <div
              className='dropdown-item currency-img-header d-flex align-items-center justify-content-between cursor-pointer non-cash-amount'
            >
              <div className='currency-unit'>${selectedWallet?.nonCashAmount}</div>
              <div className='d-flex provider-list-right align-items-center'>
                <span>{t('nonCashAmount')}</span>
                {/* <img className='ms-2' src={selectedWallet.currency.image} /> */}
              </div>
            </div>
          </li>
          {/* {filteredWallet?.map((wallet) => (
            <li key={wallet.id}>
              <div
                className='dropdown-item currency-img-header d-flex align-items-center justify-content-between cursor-pointer'
                // onClick={() => handleChangeSelectedWallet(wallet)}
              >
                <div className='currency-unit'>
                  {wallet.currency.isFiat
                    ? `${wallet?.currency?.symbol}${getPrecision(
                        parseFloat(wallet.amount),
                        PRECISION_COUNT
                      ).toFixed(PRECISION_COUNT)}`
                    : displayFiatCurrency
                      ? `${selectedFiatSymbol}${getPrecision(
                        parseFloat(wallet.convertedValue),
                        PRECISION_COUNT
                      ).toFixed(PRECISION_COUNT)}`
                      : getPrecision(
                        parseFloat(wallet.amount),
                        PRECISION_COUNT
                      ).toFixed(PRECISION_COUNT)}
                </div>
                <div className='d-flex provider-list-right align-items-center'>
                  <span>{wallet.currency.code}</span>
                  <img className='ms-2' src={wallet.currency.image} />
                </div>
              </div>
            </li>
          ))} */}
        </div>
        {/* <div
          className='wallet-setting-btn-wrap cursor-pointer'
          onClick={(e) => {
            e.preventDefault()
            setShowWalletSetting(true)
          }}
        >
          <NavLink to='#'>
            <WalletIcoIcon />
            {t('walletSettings')}
          </NavLink>
        </div> */}
      </ul>
    </div>
  )
}

export default CurrencyDropdown
