import React, { Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import './notFound.scss'
const Loader = lazy(() => import('components/ui-kit/Loader/index'))

const NotFoundComponent = () => {
  const { t } = useTranslation()
  return (
    <Suspense fallback={<Loader />}>
      <div className='pt-5 static-root page-not-found bg-container'>
        <h2 className='text-center'>
          <i className='pr-2 fa fa-exclamation-triangle' aria-hidden='true' />
          {t('404')}
        </h2>
        <div className='mt-3 notFound text-center'>
          <p>
            {t('pageNotFound')}
          </p>
        </div>
      </div>
    </Suspense>
  )
}
export default React.memo(NotFoundComponent)
