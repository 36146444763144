import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isDemoBetPlaced: false,
  demoTargetPayout: 0,
  demoBetAmount: 0,
  demoMultiplier: 0
}

const {
  actions: {
    setDemoBetPlaced,
    setDemoTargetPayout,
    setDemoBetAmount,
    setDemoMultiplier
  },
  reducer
} = createSlice({
  name: 'demoCrashGame',
  initialState,
  reducers: {
    setDemoBetPlaced: (state, action) => {
      return {
        ...state,
        isDemoBetPlaced: action.payload
      }
    },
    setDemoTargetPayout: (state, action) => {
      return {
        ...state,
        demoTargetPayout: action.payload
      }
    },
    setDemoBetAmount: (state, action) => {
      return {
        ...state,
        demoBetAmount: action.payload
      }
    },
    setDemoMultiplier: (state, action) => {
      return {
        ...state,
        demoMultiplier: action.payload
      }
    }
  }

})

export {
  setDemoBetPlaced,
  setDemoTargetPayout,
  setDemoBetAmount,
  setDemoMultiplier
}

export default reducer
