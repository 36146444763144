import React from 'react'
import moment from 'moment/moment'
import { startCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StatisticsIcon } from 'components/ui-kit/Icons/svg/index'
import { getPrecision } from 'utils/helperFunctions.utils'

const Statistics = () => {
  const { t } = useTranslation()
  const { currencies } = useSelector((state) => state.user)
  const { userData } = useSelector((state) => state.chat)
  const mainCurrencySymbol = currencies?.find(currency => currency?.primary)?.symbol

  return (
    <div className='chat-modal-content'>
      <div className='chat-inner-header mb-3 d-flex align-items-center justify-content-between'>
        <div className='chat-badge-header-left d-flex align-items-center'>
          <StatisticsIcon className='mb-1' /> <h4 className='m-0'>{t('statistics')}</h4>
        </div>
        {/* <a
        href='javascript:void(0);'
        className='d-flex align-items-center'
      >
        All Bagdes
        <ArrowRightShortIcon />
      </a> */}
      </div>
      <div className='chat-bets-wrap d-md-flex align-items-center  my-1'>
        <div className='chat-bets d-flex align-items-center w-100'>
          <h4 className='m-0'>{startCase(t('totalBets'))}:</h4>
          <p className='m-0'>{`${mainCurrencySymbol} ${getPrecision(userData?.totalBets, 2)}`}</p>
        </div>
        <div className='chat-bets d-flex align-items-center w-100'>
          <h4 className='m-0'>{t('rewarded')}:</h4>
          <p className='m-0'>
            {`${mainCurrencySymbol} ${getPrecision(userData?.totalRewards, 2)}`}
          </p>
        </div>
      </div>
      <div className='chat-bets-wrap d-md-flex align-items-center  my-1'>
        <div className='chat-bets d-flex align-items-center w-100'>
          <h4 className='m-0'>{t('wagered')}:</h4>
          <p className='m-0'>
            {`${mainCurrencySymbol} ${getPrecision(userData?.userWagerAmount, 2)}`}
          </p>
        </div>
        <div className='chat-bets d-flex align-items-center w-100'>
          <h4 className='m-0'>{startCase(t('joinedOn'))}:</h4>
          <p className='m-0'>
            {moment(userData?.joinedDate).format('MMM D, YYYY')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Statistics)
