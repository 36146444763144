const NAMESPACES = {
  DEMO: '/demo',
  CRASH_GAME: '/crash-game',
  WALLET: '/wallet',
  LIVE_WINS: '/live-wins',
  LIVE_CHATS: '/live-chats',
  ROLLER_COASTER_GAME: '/roller-coaster-game',
  CRYPTO_FUTURE: '/crypto-futures',
  ANNOUNCEMENT: '/announcement',
  MINE_GAME: '/mine-game',
  PLINKO_GAME: '/plinko-game'
}

export default NAMESPACES
