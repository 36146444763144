import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { WalletIcoIcon } from 'components/ui-kit/Icons/svg/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setWalletSetting } from 'redux-thunk/redux/slices/user.slice'

function WalletSetting (props) {
  const { onClose } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { hideZeroCurrency, displayFiatCurrency, selectedFiat, user } =
    useSelector((state) => state.user)
  const [hideZero, setHideZeroCurrency] = useState(hideZeroCurrency)
  const [displayFiat, setDisplayFiatCurrency] = useState(displayFiatCurrency)
  const [selectedFiatCurrency, setSelectedFiat] = useState(selectedFiat)

  return (
    <CommonPopup id='walletSettingsModal' modalCloseHandler={onClose}>
      <div className='content-box m-0'>
        <div className='row g-0'>
          <div className='col-md-12'>
            <div className='side-content-box px-3'>
              <h5 className='modal-title text-center mb-4 mt-4'>
                <WalletIcoIcon /> {t('walletSettings')}
              </h5>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  dispatch(
                    setWalletSetting({
                      hideZero,
                      displayFiat,
                      selectedFiatCurrency
                    })
                  )
                  props.onClose()
                }}
              >
                <div className='d-flex custom-switch-btn-wrap mb-3 mt-3'>
                  <div className='custom-switch-btn d-flex'>
                    <input
                      type='checkbox'
                      className='switch-btn'
                      checked={hideZero}
                      name='checkbox'
                      onChange={(e) => {
                        setHideZeroCurrency(e.target.checked)
                      }}
                    />
                  </div>
                  <div className='text-box text-start input-label'>
                    <h6 className='title-text mb-0'>{t('hideZero')}</h6>
                    <p className='text mb-0'>{t('hideZeroInfo')}</p>
                  </div>
                </div>
                <div className='d-flex  custom-switch-btn-wrap mb-4'>
                  <div className='custom-switch-btn d-flex'>
                    <input
                      type='checkbox'
                      checked={displayFiat}
                      className='switch-btn'
                      onChange={(e) => {
                        setDisplayFiatCurrency(e.target.checked)
                      }}
                    />
                  </div>
                  <div className='text-box text-start input-label'>
                    <h6 className='title-text  mb-0'>{t('displayFiat')}</h6>
                    <p className='text  mb-0'>{t('displayFiatInfo')}</p>
                  </div>
                </div>
                <div className='d-flex custom-switch-btn-wrap '>
                  {user?.wallets
                    ?.filter((wallet) => wallet?.currency?.isFiat)
                    ?.map((info) => (
                      <div key={info.id} className='col-lg-3 col-6'>
                        <div className='form-check mb-2 d-flex align-items-center'>
                          <input
                            className='form-check-input me-2'
                            checked={
                              selectedFiatCurrency === info.currency.code
                            }
                            onChange={(e) =>
                              setSelectedFiat(info.currency.code)}
                            type='radio'
                            name='currencySetting'
                            id='currencySetting-1'
                            disabled={!displayFiat}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='currencySetting-1'
                          >
                            <span className='me-2'>{info.currency.code}</span>
                            <img
                              width='18'
                              height='18'
                              src={info.currency.image}
                              className='img-fluid'
                              alt='Currency'
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
                <p className='text currency-notification'>
                  {t('noteForApprox')}
                </p>
                <div className='d-flex justify-content-center'>
                  <button className='btn btn-secondary' type='submit'>
                    {t('save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </CommonPopup>
  )
}

export default WalletSetting
