import { useEffect } from 'react'
import NAMESPACES from 'socket-resources/namespaces/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import createSocketConnection from 'socket-resources/socket-connection/index'
import { CRYPTO_FUTURE_AUTOCASH_BET, CRYPTO_FUTURE_PRIZE, CRYPTO_FUTURE_PUBLIC_BET } from 'socket-resources/event-constants/cryptoFuture/socket.cryptoEvent'
import { useSelector } from 'react-redux'

let cryptoFutureInstance

const useCryptoFutureNameSpace = () => {
  const authToken = getAuthToken()
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    if (!cryptoFutureInstance) {
      cryptoFutureInstance = createSocketConnection(NAMESPACES.CRYPTO_FUTURE, authToken)
    }
  }, [authToken])

  const listenCryptoFuturePriceEvent = (cb) => {
    if (cryptoFutureInstance && cb) {
      cryptoFutureInstance.on(CRYPTO_FUTURE_PRIZE, cb)
      return () => {
        cryptoFutureInstance.off(CRYPTO_FUTURE_PRIZE, cb)
      }
    }
  }

  const listenCryptoPublicBetEvent = (cb) => {
    if (cryptoFutureInstance && cb) {
      cryptoFutureInstance?.on(CRYPTO_FUTURE_PUBLIC_BET, cb)
      return () => {
        cryptoFutureInstance.off(CRYPTO_FUTURE_PUBLIC_BET, cb)
      }
    }
  }

  const listenCryptoAutoCashOutBetEvent = (cb) => {
    if (cryptoFutureInstance && cb) {
      cryptoFutureInstance?.on(`${CRYPTO_FUTURE_AUTOCASH_BET}:${user?.id}`, cb)
      return () => {
        cryptoFutureInstance.off(`${CRYPTO_FUTURE_AUTOCASH_BET}:${user?.id}`, cb)
      }
    }
  }

  return { listenCryptoFuturePriceEvent, listenCryptoPublicBetEvent, listenCryptoAutoCashOutBetEvent }
}

export default useCryptoFutureNameSpace
