import React from 'react'
import { useSelector } from 'react-redux'
import Chat from '../Chat/index'
import Notifications from '../Notifications/index'

const RightSidebar = () => {
  const { showChat, showNotification } = useSelector(
    (state) => state?.settings
  ) // isChatCollapse

  return (
    // <div className={`chat-sidebar ${isChatCollapse ? '' : 'chat-collapse'}`}>
    <div className='chat-sidebar chat-collapse'>
      <div className='chat-content'>
        {showChat && <Chat />}
        {showNotification && <Notifications />}
      </div>
    </div>
  )
}

export default React.memo(RightSidebar)
