import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DepositCurrencySection from './DepositCurrencySection'

const CashierDeposit = () => {
  const { t } = useTranslation()
  const [selectedWallet, setSelectedWallet] = useState(null)
  const { user } = useSelector((state) => state.user)
  const { inviteFriendsStatus } = useSelector(state => state.settings)

  useEffect(() => {
    if (selectedWallet) {
      const [wallet] = user?.wallets?.filter(wallet => wallet.currencyId === selectedWallet.currencyId)
      setSelectedWallet(wallet)
    }
  }, [user])

  useEffect(() => {
    return () => { setSelectedWallet(null) }
  }, [])

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? '' : 'show active'}`}
      id='pills-cashier-deposit'
      role='tabpanel'
      aria-labelledby='pills-cashier-deposit-tab'
      tabIndex='0'
    >
      {!selectedWallet
        ? (
          <div className='deposit-step-one'>
            <div className='cashier-heading'>
              <h3 className='text-uppercase'>{t('depositOptions')}</h3>
            </div>
            <div className='deposit-card-wrap'>
              <div className='row'>
                {user?.wallets
                  ?.filter((wallet) => !wallet.currency.isFiat)
                  ?.map((wallet) => (
                    <div
                      key={wallet.currencyId}
                      className='col-12  col-md-6 col-lg-3 mb-3 px-1'
                    >
                      <div
                        className='deposit-card'
                        onClick={() => {
                          setSelectedWallet(wallet)
                        }}
                      >
                        <div className='deposit-card-icon'>
                          <img src={wallet.currency.image} alt='Coin' />
                        </div>
                        <h4>{wallet.currency.name}</h4>
                        <p>{wallet.currency.code}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          )
        : (
          <DepositCurrencySection
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
          />
          )}
    </div>
  )
}

export default CashierDeposit
