export default {
  404: '404',
  register: 'Registro',
  login: 'Acceso',
  grand: 'GRANDIOSO',
  major: 'IMPORTANTE',
  minor: 'MENOR',
  mini: 'MINI',
  crashGameTitle: 'Chocar',
  betAmount: 'montoapuesta',
  MyBets: 'mis apuestas',
  AllBets: 'Todas las apuestas',
  HighRollers: 'Apostadores altos',
  RoundHistory: 'Historial de rondas',
  Total: 'Apuestas Totales',
  topHeaderBetId: 'ID de apuesta',
  topHeaderUser: 'Usuario',
  topHeaderPayout: 'Pagar',
  topHeadeAmount: 'Cantidad',
  topHeaderTime: 'Tiempo',
  topHeaderProfit: 'Ganancia',
  topHeaderRoundCrashed: 'se estrelló',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Picadillo',
  topHeaderRoundBet: 'Apuesta',
  topHeaderRoundProfit: 'Ganancia',
  topHeaderRoundSignature: 'Firma',
  messagesNoBetHistory: 'Tu historial de apuestas está vacío',
  messagesCopyNotSupported: 'Su navegador no admite la copia automática al portapapeles.\n',
  messagesCopied: 'Dirección copiada.',
  roundHistoryTitle: 'INFORMACIÓN DEL JUEGO',
  roundHistoryDescGame: 'Juego',
  roundHistoryDescCrashAt: 'Se estrelló en:',
  roundHistoryDescDate: 'Fecha :',
  roundHistoryDescBetHeader: 'Apuesta',
  roundHistoryTableTitlePlayer: 'JUGADOR',
  roundHistoryTableTitleBet: 'APUESTA',
  roundHistoryTableTitleCashedOut: 'SIN EFECTIVO',
  roundHistoryTableTitleProfit: 'GANANCIA',
  roundHistoryTableTitleBetId: 'ID DE APUESTA',
  roundHistoryMessagesNoBetHistory: 'Tu historial de apuestas está vacío',
  placedBetsTabPlaced: 'Apuesta realizada',
  placedBetsTabPrev: 'Apuesta anterior',
  placedBetsTitleUser: 'Usuario',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Apuesta',
  placedBetsTitleProfit: 'Ganancia',
  placedBetsTitleCashout: 'retiro',
  placedBetsNoPlacedBet: 'Sin apuestas realizadas.',
  provablyFairContentCheckBtn: 'Cheque Justo Demostrable',
  provablyFairContentTitle: 'demostrablemente justo',
  provablyFairContentDesc: 'El resultado de la ventaja de cuatro lados puede probarse como justo. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Tasa de accidentes',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Tasa de accidentes sin x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hachís Redondo',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'p.ej. ',
  provablyFairContentInputFieldsSubmitBtn: 'Generar Firma',
  provablyFairContentSignature: 'Firma redonda',
  provablyFairContentErrorsCrashRate: 'por favor ingrese el número en el campo de tasa de fallas',
  levelGrand: 'GRANDIOSO',
  levelMajor: 'IMPORTANTE',
  levelMinor: 'MENOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Gana $1000 diarios ',
  bannerContentContest: 'Concursos ahora ',
  bannerContentJoin: '¡Únete a nuestro concurso de apuestas diarias y conviértete en el héroe!  ',
  bannerContentPlayNow: 'Reproducir ahora',
  bannerContentTitle: 'Gana el premio mayor',
  bannerContentContent: 'Juega más',
  bannerContentGameName: 'JUEGO DE CHOQUE',
  bannerContentGameDesc: 'Juega más',
  partners: 'Socios',
  gamesGoldenDragon: 'Dragón dorado',
  gamesCrashGame: 'juego de choque',
  gamesDice: 'Dado',
  gamesHilo: 'hilo',
  allBetsTitle: 'Todas las apuestas',
  allBetsBetId: 'ID de apuesta',
  allBetsUser: 'Usuario',
  allBetsPayout: 'Pagar',
  allBetsAmount: 'Cantidad',
  allBetsTime: 'Tiempo',
  allBetsProfit: 'Ganancia',
  loginWithOtp: 'Iniciar sesión con Otp',
  enterLoginOtp: 'Entrar Otp de inicio de sesión',
  submit: 'Entregar',
  footerAboutUs: 'Sobre nosotros',
  footerFaq: 'preguntas frecuentes',
  footerSitePolicy: 'Política del sitio',
  footerResponsibleGambling: 'Juego Responsable',
  footerTerms: 'Términos y condiciones',
  footerCopywrite: 'LÍNEA CRIPTO 2022. ',
  signinTitle: 'Acceso',
  signinUsername: 'Nombre de usuario / Correo electrónico',
  signinPassword: 'Contraseña',
  signinLoginBtn: 'Acceso',
  signinSignup: '¡Inscribirse!',
  signinForgetPassword: '¿Has olvidado tu contraseña?',
  signinDontHaveAccount: '¿No tienes una cuenta? ',
  signinCreateAccount: 'Crear una cuenta',
  signinLoginDescription: 'En ocasiones, Wofe Gaming puede enviarle ofertas gratuitas y promociones especiales por correo electrónico o SMS. ',
  signinErrorsUserNameRequired: '*Por favor ingrese nombre de usuario.',
  signinErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signinErrorsUserNameMaxLength: 'Se permite un máximo de 20 caracteres.',
  signinErrorsUserNamePattern: 'Ingrese un nombre de usuario válido, solo se permiten letras y números.',
  signinErrorsPasswordRequired: '*Se requiere pasaporte.',
  signinErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signinErrorsPasswordMaxLength: 'Máximo de 16 caracteres permitidos.',
  signinErrorsPasswordPattern: '*La contraseña debe tener de 8 a 16 caracteres alfanuméricos y especiales. ',
  signinSuccessfullyLoggedIn: 'Iniciado sesión con éxito.',
  signinResetMailSent: 'Correo electrónico de restablecimiento de contraseña enviado.',
  signinTryAgain: 'Algo salió mal. Por favor, vuelva a intentarlo.',
  signinResetPasswordUsernameErr: 'Ingrese el nombre de usuario/correo electrónico para restablecer la contraseña.',
  signinResetMailSentSuccessContent: 'Le enviamos un enlace para restablecer la contraseña en su correo electrónico. ',
  signupTitle: 'Registro',
  signupUsername: 'Nombre de usuario',
  signupEmail: 'Correo electrónico',
  signupTermsConditions: 'Términos',
  signupWalletConnect: 'Iniciar sesión con Monedero ',
  signupPassword: 'Contraseña',
  signupOver18: 'Soy mayor de 18 años y acepto los Términos de servicio',
  signupRegisterBtn: 'Inscribirse',
  signupUseSocialAccount: 'O usa una cuenta social',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: '¿Tener una cuenta? ',
  signupLoginBtn: 'Acceso',
  signupErrorsUserNameRequired: '*Por favor ingrese nombre de usuario.',
  signupErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsUserNameMaxLength: 'Se permite un máximo de 20 caracteres.',
  signupErrorsUserNamePattern: 'Ingrese un nombre de usuario válido, solo se permiten letras y números.',
  signupErrorsEmailRequired: '*Por favor ingrese correo electrónico.',
  signupErrorsEmailMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsEmailMaxLength: 'Se permite un máximo de 20 caracteres.',
  signupErrorsEmailpattern: 'Por favor introduzca un correo electrónico válido.',
  signupErrorsPasswordRequired: '*Se requiere pasaporte.',
  signupErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signupErrorsPasswordMaxLength: 'Máximo de 16 caracteres permitidos.',
  signupErrorsPasswordPattern: '*La contraseña debe tener de 8 a 16 caracteres alfanuméricos y especiales. ',
  placeholderUserName: 'mín. ',
  placeholderEmail: 'p.ej. ',
  placeholderPassword: 'mín. ',
  signUpSomethingWentWrong: '¡Algo salió mal!',
  signupRegistered: 'Registrado exitosamente.',
  signupConfirmAge: 'Confirma que tienes 18 años.',
  signupCookiesConsent: 'Permita las cookies de terceros desde la configuración de su navegador para usar el inicio de sesión social.',
  signupRegisterEmailPopupContent: 'Te enviamos un correo electrónico de verificación. ',
  accountMenuDeposit: 'Depósito',
  accountMenuCashout: 'retiro',
  accountMenuJackpotWinners: 'Ganadores del premio mayor',
  accountMenuTransactions: 'Actas',
  accountMenuPromotions: 'Promociones',
  accountMenuProfile: 'Perfil',
  accountMenuLogout: 'Cerrar sesión',
  accountMenuSound: 'Sonido',
  accountMenuMusic: 'Música',
  accountMenuWithdraw: 'Retirar',
  pageNotFound: '¡No podemos encontrar la página que estás buscando!',
  error: 'Error',
  somethingWentWrong: '¡Algo salió mal!',
  verifyEmailThankYou: '¡Gracias!',
  verifyEmailEmailIsVerified: 'Su correo electrónico está verificado.',
  verifyEmailGoToLogin: 'Ir a la página de inicio de sesión',
  verifyEmailWentWrong: '¡Algo salió mal!',
  verifyEmailGoToHome: 'Ir a la página de inicio',
  verifyEmailSuccessMsg: 'Correo electrónico verificado con éxito.',
  gameCardTitle: 'Sitio de apuestas criptográficas Provably Fair de Wofe Gaming',
  bonusSectionTitle: 'JUEGO DE BITS',
  bonusSectionSpan_text: 'presenta los más generosos y variados',
  bonusSectionBonuses: 'Bonos',
  bonusSectionSubtitle_text: 'Juega con placer y obtén recompensas adicionales durante el juego. ',
  bonusSectionHeading_text: 'Bonos de Wofe Gaming',
  bonusSectionDesc: 'Como el mejor casino de Bitcoin, Wofe Gaming tiene un elaborado sistema de generosos bonos. ',
  joiningBonusTitle: 'Bono de unión',
  joiningBonusButton: 'Reclama ahora',
  joiningBonusDesc: 'Dichos bonos alientan a los jugadores de casino en línea a apostar usando Bitcoin u otras criptomonedas. ',
  depositBonusTitle: 'Bono de depósito',
  depositBonusButton: 'Depósito',
  depositBonusDesc: 'Dichos bonos alientan a los jugadores de casino en línea a apostar usando Bitcoin u otras criptomonedas. ',
  referBonusTitle: 'Bono de recomendación',
  referBonusButton: 'Depósito',
  referBonusDesc: 'Dichos bonos alientan a los jugadores de casino en línea a apostar usando Bitcoin u otras criptomonedas. ',
  aboutSectionTitle: '¿Qué es Wofe Gaming',
  aboutSectionSpan_text1: ' Wofe Gaming es un sitio de casino de Bitcoin innovador, creado con nuevos conceptos de juego en línea en mente. ',
  aboutSectionSpan_text2: 'El casino en línea le permite jugar sus juegos favoritos de forma gratuita o con criptomonedas. ',
  aboutSectionSpan_text3: ' En este mundo virtual de apuestas con Bitcoin, puede disfrutar de muchas oportunidades: jugar sus juegos de casino favoritos, obtener bonificaciones, participar en concursos y promociones.',
  verifiedSectionTitle: 'Software verificado y con licencia',
  verifiedSectionSpan_text1: 'No copiamos ni adoptamos software de otros sitios web. ',
  faqSectionTitle: 'Preguntas frecuentes de los jugadores',
  faqSectionForgetPasswordTitle: 'Olvidé mi contraseña.',
  faqSectionForgetPasswordDesc: 'No proporcionó una dirección de correo electrónico de recuperación para su cuenta. ',
  faqSectionMyDepositCreditedTitle: 'Mi depósito no ha sido acreditado.',
  faqSectionMyDepositCreditedDesc: ' Contenido de marcador de posición para este acordeón, que pretende demostrar la clase. ',
  SupportedCurrencyTitle: 'Moneda admitida',
  SupportedCurrencySpan_text1: 'No copiamos ni adoptamos software de otros sitios web. ',
  CarouselSectionTitle: 'ACCIDENTE DE HODL',
  CarouselSectionSubTitle: ' Juega más juegos y ten la oportunidad de ganar. ',
  WalletSettingTitle: 'Configuración de billetera',
  WalletSettingHideZero: 'Ocultar saldos cero',
  WalletSettingHideZeroInfo: 'Su saldo cero no aparecerá en su billetera',
  WalletSettingDisplayFiat: 'Mostrar criptografía en fiat',
  WalletSettingDisplayFiatInfo: 'Todas las apuestas',
  WalletSettingNoteForApprox: 'Tenga en cuenta que estas son aproximaciones de moneda.',
  WalletSettingSave: 'Ahorrar',
  WalletSettingNoWalletFound: 'No se encontró la billetera',
  promotionsTitle: 'Promociones',
  promotionsNoLosingData: 'Lo sentimos, las promociones de bonificación No Losing están disponibles para ',
  promotionsNoDepositData: 'Lo sentimos, promociones de bonificación sin depósito disponibles para ',
  promotionsCurrency: ' divisa.',
  promotionsViewBtn: 'Vista',
  promotionsClaimSuccess: 'Has reclamado con éxito',
  promotionsAvailability: 'Disponibilidad: ',
  promotionsTabLosing: 'Bonificación por perder',
  promotionsTabDeposit: 'bono de depósito',
  promotionsDepositBonusTableCode: 'Código',
  promotionsDepositBonusTableMinDeposit: 'mín. ',
  promotionsDepositBonusTablePercentage: 'Porcentaje',
  promotionsDepositBonusTableMaxBonus: 'Bono máximo',
  promotionsDepositBonusTableRolloverMultipler: 'multiplicador de rollover',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover máximo por apuesta',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Tiempo para lograr el objetivo de rollover',
  promotionsBonusCode: 'CÓDIGO EXTRA: ',
  promotionsLossesClaim: 'Se pueden reclamar pérdidas por (dentro del período de la campaña): ',
  promotionslosingBonusTablePercentage: 'Porcentaje',
  promotionslosingBonusTableMinLosingAmount: 'mín. ',
  promotionsBonusStatus: 'Estado de bonificación: ',
  promotionsBonusAmount: 'Monto de la bonificación: ',
  promotionsRolloverTarget: 'Objetivo de rollover: ',
  promotionsRolloverAchieved: 'Rollover logrado: ',
  promotionsActivatebtn: 'ACTIVAR',
  promotionsClaimBtn: 'AFIRMAR',
  promotionsCancelBtn: 'CANCELAR',
  promotionsBackBtn: 'ATRÁS',
  promotionsBonus: 'Prima',
  promotionsTermAndConditions: ' Términos',
  promotionsClaimDepositBonusTitle: 'Pasos para reclamar el bono de depósito',
  promotionsClaimDepositBonusFirst: 'Activar código de bonificación',
  promotionsClaimDepositBonusSecond: 'Importe del depósito en la billetera',
  promotionsClaimDepositBonusThird: 'Lograr el objetivo de rollover para reclamar el bono',
  promotionsClaimLosingBonusTitle: 'Pasos para reclamar el bono perdedor',
  promotionsClaimLosingBonusFirst: 'Activar código de bonificación',
  promotionsClaimLosingBonusSecond: 'Juega tus juegos favoritos',
  promotionsClaimLosingBonusThird: 'Haga clic en ’Reclamar pérdidas’ para reclamar pérdidas dentro del período de la campaña',
  promotionsWentWrong: '¡Algo salió mal!',
  transactionTitle: 'Actas',
  transactionBetId: 'ID de apuesta',
  transactionUser: 'Usuario',
  transactionPayout: 'Pagar',
  transactionAmount: 'Cantidad',
  transactionTime: 'Fecha',
  transactionProfit: 'Ganancia',
  transactionCashout: 'retiro',
  transactionBet: 'Apuesta',
  transactionMultix: 'Multi.x',
  transactionWin: 'Ganar',
  transactionFairness: 'Justicia',
  transactionReferFriend: 'Recomendar un amigo',
  transactionTotal: 'Apuestas Totales',
  transactionWins: 'victorias',
  transactionRefresh: 'Actualizar',
  transactionFilterTopMultipliers: 'Principales multiplicadores',
  transactionFilterHugeWins: 'Grandes victorias',
  transactionFilterBiggestWins: 'Mayores victorias',
  transactionFilterMultipliers: 'multiplicadores',
  transactionRealBalance: 'Saldo Real',
  transactionTabMyBets: 'mis apuestas',
  transactionTabDeposit: 'Depósito',
  transactionTabCashout: 'Retiros',
  transactionTabAllbets: 'Todas las apuestas',
  transactionTabTopbets: 'Apuestas principales',
  transactionTabBonus: 'Prima',
  transactionTabJackpot: 'Bote',
  transactionTabBetHistory: 'Historial de apuestas',
  transactionDepositWithdrawTXdate: 'Fecha',
  transactionDepositWithdrawTXAmount: 'Cantidad',
  transactionDepositWithdrawTXTxId: 'Tx ID',
  transactionDepositWithdrawTXTxType: 'Tipo de transacción',
  transactionDepositWithdrawTXStatus: 'Estado',
  transactionDepositWithdrawTXNoData: 'Datos no disponibles.',
  transactionDeposittitle: 'Depósito',
  transactionDeposithistory: 'Historia',
  transactionDepositsub_title: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  transactionDepositcopyNotSupported: 'Su navegador no admite la copia automática al portapapeles.\n',
  transactiondDepositcopied: 'Dirección copiada.',
  transactionDepositaddressCreated: 'Dirección creada con éxito.',
  transactionDepositgenerateAddress: 'Generar dirección',
  transactionDepositwarningText: 'NO deposite a través de una transferencia entre cadenas',
  transactionWithdrawTitle: 'Retirar',
  transactionWithdrawSub_title: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  transactionWithdrawNoteDesc: 'Asegúrese de NO ingresar la dirección BEP2, BEP20 (BSC) como billeteras de dirección de retiro de LTC',
  transactionWithdrawNote_Sublist: ' Por favor NO retire como transferencia de cadena cruzada',
  transactionWithdrawHistory: 'Historia',
  transactioWithdrawBtn: 'Petición de retiro',
  transactionWithdrawfees: 'Cargo por retiro {{fees}} {{token}}',
  transactionWithdrawPending: 'Su solicitud de retiro está pendiente. ',
  transactionWithdrawEnterFields: 'Ingrese el monto del retiro y la dirección de su billetera.',
  transactionwithdrawSelectWallet: 'Seleccione la billetera para retirar.',
  transactionAmountFieldTitle: 'Importe (mín. 0,03 {{wallet.currency.code}})',
  transactionAmountFieldPlaceholder: '0',
  transactionAddressFieldTitle: 'Su {{wallet.currency.code}} DIRECCIÓN',
  transactionAddressFieldPlaceholder: 'Escriba su dirección',
  transactionNoBalance: 'No hay suficiente equilibrio.',
  transactionMinAmount: 'Por favor ingrese una cantidad mayor o igual a {{min}} .',
  transactionFiatCurrencyInfo: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  transactionPaginationNextBtn: 'Próximo',
  transactionPaginationPrevBtn: 'Anterior',
  transactionWentWrong: 'Algo salió mal.',
  transactionReferralTitle: 'Remisión',
  transactionReferralSub_title: 'Recomiende a sus amigos',
  transactionReferraLearn: ' Gana ₹ 150 cada uno',
  transactionReferralReferAndEarn: 'Recomiende y gane bonos por cada referencia',
  transactionReferralReferPara: ' Si refiere este código a cualquier usuario',
  transactionReferralReferLink: 'Enlace de referencia',
  transactionReferralCopyReferralCode: 'Copiar código de referencia',
  transactionReferralTotalRewards: 'Recompensa total',
  mobileLeftNavRegister: 'Registro',
  mobileLeftNavLogin: 'Acceso',
  mobileLeftNavGrand: 'grandioso',
  mobileLeftNavMajor: 'Importante',
  mobileLeftNavMinor: 'Menor',
  mobileLeftNavMini: 'Mini',
  inputFieldBetAmount: 'Monto de la apuesta',
  inputFieldProfit: 'Ganancia',
  inputFieldPayout: 'Pago objetivo',
  inputFieldChance: 'Oportunidad de ganar',
  inputFieldBetBtn: 'Apuesta',
  inputFieldAutoCashout: 'Retiro automático',
  btnInputFieldhalf: '½',
  btnInputFielddouble: '2×',
  btnInputFieldmax: 'máx.',
  fieldsprofit: 'Ganancia',
  fieldswagered: 'apostado',
  fieldslost: 'Perdido',
  fieldsWon: 'Ganado',
  fieldsStreak: 'Racha',
  fieldsHighestWin: 'victoria más alta',
  fieldsHighestLost: 'Mayor perdido',
  fieldsHighestBet: 'Apuesta más alta',
  fieldsMaxStreak: 'racha máxima',
  fieldsMaxProfit: 'Beneficio máximo: ',
  fieldsBal: 'pelota: ',
  messagesPlaceBetSuccess: 'Apuesta realizada con éxito',
  messagesCancelBetSuccess: 'Apuesta cancelada con éxito',
  messagesEscapeBetSuccess: 'Apuesta escapada con éxito',
  messagesMaxProfitWarning: 'Excede la ganancia máxima para este juego',
  messagesMinAmountWarning: 'La cantidad debe ser al menos 0.00000001',
  messagesnoAmountWarning: 'Por favor, compruebe el importe de su apuesta',
  messagesinSufficientBalanceWarning: 'No hay suficiente equilibrio.',
  messagesNoTargetPayoutWarning: 'Por favor, compruebe su pago objetivo',
  messagesMinTargetPayoutWarning: 'El pago objetivo debe ser al menos 1.01',
  messagesMinWinChanceWarning: 'La probabilidad de ganar debe ser al menos 0.000099',
  messagesPlaceBetWarning: 'Inicie sesión para realizar apuestas.',
  messagesAutoRateWarning: 'La tasa automática debe ser mayor o igual a 1.01.',
  messagesNoBalance: 'No hay suficiente equilibrio.',
  messagesMaxBetAmount: 'Monto máximo de la apuesta',
  messagesLimit: 'límite excedido.',
  messagesMinBetAmount: 'Monto mínimo de la apuesta',
  messagesNotAchieved: 'no logrado.',
  messagesPlaceBetOnNextRound: 'Por favor, espere hasta la próxima ronda.',
  messagesPlaceAutoBetOnNextRound: 'Por favor, espere hasta la próxima ronda.',
  toolTipDeposit: 'Depósito',
  toolTipStatReset: 'Restablecer estadísticas',
  toolTipMaxProfit: 'Beneficio máximo',
  rollOverTitle: 'Número de rollos',
  rollOverPlaceholder: 'Introduzca el número de rollos',
  rollOverConfirmBtn: 'Confirmar',
  rollOverRollsLimitMsg: 'El número de rollos debe ser menor o igual a 100.',
  TwoWayAuthentication: 'Autenticación bidireccional',
  LoginRequired: 'Inicio de sesión obligatorio!',
  BetPlacedSuccessfully: 'Apuesta realizada con éxito',
  BetAddedInQueueSuccessfully: 'Apuesta añadida en cola con éxito',
  BetRemovedFromQueueSuccessfully: 'Apuesta retirada con éxito de la cola',
  BetCancelledSuccessfully: 'Apuesta anulada con éxito',
  PlayerEscapedSuccessfully: 'El jugador ha escapado con éxito',
  CopiedSuccessfull: 'Copiado correcto',
  NotEnoughBalance: 'Equilibrio insuficiente',
  AutoBetStarted: 'Apuesta automática iniciada ',
  AutoBetFinished: 'Apuesta automática finalizada ',
  IdCopied: 'Id Copia'
}
