import * as PIXI from 'pixi.js'
import Matter from 'matter-js'
import { assets, deviceFPS, linesNum, matterAssets } from './gameUI.js'
// import { app } from "./appPixi.js";
import { pixiApp } from './initializePixi.js'
import { averageFPS, ballTimeScale, getFPS, startGameTicker, stopGameTicker } from './gameLogic.js'
import { dummyData, settings, xForce, xForceSameRow, yForce } from '../settings.js'
import { ballAndPointsNewRadius, pointsPerRow } from './utility.js'
import { gapX } from './pointAndBox.js'

const totalBallsInGame = 0
let gX = 0
export const createBall = (x, y, pathArray, ballStartPos) => {
  // using random drop point
  // const rand = Math.round(Math.random() * 2)
  // console.log(rand)
  // if (rand == 0) {
  //   gX = -gapX
  // } else if (rand == 2) {
  //   gX = gapX
  // } else {
  //   gX = 0
  // }

  // using data for drop point
  if (ballStartPos == 0) {
    gX = -gapX
  } else if (ballStartPos == 2) {
    gX = gapX
  } else {
    gX = 0
  }
  const ball = generateNewBall((pixiApp.app.screen.width * 0.5) + gX, y, 0, pathArray)
  // const ball = generateNewBall((pixiApp.app.screen.width * 0.5) + 0, y, 0)
  ball.destroy = function () {
    const index = assets.activeBalls.indexOf(this)
    if (index !== -1) {
      assets.activeBalls.splice(index, 1)
      pixiApp.app.stage.removeChild(this.pixiBody)
      this.pixiBody.destroy()
      this.pixiBody = null
      Matter.World.remove(matterAssets.world, this.matterBody)
      this.matterBody = null
      delete this
    }
  }

  assets.activeBalls.push(ball)
}

export function path (ball, point) {
  Matter.Body.setStatic(ball, true)
  Matter.Body.setPosition(ball, {
    x: point.position.x,
    y: point.position.y - point.circleRadius * 2

  })
  // console.log(point.pointRow, point.pointNumber)
  // console.log(random , "random")
  // const pathRandom = Math.round(Math.random() * 4)
  // if (pathRandom == 1) {

  // } else {

  // }

  // NOTE: using randomly generated values
  //= ===============================================================
  // setTimeout(() => {
  //   Matter.Body.setVelocity(ball, {
  //     x: Math.round(Math.random() * 1) ? xForce[(linesNum.get() + 1) - xForce.length] : -xForce[(linesNum.get() + 1) - xForce.length],
  //     y: -yForce[(linesNum.get() + 1) - yForce.length]
  //   })
  // }, 0)
  //= ===============================================================
  // NOTE: using data
  //= ===============================================================
  // console.log(point.pointRow)
  // console.log( ballPath[point.pointRow])
  setTimeout(() => {
    Matter.Body.setVelocity(ball, {
      x: ball.ballPath[point.pointRow] ? xForce[(linesNum.get() + 1) - xForce.length] : -xForce[(linesNum.get() + 1) - xForce.length],
      y: -yForce[(linesNum.get() + 1) - yForce.length]
    })
  }, 30)
  //= ===============================================================
  Matter.Body.setStatic(ball, false)
}

export const destroyAllBalls = () => {
  return new Promise((resolve, reject) => {
    // stopGameTicker()
    let i = assets.activeBalls.length - 1
    while (i >= 0) {
      assets.activeBalls[i].destroy()
      i--
    }
    assets.activeBalls = []
    // startGameTicker()
    resolve()
  })
}

function generateNewBall (x, y, initialX, pathArray) {
  const ballRadius = ballAndPointsNewRadius(linesNum.get()) * 3.4
  return {
    matterBody: matterBall(x, y, ballRadius, initialX, pathArray),
    pixiBody: pixiBall(x, y, ballRadius)
  }
}


function matterBall (x, y, ballRadius, initialX, pathArray) {
  const ball = Matter.Bodies.circle(x, y, ballRadius, {
    restitution: 0, // 0.66,
    friction: 0, // 0.9
    density: 1, // 2
    elasticity: 0,
    collisionFilter: {
      category: 0x0002,
      mask: 0x0001
    },
    timeScale:60/averageFPS * 0.85,
    label: 'Ball',
    ballNum: totalBallsInGame,
    initialX: initialX,
    prevPointX: initialX,
    ballPath: pathArray,
  })

  console.log(ball.timeScale , "timescale: " ,getFPS() * 0.85)

  Matter.World.add(matterAssets.world, ball)
  return ball
}

function pixiBall (x, y, ballRadius) {
  const ball = new PIXI.Sprite(PIXI.Assets.get('ball'))
  ball.anchor.set(0.5)
  ball.x = x
  ball.y = y
  // ball.scale.set(0.8)
  ball.width = ballRadius * 2
  ball.height = ballRadius * 2
  pixiApp.app.stage.addChild(ball)
  return ball
}
