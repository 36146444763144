import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Tooltip from 'rc-tooltip'
import { ChatBubbleIcon, UiChartIcon, ChatCloseIcon } from 'components/ui-kit/Icons/svg/index'
import { useTranslation } from 'react-i18next'
import {
  setChatCollapse,
  setShowChat,
  setShowNotification
} from 'redux-thunk/redux/slices/settings.slice'
import { getChatGroups } from 'redux-thunk/thunk/chat.thunk'
import { setSelectedChatGroupId } from 'redux-thunk/redux/slices/chat.slice'
// import { earthIcon } from 'components/ui-kit/Icons/png/index'

const RightSidebarHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isChatCollapse, showChat } = useSelector((state) => state.settings)
  const { chatGroups, selectedChatGroupId } = useSelector(
    (state) => state.chat
  )

  const handleCloseChat = useCallback(() => {
    dispatch(setShowChat(false))
    dispatch(setShowNotification(false))
    dispatch(setChatCollapse(true))
  }, [])

  const handleClickChat = useCallback(() => {
    dispatch(setShowChat(true))
    dispatch(setShowNotification(false))
  }, [])

  useEffect(() => {
    dispatch(getChatGroups())
  }, [])

  const selectedChatGroupName = chatGroups?.rows?.find(
    (ele) => ele.id.toString() === selectedChatGroupId.toString()
  )?.name

  return (
    <div className='chat-header justify-content-between'>
      <div className='d-flex'>
        <button
          className={`btn btn-primary chat-btn  ${showChat ? 'active' : ''}`}
          type='button'
          onClick={handleClickChat}
        >
          <ChatBubbleIcon />
          {selectedChatGroupName?.length < 9 && t('chat')}
        </button>
        <div className='dropdown language-btn'>
          <button
            className='btn btn-primary dropdown-toggle shadow-none'
            type='button'
            id='group-dropdown'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <UiChartIcon />
            {selectedChatGroupName}
          </button>
          <ul className='dropdown-menu' aria-labelledby='group-dropdown'>
            {chatGroups?.rows?.length > 0 &&
              chatGroups?.rows?.map((group) => (
                <li key={group?.id}>
                  <NavLink
                    activeClassName='dropdown-item'
                    to='#'
                    onClick={() => dispatch(setSelectedChatGroupId(group?.id))}
                  >
                    <img src={group?.groupLogo} width={17} height={17} alt={group?.name} />
                    {group?.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className='d-flex chat-close-wrap'>
        <Tooltip
          placement='left'
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{isChatCollapse ? t('expand') : t('collapse')}</span>}
        >
          <NavLink
            to='#'
            activeClassName='chat-collapse-icon d-none'
            onClick={() => dispatch(setChatCollapse(!isChatCollapse))}
          >
            {/* <i className="fa fa-arrow-left" aria-hidden="true" /> */}
            <ChatCloseIcon />
          </NavLink>
        </Tooltip>
        <Tooltip
          placement='left'
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{t('close')}</span>}
          showArrow
        >
          <NavLink
            to='#'
            activeClassName='chat-close'
            onClick={handleCloseChat}
          >
            <i className='fa fa-times' aria-hidden='true' />
          </NavLink>
        </Tooltip>
      </div>
    </div>
  )
}

export default RightSidebarHeader
