import { EthiconIcon, ExploreNftIcon, JupiterIconIcon, RenderIconIcon, SeiIconIcon, SliderRightArrowIcon } from 'components/ui-kit/Icons/svg/index'
import React, { useMemo } from 'react'
import Slider from 'react-slick'
import './explore.scss'

const ExploreSection = () => {
  const homeNftSettings = useMemo(() => ({
    initialSlide: 0,
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }), [])

  return (
    <section className='nft-section'>
      <div className='inner-heading'>
        {/* <img src='/assets/images/homePage/nft-heading.svg' alt='NFT' /> */}
        <ExploreNftIcon />
        <h4 className='text-uppercase'>Explore Wofe</h4>
        {/* <a
          href='' className='heading-link' onClick={(e) => {
            e.preventDefault()
            openInfoToaster({ message: 'Coming Soon!' })
          }}
        >{t('viewAll')}
        </a> */}
      </div>
      <div className='slide-wrap'>
        <Slider {...homeNftSettings}>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='explore-heading'>

                  <h1>1000x Futures</h1>
                </div>
                <div className='explore-text'><p>Trade a diverse selection of coins with up to 1000x leverage.</p></div>
                <div className='explore-content'>
                  <div className='content-left'>
                    <EthiconIcon />
                    <RenderIconIcon />
                    <JupiterIconIcon />
                    <SeiIconIcon />
                  </div>
                  <div className='content-right'>
                    <a>All Futures <SliderRightArrowIcon /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='explore-heading'>

                  <h1>1000x Futures</h1>
                </div>
                <div className='explore-text'><p>Trade a diverse selection of coins with up to 1000x leverage.</p></div>
                <div className='explore-content'>
                  <div className='content-left'>
                    <EthiconIcon />
                    <RenderIconIcon />
                    <JupiterIconIcon />
                    <SeiIconIcon />
                  </div>
                  <div className='content-right'>
                    <a>All Futures <SliderRightArrowIcon /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='explore-heading'>

                  <h1>1000x Futures</h1>
                </div>
                <div className='explore-text'><p>Trade a diverse selection of coins with up to 1000x leverage.</p></div>
                <div className='explore-content'>
                  <div className='content-left'>
                    <EthiconIcon />
                    <RenderIconIcon />
                    <JupiterIconIcon />
                    <SeiIconIcon />
                  </div>
                  <div className='content-right'>
                    <a>All Futures <SliderRightArrowIcon /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='explore-heading'>

                  <h1>1000x Futures</h1>
                </div>
                <div className='explore-text'><p>Trade a diverse selection of coins with up to 1000x leverage.</p></div>
                <div className='explore-content'>
                  <div className='content-left'>
                    <EthiconIcon />
                    <RenderIconIcon />
                    <JupiterIconIcon />
                    <SeiIconIcon />
                  </div>
                  <div className='content-right'>
                    <a>All Futures <SliderRightArrowIcon /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default React.memo(ExploreSection)
