import { HI_LO_CARD_DECK, HI_LO_GAME_BET_TYPE } from 'constants/index'

const DEFAULT_PRECISION_MONEY = 5

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

/**
 *
 * @param {Number} num
 * @returns {Number} digits num
 */
export const truncateFloatNum = (num, upto) => {
  return num.toString().slice(0, (num.toString().indexOf('.')) + upto + 1)
}
/**
 *
 * @param {Object} gameSettings
 * @param {Number} probability
 * @returns {Number} Calculated Odds
 */
export const calculateOdds = (gameSettings, odds) => {
  return Math.max(gameSettings.minOdds, Math.min(gameSettings.maxOdds, odds * (1 - gameSettings.houseEdge / 100)))
}

/**
 *
 * @param {Object/Array} betStates
 * @param {Number} initialCard
 * @returns {Number} Calculated Odds
 */
export const getOdds = (betStates, initialCard) => {
  const same = 4 / 52
  const aboveOrBelow = (52 - 4) / 52

  const odds = betStates.reduce((odds, betState, index, betStates) => {
    let chance
    const previousCardNumber = index === betStates?.length - 1 ? initialCard : betStates[index + 1].openedCard
    const [previousCardRank] = HI_LO_CARD_DECK[previousCardNumber]

    if (betState.betType === HI_LO_GAME_BET_TYPE.SAME) {
      chance = same
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.ABOVE) {
      chance = aboveOrBelow
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.BELOW) {
      chance = aboveOrBelow
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.SAME_OR_ABOVE) {
      chance = 4 * ((13 - previousCardRank) + 1) / 52
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.SAME_OR_BELOW) {
      chance = previousCardRank * 4 / 52
    }

    odds *= (1 / chance)
    return odds
  }, 1.00)

  return odds || 1.00
}
export const calculateFirstOpMultiplier = (card, previousMultiplier = 1.00) => {
  const [cardRank] = HI_LO_CARD_DECK[card]
  let multiplier
  if (cardRank === 1) {
    multiplier = 1 / (4 / 52)
  } else if (cardRank === 13) {
    multiplier = 1 / (48 / 52)
  } else {
    multiplier = 1 / ((cardRank * 4) / 52)
  }
  return (multiplier * previousMultiplier)
}

export const calculateSecondOpMultiplier = (card, previousMultiplier = 1.00) => {
  const [cardRank] = HI_LO_CARD_DECK[card]
  let multiplier
  if (cardRank === 13) {
    multiplier = 1 / (4 / 52)
  } else if (cardRank === 1) {
    multiplier = 1 / (48 / 52)
  } else {
    multiplier = 1 / (((13 - cardRank + 1) * 4) / 52)
  }
  return (multiplier * previousMultiplier)
}

/**
 *
 * @param  {...Array} a Specify arrays to do cartesian product
 * @returns {Array.<Array.<number[]>>} Returns the cartesian product of given arrays
 */
export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

/**
 *
 * @returns {string} Returns a random generated hex string
 */
export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

/**
 *
 * @param {Number} n
 * @returns {Number} factorial of n
 */
export const factorial = n => {
  let fact = 1
  for (let i = 2; i <= n; i++) {
    fact *= i
  }
  return fact
}

/**
 *
 * @param {Number} n
 * @param {Number} r
 * @returns {Number} combination of n over r
 */
export const combination = (n, r) => {
  if (r > n) {
    return 0
  }
  return factorial(n) / (factorial(r) * factorial(n - r))
}

/**
 *
 * @param {Number} numberOfCoins
 * @param {Number} minimumChosenOutcome
 * @returns {Number} probability of minimum X number of favorable outcomes
 */
export const getCoinOutcomeProbability = (numberOfCoins, minimumChosenOutcome) => {
  const n = numberOfCoins - minimumChosenOutcome
  let sum = 0
  for (let i = 0; i <= n; i++) {
    sum += combination(numberOfCoins, i)
  }
  return sum / 2 ** numberOfCoins
}

/**
 * To calculate flip coin potential win
 * @param {number} betAmount
 * @param {number} numberOfCoins
 * @param {number} minimumChosenOutcome
 * @param {number} houseEdge
 * @returns {number}
 */
export const getFlipCoinPotentialWin = (betAmount, numberOfCoins, minimumChosenOutcome, houseEdge) => {
  const probability = getCoinOutcomeProbability(numberOfCoins, minimumChosenOutcome)
  const odds = (1 / probability) * (1 - houseEdge / 100)
  return parseInt((betAmount * odds) * 100) / 100
}

/**
 * @param {number} value
 * @param {number} precision
 * @returns {number | string} Returns precision value for specified decimal places
 */
export const getPrecision = (value, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(value * precisionDivide) / precisionDivide
  return result || 0
}

/**
 * To convert a numeric value to standard precision
 * @param {number} amount amount to be converted to precision
 * @param {number} precision digits to be considered for precision
 * @returns {number}
 */
export const getPrecisionNumber = (amount, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide || 0
  return result
}

export const countOnes = (s) => {
  let count = 0
  for (const i of s) {
    if (i === '1') {
      count++
    }
  }
  return count
}

export const getCardPoints = (cardList) => {
  let cardPoints = 0
  let acePoints = 0

  cardList.forEach(card => {
    if (card[0] === 1) {
      acePoints += 11
    } else {
      acePoints += card[0]
    }
    cardPoints += card[0]
  })

  if (acePoints > 21) {
    acePoints = cardPoints
  }

  return { acePoints, cardPoints }
}

export function delay (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Durstenfeld array shuffling algorithm
 * @param {number} array
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const OPS = {
  first: 'FIRST',
  second: 'SECOND'
}

export const PLACE_BET_STRINGS = {
  ABOVE: 'High',
  BELOW: 'Low',
  SAME: 'Same',
  SAME_OR_ABOVE: 'Higher or Same',
  SAME_OR_BELOW: 'Lower or Same'
}

export const PROBABILITY = {
  HIGHER_OR_SAME: 'higherOrSame',
  LOWER_OR_SAME: 'lowerOrSame'
}
