import { createSlice } from '@reduxjs/toolkit'
import { getFlipCoinBets, placeBetCoinFlip } from 'redux-thunk/thunk/flipCoinGame.thunk'

const initialState = {
  betHistoryData: [],
  placedBet: [],
  myBetsData: [],
  myBetsLoading: false,
  betInProgress: false
}

const {
  actions: { setPlacedBetsData },
  reducer
} = createSlice({
  name: 'flipCoinGame',
  initialState,
  reducers: {
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlipCoinBets.pending, (state, action) => {
        return {
          ...state,
          myBetsLoading: true
        }
      })
      .addCase(getFlipCoinBets.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload.rows,
          myBetsLoading: false
        }
      })
      .addCase(placeBetCoinFlip.pending, (state, action) => {
        return {
          ...state,
          placedBet: action.payload,
          betInProgress: true
        }
      })
      .addCase(placeBetCoinFlip.fulfilled, (state, action) => {
        return {
          ...state,
          placedBet: action.payload,
          betInProgress: false,
          myBetsData: [action.payload, ...state.myBetsData]
        }
      })
      .addCase(placeBetCoinFlip.rejected, (state, action) => {
        return {
          ...state,
          placedBet: action.payload,
          betInProgress: false
        }
      })
  }
})

export default reducer
export { setPlacedBetsData }
