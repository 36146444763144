import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cryptoCoin: {}
}

const {
  actions: {
    setCryptoCoin
  },
  reducer
} = createSlice({
  name: 'cryptoCoin',
  initialState: initialState,
  reducers: {
    setCryptoCoin: (state, action) => ({
      ...state,
      cryptoCoin: action.payload
    })
  }
})

export default reducer
export {
  setCryptoCoin
}
