import { openInfoToaster } from 'helpers/toaster.helpers'
import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import './nft.scss'
import { NftheadingIcon, RobotIconIcon } from 'components/ui-kit/Icons/svg/index'
import { nftOne, nftTwo, nftThree, nftFour, nftFive, nftSix } from 'components/ui-kit/Icons/png/index'

const NFT = () => {
  const { t } = useTranslation()
  const homeNftSettings = useMemo(() => ({
    initialSlide: 0,
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }), [])

  return (
    <section className='nft-section'>
      <div className='inner-heading'>
        {/* <img src='/assets/images/homePage/nft-heading.svg' alt='NFT' /> */}
        <NftheadingIcon />
        <h4 className='text-uppercase'>{t('nft')}</h4>
        <a
          href='' className='heading-link' onClick={(e) => {
            e.preventDefault()
            openInfoToaster({ message: 'Coming Soon!' })
          }}
        >{t('viewAll')}
        </a>
      </div>
      <div className='slide-wrap'>
        <Slider {...homeNftSettings}>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>#8289</h1>
                  <p>Sports Rollbots</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftOne} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $19.9</button>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>#8289</h1>
                  <p>Sports Rollbots</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftTwo} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $30</button>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>#8289</h1>
                  <p>Rollbots</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftThree} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $25</button>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>#8289</h1>
                  <p>Rollbots</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftFour} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $10.4k</button>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>CryptoPunk #4668</h1>
                  <p>CryptoPunks</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftFive} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $88.9</button>
              </div>
            </div>
          </div>
          <div className='col px-2'>
            <div className='theme-card-wrap'>
              <div className='theme-card'>
                <div className='nft-heading'>
                  <h1>CryptoPunk #4668</h1>
                  <p>CryptoPunks</p>
                </div>
                <div className='nft-img-wrap'>
                  {/* <div className='nft-badge'>25%</div> */}
                  <img src={nftSix} alt='NFT' />
                </div>
                <div className='nft-content-wrap d-flex align-items-center my-2 justify-content-between'>
                  <div className='nft-content-right d-flex align-items-center'>
                    <RobotIconIcon className='me-1' />
                    <span>Kurt Bates</span>
                  </div>
                  <p>10 Items</p>
                </div>
                <button className='btn btn-primary'>Open for $19.9</button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default React.memo(NFT)
