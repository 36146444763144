let currentPixiApp = null;
export let pixiApp;

const loadAssetsPixi = () =>
  import("./assetsLoad.js")
    .then(({ default: loadAssets }) => {
      return loadAssets()
    })
    .catch((error) => {
      console.error("Error during assets loading in Pixi:", error);
      throw error;
    });


const loadPixiApp = () =>
  import("./appPixi.js")
    .then(({ default: createPixiApp }) => {
      if (currentPixiApp) {
        currentPixiApp.destroyPixi(true);
      }

      pixiApp = createPixiApp();
      currentPixiApp = pixiApp;

      console.log("Pixi app loaded!")
      return pixiApp;
    })
    .catch((error) => {
      console.error("Error during Pixi.js initialization:", error);
      throw error;
    });

const loadGameUI = () =>
  import("./coinGameUI.js")
    .then(({ createCoinAppUI }) => {
      createCoinAppUI()
      console.log("Game UI created!")
    })
    .catch((error) => {
      console.error("Error loading gameUI.js:", error);
      throw error;
    });

let coinGameLogicModule;

export const loadCoinGameLogicFunctions = () => {
  if (!coinGameLogicModule) {
    return import('./coinGameLogic.js')
      .then((module) => {
        coinGameLogicModule = module;
        return coinGameLogicModule;
      })
      .catch((error) => {
        console.error('Error loading coinGameLogic.js:', error);
        throw error;
      });
  }

  return Promise.resolve(coinGameLogicModule);
};


const gameTickerStart = () =>
  loadCoinGameLogicFunctions(coinGameLogicModule)
    .then((coinGameLogicModule) => {
      coinGameLogicModule.startGameTicker()
      console.log("Game Ticker Started!")
    })
    .catch((error) => {
      console.error("Error loading gameLogic.js:", error);
      throw error;
    });


export const loadPixiAssets = () =>
  loadAssetsPixi()
    .then(() => console.log())
    .catch((error) => {
      console.error("Error loading pixi assets:", error);
    });

export const destroyPixiApp = () =>
  loadCoinGameLogicFunctions(coinGameLogicModule)
    .then((coinGameLogicModule) => {
      coinGameLogicModule.stopGameTicker()
      console.log("Game Ticker Stopped!")
    })
    .then(destroyPixiAppliaction)
    .catch((error) => {
      console.error("Error destroying pixi:", error);
    });

function destroyPixiAppliaction () {
  if (currentPixiApp) {
    currentPixiApp.destroyPixi(true);
    currentPixiApp = null;
    console.log("Pixi app destroyed")
  }
}

export const init = () =>
  loadAssetsPixi()
    .then(() => loadPixiApp())
    .then(() => currentPixiApp.resizePixiApp(700, 500))
    .then(() => loadGameUI())
    .then(() => gameTickerStart())
    .catch((error) => {
      console.error("Error during Pixi initialization:", error);
    });

let coinGameUIModule;

export const loadCoinGameUIFunctions = () => {
  if (!coinGameUIModule) {
    return import('./coinGameUI.js')
      .then((module) => {
        coinGameUIModule = module;
        return coinGameUIModule;
      })
      .catch((error) => {
        console.error('Error loading coinGameUI.js:', error);
        throw error;
      });
  }

  return Promise.resolve(coinGameUIModule);
};
