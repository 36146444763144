const config = {
  apiGateways: {
    // BASE_URL_1: 'https://user-api.clutch.app/api/v1'
    BASE_URL_1: `${process.env.REACT_APP_BACKEND_URL_HTTP}`
  },
  // SOCKET_URL: 'wss://user-api.clutch.app',
  SOCKET_URL: `${process.env.REACT_APP_BACKEND_URL_WS}`,
  META_MASK_SIGN_MESSAGE: `${process.env.REACT_APP_META_MASK_SIGN_MESSAGE}`,
  TWITCH_CLIENT_ID: `${process.env.REACT_APP_TWITCH_CLIENT_ID}`,
  GOOGLE_CLIENT_ID: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`
}

export default config
