import { METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstance from 'network/apis/index'

export const placeBetHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'hi-lo-game/place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const openCardHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'hi-lo-game/open-card', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const cashOutBetHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'hi-lo-game/cash-out-bet', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const myBetsHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'hi-lo-game/my-bets', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const getUnfinishedBetService = () => {
  return axiosInstance(METHOD_TYPES.get, 'hi-lo-game/unfinished-bet', {
    params: {},
    server: microServices.SERVICE_URL_1
  })
}
