import * as PIXI from 'pixi.js'
import Matter from 'matter-js'
import { assets, containers, linesNum, matterAssets } from './gameUI.js'
// import { app } from "./appPixi.js";
import { pixiApp } from './initializePixi.js'
import { ballAndPointsNewRadius, boxNewWidth, fontSize, pointsPerRow, splitGradientTexture } from './utility.js'
import { settings } from '../settings.js'
// import { startGameTicker, stopGameTicker } from './gameLogic.js'
// import { lte } from 'lodash'
// import { setTickerRun } from './gameLogic.js'

// const pointRadiusInitial = 20
const pointsContainerWidthOffset = 1
export let gapX = 0
export const createPointsAndBoxes = () => {
  const lineNum = linesNum.get()
  const gapY = pixiApp.app.screen.height / (lineNum + 1 + pointsContainerWidthOffset) - 10
  gapX = pixiApp.app.screen.width / (pointsPerRow[lineNum - 1]) + 4 // gapY * 0.96
  const pointRadius = ballAndPointsNewRadius(lineNum)
  for (let row = 0; row < lineNum; row++) {
    const startX = (pixiApp.app.screen.width - (row * gapX) - gapX * 2) / 2
    const rowArray = []
    for (let j = 0; j < pointsPerRow[row]; j++) {
      const point = createAPoint({ x: startX + (j * gapX), y: gapY * (row + 1 + pointsContainerWidthOffset), radius: pointRadius, pointRow: row, pointNumber: j })
      point.destroy = function () {
        const index = rowArray.indexOf(this)
        if (index !== -1) {
          rowArray.splice(index, 1)
          pixiApp.app.stage.removeChild(this.pixiBody)
          this.pixiBody.destroy()
          this.pixiBody = null
          Matter.World.remove(matterAssets.world, this.matterBody)
          this.matterBody = null
          delete this
        }
      }

      rowArray.push(point)
    }

    if (row === lineNum - 1) { createBoxes(startX, gapX, 1800, lineNum) }

    assets.points.push(rowArray)
  }
}

function createBoxes (startX, gapX, y, lineNum) {
  const gradientSprites = splitGradientTexture(lineNum + 1)
  //const gradientSprites = new PIXI.Sprite(PIXI.Assets.get('box'))
  const boxWidth = boxNewWidth(lineNum)
  for (let i = 0; i < lineNum + 1; i++) {
    // const box = createABox(startX + (gapX * 0.5) + (gapX * i), y, boxWidth, settings.box.height, i, gradientSprites[i], lineNum)
    const box = createABox({ x: startX + (gapX * 0.5) + (gapX * i), y, width: boxWidth, height: settings.box.height, boxIndex: i, boxSprite: gradientSprites[i], lineNum })

    box.destroy = function () {
      const index = assets.boxes.indexOf(this)
      if (index !== -1) {
        assets.boxes.splice(index, 1)
        containers.pointsContainer.removeChild(this.boxPixi, this.boxText, this.boxContainer)
        this.boxPixi.destroy()
        this.boxPixi = null
        this.boxText.destroy()
        this.boxText = null
        this.boxContainer.destroy()
        this.boxContainer = null
        Matter.World.remove(matterAssets.world, this.boxMatter)
        this.boxMatter = null
        delete this
      }
    }

    assets.boxes.push(box)
  }
  changePayoutText()
}

function createABox ({ x, y, width, height, boxIndex, boxSprite, lineNum }) {
  const boxMatter = Matter.Bodies.rectangle(x, y, width, height, {
    isStatic: true,
    collisionFilter: {
      category: 0x0003,
      mask: 0x0002
    },
    label: 'Box',
    boxIndex: boxIndex
  })

  Matter.World.add(matterAssets.world, boxMatter)

  const boxContainer = new PIXI.Container()
  containers.pointsContainer.addChild(boxContainer)
  boxContainer.x = x - width
  boxContainer.y = y - height / 2
  boxContainer.startPos = boxContainer.y

  boxContainer.doNothing = () => { }
  boxContainer.boxState = boxContainer.doNothing

  boxContainer.boxBounceEffect = (delta) => {
    if (boxContainer.y > boxContainer.startPos) {
      boxContainer.y -= delta * settings.box.boxSpeed
    } else {
      boxContainer.y = boxContainer.startPos
      boxContainer.boxState = boxContainer.doNothing
    }
  }

 // const boxVisual = ['box0', 'box1', 'box2', 'box3']
  let boxColor = 'box0'
  if (boxIndex === 0 || boxIndex === lineNum)
    boxColor = 'box2'
  else if (boxIndex / lineNum >= 0.3 && boxIndex / lineNum <= 0.7)
    boxColor = 'box0'
  else
    boxColor = 'box3'
// console.log(lineNum , "lines")
  const boxGraphics = new PIXI.Sprite(PIXI.Assets.get(boxColor))
  boxGraphics.scale.set(1.7 -(lineNum-8)/10)
  // const boxGraphics = new PIXI.Sprite(PIXI.Assets.get('box0'))
  // console.log(boxGraphics,)
  // boxGraphics.width = width * 2
  // boxGraphics.height = height

  // const maskGraphics = new PIXI.Graphics()
  // maskGraphics.beginFill(0xffffff)
  // maskGraphics.drawRoundedRect(0, 0, boxSprite.width, boxSprite.height, 8)
  // maskGraphics.endFill()

  //boxGraphics.mask = maskGraphics

  boxContainer.addChild(boxGraphics)


  const multiplierText = new PIXI.Text(
    '0.0x',
    new PIXI.TextStyle({
      fill: ['#ffffffff'],
      fontSize: fontSize(lineNum),
      fontWeight: '900'
      // align: 'center'
    })
  )

  multiplierText.x = boxGraphics.width / 2
  multiplierText.y = boxGraphics.height / 2
  multiplierText.scale.set(0.7, 1)
  multiplierText.anchor.set(0.5)
  boxContainer.addChild(multiplierText)

  return { boxMatter: boxMatter, boxContainer: boxContainer, boxPixi: boxGraphics, boxText: multiplierText }
}

let _payout = []
export const payoutText = {
  get: () => _payout,
  set: (newPayout) => { _payout = newPayout }
}

function changePayoutText () {
  const payout = payoutText.get()
  for (let i = 0; i < payout?.length; i++) {
    if (payout[i] || payout[i] === 0) {
      if (assets.boxes[i]) {
        const multiplierText = assets.boxes[i].boxText
        multiplierText.text = payout[i] + 'x'
      }
    } else console.error('Sent payout is not a valid number')
  }
}

export function setupPayoutText (payout) {
  return new Promise((resolve, reject) => {
    payoutText.set(payout)
    changePayoutText()
    resolve()
  })
}

export function changeLinesNumber (lineNum, payouts) {
  destroyAllPoints()
    .then(() => destroyAllBoxes())
    .then(() => {
      linesNum.set(lineNum)
      setupPayoutText(payouts)
    })
    .then(() => { createPointsAndBoxes() })
    .catch()
}

function createAPoint ({ x, y, radius, pointRow, pointNumber }) {
  return {
    matterBody: pointMatter(x, y, radius, pointRow, pointNumber),
    pixiBody: pointPixi(x, y, radius)
  }
}

function pointMatter (x, y, radius, pointRow, pointNumber) {
  const point = Matter.Bodies.circle(x, y, radius, {
    isStatic: true,
    density: 1, // 10000
    collisionFilter: {
      category: 0x0001,
      mask: 0x0002
    },
    label: 'Point',
    pointRow: pointRow,
    pointNumber: pointNumber
  })
  Matter.World.add(matterAssets.world, point)
  return point
}

function pointPixi (x, y, radius) {
  const point = new PIXI.Sprite(PIXI.Assets.get('point'))
  point.anchor.set(0.5)
  point.x = x
  point.y = y
  point.width = radius * 2
  point.height = radius * 2
  // point.scale.set(0.4)

  point.shine = new PIXI.AnimatedSprite(
    PIXI.Assets.get('shineEffect').animations.frames
  )
  point.shine.scale.set(9) // 1
  point.shine.alpha = 0.3 // 0.55
  point.shine.animationSpeed = settings.points.shine.animationSpeed // 0.7
  point.shine.visible = false
  point.startShine = () => {
    point.shine.play()
    point.shine.visible = true
  }
  point.shine.onLoop = () => {
    point.shine.gotoAndStop(1)
    point.shine.visible = false
  }

  point.shine.anchor.set(0.5)
  point.addChild(point.shine)

  containers.pointsContainer.addChild(point)

  return point
}

export const destroyAllPoints = () => {
  return new Promise((resolve, reject) => {
    // stopGameTicker()
    for (let pointArray = assets.points.length - 1; pointArray >= 0; pointArray--) {
      for (let point = assets.points[pointArray].length - 1; point >= 0; point--) {
        assets.points[pointArray][point].destroy()
      }
    }
    assets.points = []
    // startGameTicker()
    resolve()
  })
}

export const destroyAllBoxes = () => {
  return new Promise((resolve, reject) => {
    // stopGameTicker()
    let i = assets.boxes.length - 1
    while (i >= 0) {
      const box = assets.boxes[i]
      box.destroy()
      i--
    }
    assets.boxes = []
    // startGameTicker()
    resolve()
  })
}
