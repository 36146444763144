import { createSlice } from '@reduxjs/toolkit'
import { CHAT_GROUP_ID, LANGUAGE_ROOM_ID } from 'constants/index'
import { getItem, setItem } from 'helpers/localstorage.helpers'
import { getChatGroups, getChatRules, getChats, getLanguageRooms, getUserDetails } from 'redux-thunk/thunk/chat.thunk'

const initialState = {
  userData: null,
  showTipInChat: true,
  chatRules: [],
  userToReport: null,
  chat: null,
  languageRooms: null,
  selectedRoomId: getItem(LANGUAGE_ROOM_ID) || 1,
  chatGroups: null,
  selectedChatGroupId: getItem(CHAT_GROUP_ID) || 1
}

const {
  actions: {
    setSpecificUserData,
    setShowTipInChat,
    setUserToReport,
    setChat,
    setSelectedRoomId,
    setSelectedChatGroupId,
    updateChat
  },
  reducer
} = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateChat: (state, action) => {
      return {
        ...state,
        chat: {
          ...state.chat,
          rows: action.payload
        }
      }
    },
    setSelectedChatGroupId: (state, action) => {
      setItem(CHAT_GROUP_ID, action.payload)
      return {
        ...state,
        selectedChatGroupId: action.payload
      }
    },
    setSelectedRoomId: (state, action) => {
      setItem(LANGUAGE_ROOM_ID, action.payload)
      return {
        ...state,
        selectedRoomId: action.payload
      }
    },
    setChat: (state, action) => {
      return {
        ...state,
        chat: {
          count: state.chat.count + 1,
          rows: [...state.chat.rows, action.payload]
        }
      }
    },
    setSpecificUserData: (state, action) => ({
      ...state,
      userData: action.payload
    }),
    setShowTipInChat: (state, action) => ({
      ...state,
      showTipInChat: action.payload
    }),
    setUserToReport: (state, action) => ({
      ...state,
      userToReport: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        return {
          ...state,
          userData: action.payload
        }
      })
      .addCase(getChatRules.fulfilled, (state, action) => {
        return {
          ...state,
          chatRules: action.payload?.rules
        }
      })
      .addCase(getChats.fulfilled, (state, action) => {
        return {
          ...state,
          chat: { ...action.payload, rows: action.payload.rows.reverse() }
        }
      })
      .addCase(getLanguageRooms.fulfilled, (state, action) => {
        return {
          ...state,
          languageRooms: action.payload
        }
      })
      .addCase(getChatGroups.fulfilled, (state, action) => {
        return {
          ...state,
          chatGroups: action.payload
        }
      })
  }

})

export default reducer
export {
  setSpecificUserData,
  setShowTipInChat,
  setUserToReport,
  setChat,
  setSelectedRoomId,
  setSelectedChatGroupId,
  updateChat
}
