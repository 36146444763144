import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setInviteFriendsStatus, setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'
import CashierDeposit from './components/Deposit/index'
import CashierWithdraw from './components/Withdraw/index'
import CashierCoupons from './components/Coupons/index'
import CashierReferrals from './components/Referrals/index'
import CommonPopup from 'components/ui-kit/CommonPopup/index'

const CashierPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { inviteFriendsStatus } = useSelector(state => state.settings)

  const modalCloseHandler = () => {
    dispatch(setShowCashierPopup(false))
  }

  const cashierTabs = useMemo(() => ([
    {
      id: 'pills-cashier-deposit',
      label: t('deposit')
    },
    {
      id: 'pills-cashier-withdraw',
      label: t('withdraw')
    },
    {
      id: 'pills-cashier-coupons',
      label: t('coupons')
    },
    {
      id: 'pills-cashier-referrals',
      label: t('referrals')
    }
  ]), [])

  useEffect(() => {
    return () => {
      if (inviteFriendsStatus) {
        dispatch(setInviteFriendsStatus(false))
      }
    }
  }, [])

  return (
    <CommonPopup
      classes='cashier-modal'
      modalCloseHandler={modalCloseHandler}
      id='cashierModal'
    >
      <div className='custom-tabs-nav'>
        <ul
          className='nav nav-pills p-0 mb-4'
          id='pills-tab'
          role='tablist'
        >
          {cashierTabs?.map((tab, idx) => (
            <li
              key={tab.id}
              className='nav-item'
              role='presentation'
            >
              <button
                className={`nav-link text-uppercase ${
                  ((idx === 0) && !inviteFriendsStatus)
                 ? 'active'
                 : ((tab.id === 'pills-cashier-referrals') && inviteFriendsStatus)
                  ? 'active'
                  : ''}`}
                id={`${tab.id}-tab`}
                data-bs-toggle='pill'
                data-bs-target={`#${tab.id}`}
                type='button'
                role='tab'
                aria-controls={`${tab.id}`}
                aria-selected={idx === 0 ? 'true' : 'false'}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className='tab-content' id='pills-tabContent'>
        <CashierDeposit />
        <CashierWithdraw />
        <CashierCoupons />
        <CashierReferrals />
      </div>
    </CommonPopup>
  )
}

export default CashierPopup
