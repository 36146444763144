import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import routes from './routesList'
import RouteValidator from './RouteValidator'
import NotFoundComponent from 'components/layout/404/index'
import SideNavBar from 'components/layout/SideNavBar/index'
import Header from 'components/layout/Header/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import MainLoader from 'components/ui-kit/Loader/MainLoader/index'
import Loader from 'components/ui-kit/Loader/index'

const CustomRoutes = () => {
  const { [LOADER_HANDLER_TYPES.page]: loading } = useSelector(
    (state) => state.loader
  )
  return (
    <>
      <SideNavBar />
      <Header />
      {loading && <MainLoader />}
      <Suspense fallback={<Loader />}>
        <Switch>
          {
            routes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                exact
                render={() => <RouteValidator route={route} />}
              />
            ))
          }
          <Route component={() => <NotFoundComponent />} />
        </Switch>
      </Suspense>
    </>
  )
}

export default CustomRoutes
