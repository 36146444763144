import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import WithdrawCurrencySection from './WithdrawCurrencySection'

const CashierWithdraw = () => {
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.user)
  const [selectedWithdrawWallet, setSelectedWithdrawWallet] = useState(null)

  return (
    <div
      className='tab-pane fade'
      id='pills-cashier-withdraw'
      role='tabpanel'
      aria-labelledby='pills-cashier-withdraw-tab'
      tabIndex='0'
    >
      {selectedWithdrawWallet
        ? (
          <WithdrawCurrencySection
            selectedWithdrawWallet={selectedWithdrawWallet}
            setSelectedWithdrawWallet={setSelectedWithdrawWallet}
          />
          )
        : (
          <div className='deposit-step-one'>
            <div className='cashier-heading'>
              <h3 className='text-uppercase'>{t('withdrawalOptions')}</h3>
            </div>
            <div className='deposit-card-wrap'>
              <div className='row'>
                {user?.wallets
                  ?.filter((wallet) => !wallet.currency.isFiat)
                  ?.map((wallet) => (
                    <div
                      key={wallet.currencyId}
                      className='col-12  col-md-6 col-lg-3 mb-3 px-1'
                    >
                      <div
                        className='deposit-card'
                        onClick={() => {
                          setSelectedWithdrawWallet(wallet)
                        }}
                      >
                        <div className='deposit-card-icon'>
                          <img src={wallet.currency.image} alt='Coin' />
                        </div>
                        <h4>{wallet.currency.name}</h4>
                        <p>{wallet.currency.code}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          )}
    </div>
  )
}

export default CashierWithdraw
