import React, { useRef } from 'react'
import RightSidebarHeader from '../RightSidebarHeader/index'
import ChatFooter from './components/ChatFooter/index'
import ChatContent from './components/ChatContent/index'
import './chat.scss'

const Chat = () => {
  const bottomRef = useRef(null)

  return (
    <>
      <RightSidebarHeader />
      <ChatContent bottomRef={bottomRef} />
      <ChatFooter bottomRef={bottomRef} />
    </>
  )
}

export default React.memo(Chat)
