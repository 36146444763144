import { createAsyncThunk } from '@reduxjs/toolkit'
import { ANNOUNCEMENTS } from 'constants/index'
import { getItem } from 'helpers/localstorage.helpers'
import { getAnnouncementsService } from 'network/services/announcement.service'

export const getAnnouncements = createAsyncThunk(
  'fetch/announcements',
  async (params, thunkApi) => {
    try {
      const res = await getAnnouncementsService(params)
      const announcementData = getItem(ANNOUNCEMENTS)
      const seenAnnouncements = announcementData?.rows?.filter(element => element?.seen)?.map(el => el.id)
      if (seenAnnouncements?.length > 0) {
        const updatedResponse = {
          ...res,
          rows: res?.rows?.map(element => {
            if (seenAnnouncements?.includes(element.id)) {
              return {
                ...element,
                seen: true
              }
            }
            return element
          })
        }
        return updatedResponse
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
