import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { SuccessMessage } from 'network/messages/successMessages'

export const sendChatMessageService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/chat', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getUserDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/rank-details', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.userDetails,
    params: data
  })
}

export const tipUserService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/live-chat/send-tip', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.tipSendSuccessfully
  })
}

export const getChatRulesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/system/chat-rules', {}, {
    server: microServices.USER_URL
  })
}

export const reportUserService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/reported-user/create', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.userReportedSuccessfully
  })
}

export const getChatsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/user/get-chat', {}, {
    server: microServices.USER_URL,
    params,
    loader: LOADER_HANDLER_TYPES.chat
  })
}

export const getLanguageRoomsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/live-chat/get-language-rooms', {}, {
    server: microServices.USER_URL,
    params,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getChatGroupsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/live-chat/get-chat-group', {}, {
    server: microServices.USER_URL,
    params
  })
}
