import { createAsyncThunk } from '@reduxjs/toolkit'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { getMyBetsService, placeBetCoinFlipGameService } from 'network/services/flipCoin.service'
import { startFlip } from 'pixi-js-scripts/flip-coin-game/bridge'
import { setDemoWallet } from 'redux-thunk/redux/slices/gameSetting.slice'

export const placeBetCoinFlip = createAsyncThunk(
  'coin-flip/place-bet-flip-coin-game',
  async (data, thunkApi) => {
    try {
      const res = await placeBetCoinFlipGameService(data)
      data?.onSuccess()
      openSuccessToaster({
        message: 'Bet Placed successfully'
      })
      startFlip()
      if (data?.isDemoGame) {
        thunkApi.dispatch(setDemoWallet(res?.demoAmount))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getFlipCoinBets = createAsyncThunk(
  'coin-flip/myBets',
  async (data, thunkApi) => {
    try {
      const res = await getMyBetsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
