import React from 'react'
import { useTranslation } from 'react-i18next'

const NotificationHeader = () => {
  const { t } = useTranslation()

  return (
    <div className='d-flex justify-content-center notification-header'>
      <span>{t('notifications')}</span>
    </div>
  )
}

export default React.memo(NotificationHeader)
