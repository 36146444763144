import { createSlice } from '@reduxjs/toolkit'
import { getUserCasinoTransactions, getUserCustomGameTransactions, getUserDepositTransactions, getUserSportsbookTransactions, getUserWithdrawDepositTransactions, getUsersCryptoTransaction, getUsersTotalBets } from 'redux-thunk/thunk/user.thunk'
// getUserCryptoCoinTransactions
const initialState = {
  withdrawTransactions: null,
  depositTransactions: null,
  usersCasinoTransactions: null,
  usersCustomGameTransactions: null,
  usersSportsbookTransactions: null,
  usersTotalBetAmounts: null,
  usersCryptoTransactions: null,
  usersCryptoCoinTransactions: null
}

const {
  actions: {
    getUserTransactionsInit
  },
  reducer
} = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
    getUserTransactionsInit: (state, action) => ({
      ...state,
      usersCustomGameTransactions: null
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          depositTransactions: action.payload
        }
      })
      .addCase(getUserWithdrawDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawTransactions: action.payload
        }
      })
      .addCase(getUserCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCasinoTransactions: action.payload
        }
      })
      .addCase(getUserCustomGameTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCustomGameTransactions: action.payload
        }
      })
      .addCase(getUserSportsbookTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersSportsbookTransactions: action.payload
        }
      })
      .addCase(getUsersTotalBets.fulfilled, (state, action) => {
        return {
          ...state,
          usersTotalBetAmounts: action.payload
        }
      })
      .addCase(getUsersCryptoTransaction.fulfilled, (state, action) => {
        return {
          ...state,
          usersCryptoTransactions: action.payload
        }
      })
      // .addCase(getUserCryptoCoinTransactions.fulfilled, (state, action) => {
      //   return {
      //     ...state,
      //     usersCryptoCoinTransactions: action.payload
      //   }
      // })
  }
})

export default reducer
export { getUserTransactionsInit }
