import React, { useCallback, useEffect, useState } from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { cryptoIcon } from 'utils/helperFunctions.utils'
import CryptoPrice from '../../../../components/layout/SideNavBar/components/CryptoPrice/index'
import useCryptoFutureNameSpace from 'socket-resources/hooks/useCryptoFutureNameSpace'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'

const CryptoFutures = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cryptoCoinsList } = useSelector((state) => state.crypto)
  const { listenCryptoFuturePriceEvent } = useCryptoFutureNameSpace()
  const coinPercentageObj = {}

  for (let i = 0; i <= cryptoCoinsList?.length; i++) {
    coinPercentageObj[cryptoCoinsList[i]?.id] = [{ currentPrice: 0, basePrice: 0 }]
  }

  const [basePrice, setBasePrice] = useState(coinPercentageObj)

  const handleCryptoPriceUpdate = (data) => {
    setBasePrice((prevState) => {
      return {
        ...prevState,
        [data.data.ticker.cryptoFuturesInstrumentId]: [
          { currentPrice: +data.data.ticker.price || 0, basePrice: +data?.data?.basePrice || 0 }
        ]
      }
    })
  }

  useEffect(() => {
    const cleanerCryptoEvent = listenCryptoFuturePriceEvent(handleCryptoPriceUpdate)
    return () => {
      cleanerCryptoEvent()
    }
  }, [])

  const calculatePercentageChange = useCallback((higherValue, lowerValue) => {
    const percentageChange = (((higherValue - lowerValue) * 100) / higherValue)?.toFixed(2)
    return isNaN(percentageChange) ? '0.00' : percentageChange
  }, [])

  const homeCryptoSettings = {
    initialSlide: 0,
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    autoplay: false
  }

  return (
    <section className='crypto-section'>
      <div className='inner-heading'>
        <img src='/assets/images/homePage/crypto-heding.svg' alt='Crypto' />
        <h4 className='text-uppercase'>{t('cryptoFutures')}</h4>
        <Link
          to={ROUTE_PATHS.ROLLERCOASTER}
          className='heading-link'
        >
          {t('tradeNow')}
        </Link>
      </div>
      <div className='slide-wrap'>
        <Slider {...homeCryptoSettings}>

          {cryptoCoinsList?.map((item, i) => {
            return (
              <div key={i} className='col px-06'>
                <div className='theme-card-wrap'>
                  <Link
                    to={`${ROUTE_PATHS.CRYPTO}/${item?.id}`}
                    onClick={(e) => {
                      dispatch(setCryptoCoin({ name: item?.name, id: item?.id }))
                    }}
                  >
                    <div className='theme-card p-0'>
                      <div className='trade-header d-flex  justify-content-between  py-2'>
                        <div className='trade-heder-left d-flex align-items-center'>
                          {/* <img
                      src='/assets/images/homePage/clutch.svg'
                      className='tarde-logo'
                      alt='Clutch'
                    /> */}
                          <img src={cryptoIcon(item?.id)} alt={item?.name} className='tarde-logo' />
                          <div className='trade-heder-details'>
                            <h4 className='text-uppercase'>{item.name}</h4>
                            <p>{item.symbol}</p>
                          </div>
                        </div>
                        {/* <NavLink to='#' className='Info-icon'>
                        <img
                          src='/assets/images/homePage/info-icon.svg'
                          alt='Info'
                        />
                      </NavLink> */}
                      </div>
                      <div className='trade-card-content'>
                        <CryptoPrice changeShow={false} id={item?.id} />
                      </div>
                      <div>
                        <p className={`${basePrice[item?.id]?.[0]?.currentPrice < basePrice[item?.id]?.[0]?.basePrice ? 'loss-change' : 'profit-change'}`}>
                          {
                          basePrice[item?.id]?.[0]?.currentPrice < basePrice[item?.id]?.[0]?.basePrice
                            ? `-${calculatePercentageChange(basePrice[item?.id]?.[0]?.basePrice, basePrice[item?.id]?.[0]?.currentPrice)}%`
                            : `+${calculatePercentageChange(basePrice[item?.id]?.[0]?.currentPrice, basePrice[item?.id]?.[0]?.basePrice)}%`
                        }
                        </p>
                      </div>
                      <div className='tarde-graph-wrap'>
                        <img
                          src={`/assets/images/homePage/${item.symbol.toLowerCase()}-graph.svg`}
                          alt='Graph'
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default React.memo(CryptoFutures)
