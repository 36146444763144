import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllBonusService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/list', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const activateWageringService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/activate-wagering', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus activated successfully'
  })
}

export const forfeitBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/forfeit-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus forfeit successfully'
  })
}

export const getUserBonusService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/list-user-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const claimReferralBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/claim-referral-bonus', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getReferralBonusService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/user/get-referral-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const claimBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/claim-user-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getTotalRewardsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/all-rewards-detail', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getAllRanksDetailsOfUserService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/all-rank-detail', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getSplittedBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/get-split-bonus', data, {
    server: microServices.SERVICE_URL_1
  })
}
