import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDemoGameIframeUrlService, getGameIframeUrlService, getHighRollerService, getLiveTransactionsService } from 'network/services/casino.service'
import { getGamesListService } from 'network/services/sportsBook.service'

export const getGameIframe = createAsyncThunk(
  'game/get-iframe-url',
  async (data, thunkApi) => {
    try {
      const res = await getGameIframeUrlService(data)
      return res?.responseObject?.data
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getDemoGameIframe = createAsyncThunk(
  'game/get-demo-iframe-url',
  async (data, thunkApi) => {
    try {
      const res = await getDemoGameIframeUrlService(data)
      return res?.responseObject?.data
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getLiveTransactions = createAsyncThunk(
  'casino/get-live-transactions/month/week/days',
  async (data, thunkApi) => {
    try {
      const res = await getLiveTransactionsService(data)
      return res?.winners
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

// for search popup
export const getGames = createAsyncThunk(
  'fetch/search-popup-games',
  async ({ data }, thunkApi) => {
    try {
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { viewAllGames } = thunkApi?.getState()?.searchPopup
        res = { count: res?.count, rows: [...viewAllGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getHighRollers = createAsyncThunk(
  'casino/get-high-rollers',
  async (data, thunkApi) => {
    try {
      const res = await getHighRollerService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
