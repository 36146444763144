import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSumsubTokenService } from 'network/services/sumsub.service'

export const getSumsubToken = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getSumsubTokenService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
