import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { startCase } from 'lodash'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'
import { SearchIconIcon } from 'components/ui-kit/Icons/svg/index'
import { capitalizeWords } from 'utils/helperFunctions.utils'

const ProvidersSearchBar = (props) => {
  const { setName, setProvider, provider, showProvidersDropdown } = props
  const { t } = useTranslation()
  const { providers } = useSelector((state) => state.games)
  const [selectedProviderName, setSelectedProviderName] = useState('All')
  const [searchVal, setSearchVal] = useState('')
  const [inputProvider, setInputProvider] = useState('')
  const [filteredProviders, setFilteredProviders] = useState(providers)

  const handleSearchGame = (e) => {
    setSearchVal(e?.target?.value)
  }

  const handleSearchProvider = (e) => {
    setInputProvider(e?.target?.value)
  }

  const closeDropdown = useCallback(() => {
    const element = document?.getElementById('providers_dropdown')
    const button = document?.getElementById('providers_dropdown_button')
    button?.setAttribute('aria-expanded', 'false')
    element?.classList.remove('show')
    button?.classList.remove('show')
    setInputProvider('')
  }, [])

  useDebounce(
    () => {
      setName(searchVal)
    },
    1000,
    [searchVal]
  )

  useEffect(() => {
    if (inputProvider === '') {
      setFilteredProviders(providers)
    } else {
      setFilteredProviders({
        rows: providers.rows.filter((item) => {
          if (
            item?.name?.toLowerCase()?.includes(inputProvider.toLowerCase())
          ) {
            return item
          }
          return false
        })
      })
    }
  }, [inputProvider])

  useEffect(() => {
    setFilteredProviders(providers)
  }, [providers])

  return (
    <section className='casino-search d-md-flex align-items-center justify-content-between mb-3'>
      <div className='casino-search-content w-100 me-2 mb-2 mb-md-0'>
        <input
          type='text'
          className='form-control shadow-none'
          value={searchVal}
          id='casinoSearch'
          placeholder={t('searchGames')}
          onChange={(e) => {
            handleSearchGame(e)
          }}
        />
        <SearchIconIcon className='search-icon' />
      </div>
      {showProvidersDropdown && (
        <div className='search-filter-right d-flex align-items-center'>
          <div className='me-2 '>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='providers_dropdown_button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {t('provider')}: <span>{startCase(selectedProviderName)}</span>
            </button>
            <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='providers_dropdown'
              id='providers_dropdown'
            >
              <section className='casino-search'>
                <div className='casino-search-content'>
                  <input
                    type='text'
                    className='form-control shadow-none'
                    id='providerSearch'
                    placeholder={`${t('search')}...`}
                    value={inputProvider}
                    onChange={(e) => handleSearchProvider(e)}
                  />
                  <SearchIconIcon className='search-icon' />
                </div>
              </section>
              <div className='provider-list-wrap'>
                <li>
                  <a
                    className={`dropdown-item ${
                      provider === 'All' ? 'active' : ''
                    }`}
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      setProvider('')
                      setSelectedProviderName('All')
                      closeDropdown()
                    }}
                  >
                    {t('all')}
                  </a>
                </li>
                {filteredProviders?.rows?.map((item) => (
                  <li key={item?.providerId}>
                    <a
                      className={`dropdown-item ${
                        provider === item?.providerId ? 'active' : ''
                      }`}
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        setProvider(item?.providerId)
                        setSelectedProviderName(item?.name)
                        closeDropdown()
                      }}
                    >
                      {capitalizeWords(item?.name)}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div className='dropdown d-none'>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {t('sortBy')}: <span>{t('popular')}</span>
            </button>
            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li>
                <a className='dropdown-item' href='#'>
                  Action
                </a>
              </li>
              <li>
                <a className='dropdown-item' href='#'>
                  Another action
                </a>
              </li>
              <li>
                <a className='dropdown-item' href='#'>
                  Something else here
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </section>
  )
}

export default React.memo(ProvidersSearchBar)
