import React from 'react'
import RightSidebarHeader from '../RightSidebarHeader/index'
import NotificationContent from './components/NotificationContent/index'
import NotificationHeader from './components/NotificationHeader/index'
import './notifications.scss'

const Notifications = () => {
  return (
    <>
      <RightSidebarHeader />
      <NotificationHeader />
      <NotificationContent />
    </>
  )
}

export default React.memo(Notifications)
