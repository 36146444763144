import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// import { FavouritesWIcon } from 'components/ui-kit/Icons/svg/index'
import { crash, plinko } from 'components/ui-kit/Icons/png/index'
import ContentHeader from 'components/ui-kit/ContentHeader/index'
import { OriginalsIcon } from 'components/ui-kit/Icons/svg/index'
import { ROUTE_PATHS } from 'constants/index'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'

const OriginalsGames = (props) => {
  const { title, gameId } = props
  const { t } = useTranslation()
  const { loginData } = useSelector(state => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  const originalGames = useMemo(() => [
    // {
    //   id: '3',
    //   img: mine,
    //   path: ROUTE_PATHS.MINE_GAME
    // },
    // {
    //   id: '2',
    //   img: hilo,
    //   path: ROUTE_PATHS.HILO_GAME
    // },
    // {
    //   id: '4',
    //   img: coinFlip,
    //   path: ROUTE_PATHS.FLIP_COIN_GAME
    // },
    {
      id: '1',
      img: crash,
      path: ROUTE_PATHS.CRASH_GAME
    },
    {
      id: '5',
      img: plinko,
      path: ROUTE_PATHS.PLINKO_GAME
    }
  ], [])

  const handleRedirectToGame = useCallback((path) => {
    if (loginData?.accessToken) {
      history.push(path)
    } else {
      dispatch(setShowLoginPopup(true))
    }
  }, [loginData])

  const homeCasinoSettings = useMemo(
    () => ({
      initialSlide: 0,
      dots: false,
      infinite: false,
      arrows: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 7,
      slidesToScroll: 7,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    }),
    []
  )

  return (
    <section className='golabl-casino-section'>
      <ContentHeader
        title={title ? t(`${title}`) : t('wofeOriginals')}
        subtitle={t('allGames')}
        Icon={OriginalsIcon}
        subtitleOnClickHandler={() => {}}
      />
      <div className='slide-wrap p-0 m-0'>
        <Slider {...homeCasinoSettings}>
          {originalGames.filter(game => game.id !== gameId)?.map(game => (
            <div
              key={game.id}
              className='col px-06'
            >
              <div className='theme-card-wrap'>
                <div
                  className='theme-card'
                  onClick={() => handleRedirectToGame(game.path)}
                >
                  <div className='casino-img-wrap'>
                    <img src={game.img} alt={game.id} />
                    {/* <div className='card-overlay'>
                    <h4>Challenge Pool</h4>
                    <p>$1,130.33</p>
                    <a
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        openInfoToaster({ message: 'Coming Soon!' })
                      }}
                    >
                      <FavouritesWIcon />
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default React.memo(OriginalsGames)
