import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllCurrencyService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-currency', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-game-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getAllSiteinfoService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-all-cms', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getGameThemeSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-all-game-theme-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getEncourageWordsSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/list-uplifting-words', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const setUpdateAffilateCountService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/system/increase-affiliateCount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getBannerService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-banners', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getSponserService = () => {
  return axiosInstance(METHOD_TYPES.get, '/sponsor/list', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}
