import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'

export const usePreLogin = ({ path } = {}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loginData } = useSelector((state) => state.auth)

  const handleRegister = useCallback(({ showLoginPopup }) => {
    if (!loginData?.accessToken) {
      if (showLoginPopup) {
        dispatch(setShowLoginPopup(true))
      } else {
        dispatch(setShowSignupPopup(true))
      }
    }
  }, [loginData?.accessToken])

  useEffect(() => {
    if (loginData?.accessToken && path) {
      history.push(path)
    }
  }, [loginData])

  return { handleRegister }
}
