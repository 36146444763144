export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'profileMessagesChangePassword',
  playerStatusUpdate: 'updatePlayerStatusSuccess',
  createOperator: 'operatorUserCreatedSuccess',
  resetPassword: 'passwordResetSuccess',
  operatorDelete: 'operatorDeletedSuccess',
  operatorStatusUpdate: 'operatorStatusUpdateSuccess',
  signup: 'signup',
  BetPlacedSuccessfully: 'BetPlacedSuccessfully',
  PlayerEscapedSuccessfully: 'PlayerEscapedSuccessfully',
  tokenVerify: 'tokenVerify',
  emailVerify: 'emailVerify',
  emailChangedSuccess: 'emailChangedSuccess',
  profileUpdatedSuccess: 'profileUpdatedSuccess',
  otpVerified: 'otpVerified',
  twoFADeactivated: 'twoFADeactivated',
  withdrawRequestSent: 'withdrawRequestSent',
  userReportedSuccessfully: 'userReportedSuccessfully',
  tipSendSuccessfully: 'tipSendSuccessfully',
  limitUpdatedSuccessFully: 'Limit Updated Successfully',
  cancelBetSuccessfully: 'cancelBetSuccessfully'
}
