import React, { useRef } from 'react'
import { Carousel } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { useDispatch, useSelector } from 'react-redux'
import { sendChatMessage } from 'redux-thunk/thunk/chat.thunk'

const Gif = ({ gifSearch, setGif }) => {
  const giphyFetch = new GiphyFetch('sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh')
  const gifRef = useRef(true)
  const { loginData } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.user)
  const {
    selectedRoomId,
    selectedChatGroupId
  } = useSelector((state) => state.chat)
  const dispatch = useDispatch()

  function CarouselDemo ({ onGifClick }) {
    if (gifRef.current) {
      const fetchGifs = (offset) => gifSearch ? giphyFetch.search(gifSearch, { offset, limit: 6 }) : giphyFetch.trending({ offset, limit: 6 })
      return <Carousel onGifClick={onGifClick} fetchGifs={fetchGifs} gifHeight={200} gutter={6} />
    } else {
      return <></>
    }
  }

  return (
    <CarouselDemo
      onGifClick={(gif, e) => {
        e.preventDefault()
        if (loginData?.accessToken && gif.embed_url) {
          dispatch(
            sendChatMessage({
              message: gif.embed_url,
              userName: user?.userName,
              languageId: selectedRoomId,
              groupId: selectedChatGroupId
            })
          )
          setGif(!gif)
          // setMessage((prev) => prev + gif.embed_url)
        }
        gifRef.current = false
        // setGif(false)
      }}
    />
  )
}

export default React.memo(Gif)
