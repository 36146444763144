import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrowRightShortIcon,
  RedditChatIcon,
  SteamChatIcon,
  TwitchChatIcon,
  TwitterChatIcon,
  SocialIcon
} from 'components/ui-kit/Icons/svg/index'

const SocialMedia = () => {
  const { t } = useTranslation()
  return (
    <div className='chat-modal-content'>
      <div className='chat-badge-wrap'>
        <div className='chat-inner-header d-flex align-items-center justify-content-between'>
          <div className='chat-badge-header-left d-flex align-items-center'>
            <SocialIcon className='mb-1' /> <h4 className='m-0'>{t('socialMedia')}</h4>
          </div>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center'
          >
            {t('allLinks')}
            <ArrowRightShortIcon />
          </a>
        </div>
        <div className='user-social-list-wrap d-flex align-items-center'>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center justify-content-center'
          >
            <TwitterChatIcon />
          </a>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center justify-content-center'
          >
            <RedditChatIcon />
          </a>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center justify-content-center'
          >
            <SteamChatIcon />
          </a>
          <a
            href='javascript:void(0);'
            className='d-flex align-items-center justify-content-center'
          >
            <TwitchChatIcon />
          </a>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SocialMedia)
