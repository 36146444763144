import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { SuccessMessage } from 'network/messages/successMessages'

// Get Total Limit
export const getUsersTotalLimitsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/get-user-limit', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

// Set Wager Limit
export const setUsersWagerLimitService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-wager-limit', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}

// Set Loss Limit
export const setUsersLossLimitService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-loss-limit', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}

// Set Deposit limit
export const setUsersDepositLimitService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-deposit-limit', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}

// Take a break or self exclusion
export const setUsersBreakOrExclusionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-account-disable', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}

// Session limit
export const setUsersSessionLimitService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-session-limit', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}

// Reset Session limit
export const setUsersResetLimitService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/reset-user-limit', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.limitUpdatedSuccessFully
  })
}
