import { createAsyncThunk } from '@reduxjs/toolkit'
import { BET_RESULT } from 'constants/index'
import * as mineGameService from 'network/services/mine.service'
// import { setNextServerSeedHash } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setRoundResult } from 'redux-thunk/redux/slices/mineGame.slice'

export const getMineGamePreviousRoundState = createAsyncThunk(
  'mineGame/get-previous-round-state', async (_, thunkApi) => {
    try {
      const res = await mineGameService.getPreviousRoundState()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const getMyBetsMineGame = createAsyncThunk(
  'mineGame/get-my-bets', async (data, thunkApi) => {
    try {
      const res = await mineGameService.getBetHistory(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGamePlacedBet = createAsyncThunk(
  'mineGame/place-bet', async ({ payload }, thunkApi) => {
    try {
      const res = await mineGameService.placeBet(payload)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameAutoBet = createAsyncThunk(
  'mineGame/auto-bet', async ({ payload }, thunkApi) => {
    try {
      const res = await mineGameService.autoBet(payload)
      // if (res?.nextServerSeedHash) {
      //   thunkApi.dispatch(setNextServerSeedHash(res?.nextServerSeedHash))
      // }
      setTimeout(() => {
        if (res?.result === BET_RESULT.LOST) {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        } else {
          thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
        }
      }, 2000)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameOpenTile = createAsyncThunk(
  'mineGame/opened-tile', async (payload, thunkApi) => {
    try {
      const { normalTileAudio, mineTileAudio, tile } = payload
      const { minesVolume } = thunkApi?.getState()?.mineGame
      const res = await mineGameService.openTile({ tile })
      // if (res?.nextServerSeedHash) {
      //   thunkApi.dispatch(setNextServerSeedHash(res?.nextServerSeedHash))
      // }

      if (res?.mineTile) {
        setTimeout(() => {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        }, 2000)
        mineTileAudio.volume = minesVolume
        mineTileAudio?.play()
      } else {
        normalTileAudio.volume = minesVolume
        normalTileAudio?.play()
      }
      res.tile = payload.tile
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameCashOut = createAsyncThunk(
  'mineGame/cash-out', async (_, thunkApi) => {
    try {
      const res = await mineGameService.cashOutBet()
      // if (res?.nextServerSeedHash) {
      //   thunkApi.dispatch(setNextServerSeedHash(res?.nextServerSeedHash))
      // }
      setTimeout(() => {
        thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
      }, 2000)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const minGameTopBets = createAsyncThunk(
  'mineGame/top-bets', async (_, thunkApi) => {
    try {
      const res = await mineGameService.getTopBets()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const verifyProvableFairMine = createAsyncThunk('crash-game/check-provable-fair', async (payload, thunkApi) => {
  try {
    const res = await mineGameService.verifyProvableFairMine(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMineGameLiveStats = createAsyncThunk(
  'mineGame/get-mine-game-live-stats', async (_, thunkApi) => {
    try {
      const res = await mineGameService.getLiveStats()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
