import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorMessage from 'components/layout/ErrorMessage'
import { showLoginOtpHandle } from 'redux-thunk/redux/slices/user.slice'
import {
  // forgetPassword,
  userLogin
} from 'redux-thunk/thunk/auth.thunk'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index.js'
// import SocialLinks from '../SocialLinks/index'

const Login = ({ showSignupPopup }) => {
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)

  const schema = yup.object().shape({
    userName: yup.string().required(`${t('signinErrorsUserNameRequired')}`),
    password: yup
      .string()
      .required(`${t('signinErrorsPasswordRequired')}`)
      .min(8, `${t('signinErrorsPasswordMinLength')}`)
      .max(16, `${t('signinErrorsPasswordMaxLength')}`)
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const onLoginSuccess = () => {
    if (location.pathname.match('/casino/play-game')) {
      history.replace(ROUTE_PATHS.CASINO)
    } else if (location.pathname?.match('/verify')) {
      history.replace(ROUTE_PATHS.HOME)
    } else {
      history.replace(location.pathname)
    }
    dispatch(setShowLoginPopup(false))
    dispatch(setShowSignupPopup(false))
  }

  const OnLoginError = (error) => {
    if (error[0].description === 'otp required') {
      dispatch(setShowLoginPopup(false))
      dispatch(showLoginOtpHandle(true))
      dispatch(setShowSignupPopup(false))
    }
  }

  const handleLoginSubmit = async (loginDetails) => {
    dispatch(
      userLogin({
        userNameOrEmail: loginDetails.userName,
        password: loginDetails.password,
        onLoginSuccess,
        OnLoginError
      })
    )
  }

  // const handleForgetPassword = async () => {
  //   if (username?.length > 0) {
  //     dispatch(forgetPassword({ userNameOrEmail: username }))
  //   } else {
  //     openErrorToaster({ message: t('signinResetPasswordUsernameErr') })(
  //   }
  // }

  return (
    <div
      className={`tab-pane fade ${!showSignupPopup && 'show active'}`}
      id='pills-home'
      role='tabpanel'
      aria-labelledby='pills-home-tab'
    >
      <div className='register-wrap'>
        <form onSubmit={handleSubmit(handleLoginSubmit)}>
          <div className='mb-2 form-group'>
            <label htmlFor='userEmail' className='form-label text-uppercase'>{t('email')}</label> <span className='color-red'>*</span>
            <input
              type='text'
              placeholder={t('emailPlaceholder')}
              className='form-control shadow-none'
              id='userName'
              name='userName'
              {...register('userName')}
              autoComplete='off'
              maxLength={50}
            />
            {errors && errors.userName && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input  ms-2 d-flex'
                message={errors.userName.message}
              />
            )}
          </div>
          <div className='mb-2 form-group'>
            <label htmlFor='userEmail' className='form-label text-uppercase'>{t('profilePassword')}</label> <span className='color-red'>*</span>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='********'
              className='form-control shadow-none'
              id='password'
              name='password'
              autoComplete='off'
              {...register('password')}
              maxLength={50}
              minLength={8}
              aria-label='Username'
              aria-describedby='button-addon1'
            />
            <a
              onClick={() =>
                setShowPassword((prevState) => !prevState)}
              className='show-password'
            >
              {!showPassword
                ? (
                  <i className='fas fa-eye' />
                  )
                : (
                  <i className='fas fa-eye-slash' aria-hidden='true' />
                  )}
            </a>
            {/* <a href='javascript:void(0);' className='show-password'>
              <img src='/assets/images/homePage/eye-fill.svg' alt='Show' onClick={() => setShowPassword(prev => !prev)} />
            </a> */}
            {errors && errors.password && (
              <ErrorMessage
                className='color-red text-danger error-msg login-input  ms-2 d-flex'
                message={errors.password.message}
              />
            )}
          </div>
          <div className='auth-btn-wrap'>
            <button
              type='submit'
              className='btn btn-secondary text-uppercase w-100'
              disabled={loading}
            >
              {
                loading
                  ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  : <span>{t('signIn')}</span>
              }
            </button>
          </div>
          {/* <div className='auth-socail-wrap'>
            <div className='auth-socail-heading'>
              <h6><span>{t('orContinueWith')}</span></h6>
            </div>
          </div> */}
          {/* <SocialLinks isLoginSignup /> */}
          <div className='auth-mob-instructions d-block d-lg-none'>
            <p>
              {t('usersConsent')}
              <NavLink to='#'>{t('termsOfService')}</NavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
