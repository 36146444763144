import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setShowWalletPopup } from 'redux-thunk/redux/slices/wallet.slice'
import { withdrawRequest } from 'redux-thunk/thunk/user.thunk'
import ErrorMessage from 'components/layout/ErrorMessage'
import { restrictInputToNumeric } from 'utils/helperFunctions.utils'

export const Withdraw = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedWallet } = useSelector(state => state.user)

  const schema = yup.object().shape({
    amount: yup.number()
      .typeError(t('amountIsRequired'))
      .required(t('amountIsRequired'))
      .moreThan(1, t('minWithdrawAmount', { min: 1 }))
      .lessThan(selectedWallet?.amount, t('withdrawMustLessThanWallet')),
    accountType: yup.string().required(t('accountTypeIsRequired')),
    accountNumber: yup.string()
      .when('accountType', {
        is: (val) => val === 'PHONE',
        then: yup.string()
          .length(11, t('mobileNumberLength', { length: 11 }))
          .required(t('phoneIsRequired')),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'EMAIL',
        then: yup.string()
          .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email is not valid')
          .required(t('profileErrorsEmailRequired')),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'CPF',
        then: yup.string()
          .length(11, t('cpfError', { length: 11 }))
          .required(t('cpfRequiredError')),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'CNPJ',
        then: yup.string()
          .length(14, t('cnpjError', { length: 14 }))
          .required('CNPJ is required'),
        otherwise: yup.string().notRequired()
      }),
    customerCertification: yup.string()
      .required('Customer Certification is required')
      .length(11, 'Customer Certification must be of 11 characters')
  })

  const {
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const selectedAccountType = watch('accountType', 'PHONE')

  const maxLegthOfInput = () => {
    if (selectedAccountType === 'CPF' || selectedAccountType === 'PHONE') {
      return 11
    }
    if (selectedAccountType === 'CNPJ') {
      return 14
    }
    if (selectedAccountType === 'EMAIL') {
      return 320
    }
  }

  const handleDepositSubmit = (values) => {
    dispatch(withdrawRequest({ values }))
    dispatch(setShowWalletPopup(false))
  }
  return (
    <div className='tab-pane fade text-white' id='pills-withdraw' role='tabpanel' aria-labelledby='pills-profile-tab'>
      <form onSubmit={handleSubmit(handleDepositSubmit)}>
        <div className='row g-2'>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Amount</label>
            <input
              type='text'
              onInput={(e) => restrictInputToNumeric(e)}
              className='form-control'
              id='amount'
              name='amount'
              autoComplete='off'
              maxLength={parseInt(selectedWallet?.amount).toString().length + 1}
              {...register('amount')}
            />
            {errors && errors.amount && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.amount.message}
              />
            )}
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Account Type</label>
            <select
              className='form-control form-select'
              name='accountType'
              id='accountType'
              aria-label='Default select example'
              onClick={async () => {
                await trigger('accountNumber')
              }}
              {...register('accountType')}
            >
              <option value='PHONE'>Phone</option>
              <option value='CPF'>CPF</option>
              <option value='CNPJ'>CNPJ</option>
              <option value='EMAIL'>Email</option>
            </select>
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Account Number</label>
            <input
              type='text'
              className='form-control'
              id='accountNumber'
              name='accountNumber'
              autoComplete='off'
              maxLength={maxLegthOfInput()}
              {...register('accountNumber')}
            />
            {errors && errors.accountNumber && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.accountNumber.message}
              />
            )}
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='customerCertification'>Customer Certification (CPF)</label>
            <input
              type='text'
              className='form-control'
              id='customerCertification'
              name='customerCertification'
              autoComplete='off'
              maxLength={11}
              {...register('customerCertification')}
            />
            {errors && errors.customerCertification && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.customerCertification.message}
              />
            )}
          </div>
          <div>
            <button
              type='submit'
              className='btn btn-primary mt-2 mb-3'
              style={{ width: '100%', lineHeight: '40px' }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
