import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { chatAvtar, goldICon } from 'components/ui-kit/Icons/png/index'
import {
  getChatRules,
  getChats,
  getLanguageRooms,
  getUserDetails
} from 'redux-thunk/thunk/chat.thunk'
import {
  setShowReportUserPopup,
  setShowUserDetailsPopup
} from 'redux-thunk/redux/slices/settings.slice'
import { setChat, setUserToReport } from 'redux-thunk/redux/slices/chat.slice'
import { ChatSportsIcon, ReportIcoIcon } from 'components/ui-kit/Icons/svg/index'
import Announcements from '../Announcements/index'
import useLiveChatSocketNameSpace from 'socket-resources/hooks/useChatNameSpace'
import { capitalize } from 'lodash'
// import { toUtcTime } from 'utils/helperFunctions.utils'

const ChatContent = ({ bottomRef }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loginData } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.user)
  const { chat, selectedRoomId, selectedChatGroupId } = useSelector(
    (state) => state.chat
  )
  // const { [LOADER_HANDLER_TYPES.chat]: loading } = useSelector(state => state.loader)
  const { listenLiveChatsUpdateEvent } = useLiveChatSocketNameSpace()
  const regex = /@{([^}]+)}/
  const GLOBAL_GROUP_ID = '1'

  const handleFetchUserInformation = (id) => {
    dispatch(getUserDetails({ userId: id }))
  }

  const handleScroll = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'instant' })
  }

  const [isNewMessage, setIsNewMessage] = useState(false)

  useEffect(() => {
    if (isNewMessage) {
      const timeoutId = setTimeout(() => {
        setIsNewMessage(false)
      }, 10000)

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId)
    }
  }, [isNewMessage])

  const handleLiveChatsData = (e) => {
    setIsNewMessage(false)
    dispatch(setChat(e?.data))
    setIsNewMessage(true)
  }

  const handleShowUserDetails = (userId) => {
    handleFetchUserInformation(userId)
    dispatch(setShowUserDetailsPopup(true))
  }

  const shouldShowChat = (chat) => {
    const isDifferentUserWithOffensiveContent =
      user?.id?.toString() !== chat?.userId?.toString() &&
      chat?.isContainOffensiveWord
    const isGlobalGroup = selectedChatGroupId?.toString() === GLOBAL_GROUP_ID
    if (isGlobalGroup) {
      const isNotSameChatRoom =
        chat?.languageId?.toString() !== selectedRoomId?.toString()
      if (isNotSameChatRoom) return false
    } else {
      const isNotSameChatGroup =
        chat?.groupId?.toString() !== selectedChatGroupId?.toString()
      if (isNotSameChatGroup) return false
    }
    return !isDifferentUserWithOffensiveContent
  }

  useEffect(() => {
    dispatch(
      getChats({
        limit: 20,
        startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        languageId: selectedRoomId,
        groupId: selectedChatGroupId
      })
    )
    handleScroll()
  }, [selectedRoomId, selectedChatGroupId])

  useEffect(() => {
    dispatch(getLanguageRooms())
    dispatch(getChatRules())
    const cleanUpListener = listenLiveChatsUpdateEvent(handleLiveChatsData)
    return () => {
      cleanUpListener()
    }
  }, [])

  return (
    <div className='chat-content-scroll'>
      <Announcements />
      <div className='chat-content-wrap'>
        {chat?.rows?.filter(shouldShowChat)?.map((userChat, idx) => {
          const splittedMessage = userChat?.message?.split(' ')
          return (
            <div key={userChat?.id || uuidv4()}>
              {chat?.rows?.filter(shouldShowChat).length - 1 === idx && isNewMessage && userChat.userId !== user.id &&
                <p className='new_message'>{t('newMessage')}</p>}
              <div className='chat-card'>
                <div className='chat-card-head '>
                  <p
                    className='chat-text user-clr-one'
                    onClick={() => {
                      handleShowUserDetails(userChat?.userId)
                    }}
                  >
                    <img
                      width={26}
                      height={26}
                      src={userChat?.profileImageUrl || chatAvtar}
                      alt='Avtar'
                      className='cursor-pointer'
                    />
                      &nbsp;
                    <ChatSportsIcon />
                      &nbsp;
                    <ChatSportsIcon />
                      &nbsp;
                    <ChatSportsIcon />
                      &nbsp;
                    <span
                      className='chat-user-label cursor-pointer me-1'
                      style={{ color: userChat?.moreDetails?.color }}
                    >
                      {capitalize(userChat?.userName)}:
                    </span>
                    {userChat?.amount
                      ? (
                        <>
                          <img src={goldICon} alt='Coin' className='me-1' />
                          <span className='tip-amount'>
                            ${userChat.amount}
                          </span>{' '}
                          {t('tipFor')}{' '}
                          <span
                            className='cursor-pointer'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleFetchUserInformation(userChat.receiverId)
                              dispatch(setShowUserDetailsPopup(true))
                            }}
                          >
                            {userChat.receiverName}
                          </span>
                        </>
                        )
                      : (
                          splittedMessage?.map((string, idx) => {
                            if (string.includes('@{}')) {
                              return string
                            }
                            if (string.includes('https://giphy.com')) {
                              return <iframe className='gif_img' src={string} alt={string} />
                            }
                            if (
                              string?.includes('@{') &&
                          string?.includes('}') &&
                          string?.includes('^*+@!#+*^') &&
                          {
                            /* isPresentInMentionsList(string) */
                          }
                            ) {
                              const splittedString = string?.split('^*+@!#+*^')
                              return (
                                <span key={idx}>
                                  <span
                                    className='cursor-pointer tagged-name'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleFetchUserInformation(splittedString[1])
                                      dispatch(setShowUserDetailsPopup(true))
                                    }}
                                  >
                                    {`@${capitalize(string?.match(regex)[1])} `}
                                  </span>
                                  {splittedString?.length > 2
                                    ? splittedString[2]
                                    : ' '}
                                </span>
                              )
                            }
                            return `${string} `
                          })
                        )}
                  </p>
                  <div>
                    {/* <p className='chat-time ms-auto'>{toUtcTime(userChat?.createdAt, 'hh:mm')}</p> */}
                    {userChat?.userName !== user?.userName &&
                      loginData?.accessToken && (
                        <div
                          className='report-user-wrap'
                          onClick={() => {
                            dispatch(setShowReportUserPopup(true))
                            dispatch(
                              setUserToReport({
                                id: userChat?.userId,
                                name: userChat?.userName
                              })
                            )
                          }}
                        >
                          <ReportIcoIcon />
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div id='empty-chat' className='empty-chat' ref={bottomRef} />
      </div>
    </div>
  )
}

export default ChatContent
