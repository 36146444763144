import { createAsyncThunk } from '@reduxjs/toolkit'
import { allRoundPlacedBets, cancelCrashBetService, crashCheckFairnessService, escapeCrashBetService, getAllBetDataService, getAllRoundHistoryService, getCrashGameRoundDetailService, getHighrollerCrashService, getMyBetsService, getTotalWinningAmountService, placeBetCrashGameService } from 'network/services/crashGame.service'
import { setPlacedCrashBetId } from 'redux-thunk/redux/slices/crashGame.slice'

export const placedBetCrashGame = createAsyncThunk('crash-game/place-bet-crash-game', async ({ payload }, thunkApi) => {
  try {
    const res = await placeBetCrashGameService(payload)
    thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const escapeCrashBet = createAsyncThunk('crash-game/player-escape-crashGame', async (_, thunkApi) => {
  try {
    const res = await escapeCrashBetService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cancelCrashBet = createAsyncThunk('crash-game/cancel-bet-crash-game', async (_, thunkApi) => {
  try {
    const res = await cancelCrashBetService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllBetData = createAsyncThunk('crash-game/get-crash-game-bets-data', async (_, thunkApi) => {
  try {
    const res = await getAllBetDataService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getTotalWinningAmount = createAsyncThunk('crash-game/get-winning-amount-data', async (_, thunkApi) => {
  try {
    const res = await getTotalWinningAmountService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllRoundHistory = createAsyncThunk('crash-game/get-crash-game-history', async ({ offset, limit }, thunkApi) => {
  try {
    const res = await getAllRoundHistoryService({ offset, limit })
    return res
  } catch (error) {
    console.log('error', error)
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getCrashGameRoundDetails = createAsyncThunk('crash-game/get-crash-game-round-detail', async (_, thunkApi) => {
  try {
    const res = await getCrashGameRoundDetailService()

    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBets = createAsyncThunk('crash-game/myBets', async (_, thunkApi) => {
  try {
    const res = await getMyBetsService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const checkFairnessCrashGame = createAsyncThunk('crash-game/check-fairness', async ({ payload }, thunkApi) => {
  try {
    const res = await crashCheckFairnessService(payload)
    thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllbetOfOneRound = createAsyncThunk('crash-game/get-all-bets-of-round', async ({ params }, thunkApi) => {
  try {
    const res = await allRoundPlacedBets({ params })
    thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getCrashHighRollerTransactions = createAsyncThunk(
  'crash-game/high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getHighrollerCrashService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

// import { createAsyncThunk } from '@reduxjs/toolkit'
// import { cancelCrashBetService, escapeCrashBetService, getAllBetDataService, getAllRoundHistoryService, getAllbetOfOneRoundService, getCrashGameRoundDetailService, getMyBetsService, getTotalWinningAmountService, placeBetCrashGameService, verifyProvableFairCrashService } from 'network/services/auth.service'
// import { setPlacedCrashBetId } from '../redux/slices/crashGame.slice'

// export const placedBetCrashGame = createAsyncThunk('crash-game/place-bet-crash-game', async ({ payload }, thunkApi) => {
//   try {
//     const res = await placeBetCrashGameService(payload)
//     thunkApi.dispatch(setPlacedCrashBetId(res?.id))
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const escapeCrashBet = createAsyncThunk('crash-game/player-escape-crashGame', async (thunkApi) => {
//   try {
//     const res = await escapeCrashBetService()
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const cancelCrashBet = createAsyncThunk('crash-game/cancel-bet-crash-game', async (thunkApi) => {
//   try {
//     const res = await cancelCrashBetService()
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getAllBetData = createAsyncThunk('crash-game/get-crash-game-bets-data', async (_, thunkApi) => {
//   try {
//     const res = await getAllBetDataService()
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getTotalWinningAmount = createAsyncThunk('crash-game/get-winning-amount-data', async (_, thunkApi) => {
//   try {
//     const res = await getTotalWinningAmountService()
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getAllRoundHistory = createAsyncThunk('crash-game/get-crash-game-history', async ({ limit, offset }, thunkApi) => {
//   try {
//     const params = { limit, offset }
//     const res = await getAllRoundHistoryService(params)
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getCrashGameRoundDetails = createAsyncThunk('crash-game/get-crash-game-round-detail', async ({ roundId }, thunkApi) => {
//   try {
//     const res = await getCrashGameRoundDetailService(roundId)

//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getMyBets = createAsyncThunk('crash-game/myBets', async (_, thunkApi) => {
//   try {
//     const res = await getMyBetsService()
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const getAllbetOfOneRound = createAsyncThunk('crash-game/get-bets-of-one-round', async ({ roundId }, thunkApi) => {
//   try {
//     const res = await getAllbetOfOneRoundService({ roundId })
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })

// export const verifyProvableFairCrash = createAsyncThunk('crash-game/check-provable-fair', async ({ payload }, thunkApi) => {
//   try {
//     const res = await verifyProvableFairCrashService(payload)
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })
