import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getChatGroupsService,
  getChatRulesService,
  getChatsService,
  getLanguageRoomsService,
  getUserDetailsService,
  reportUserService,
  sendChatMessageService,
  tipUserService
} from 'network/services/chat.service'
import { setShowReportUserPopup, setShowUserDetailsPopup } from 'redux-thunk/redux/slices/settings.slice'

export const sendChatMessage = createAsyncThunk(
  'chat/send-message',
  async (data, thunkApi) => {
    try {
      const res = await sendChatMessageService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserDetails = createAsyncThunk(
  'user/details-chat-popup',
  async (params, thunkApi) => {
    try {
      const res = await getUserDetailsService(params)
      return res
    } catch (error) {
      thunkApi.dispatch(setShowUserDetailsPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const tipUser = createAsyncThunk(
  'user/tip',
  async (data, thunkApi) => {
    try {
      const res = await tipUserService(data)
      const { user } = thunkApi?.getState()?.user
      const { showTipInChat, selectedRoomId } = thunkApi?.getState()?.chat
      if (showTipInChat) {
        thunkApi.dispatch(sendChatMessage({
          message: `$${data.amount} tip for @{${data.receiverName}}`,
          userName: user?.userName,
          amount: data?.amount,
          receiverName: data?.receiverName,
          receiverId: data?.receiverId,
          languageId: selectedRoomId
        }))
      }
      return res
    } catch (error) {
      thunkApi.dispatch(setShowUserDetailsPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getChatRules = createAsyncThunk(
  'chat/get-rules',
  async (thunkApi) => {
    try {
      const res = await getChatRulesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const reportUser = createAsyncThunk(
  'chat/report-user',
  async (data, thunkApi) => {
    try {
      const res = await reportUserService(data)
      return res
    } catch (error) {
      thunkApi?.dispatch(setShowReportUserPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getChats = createAsyncThunk(
  'fetch/chats',
  async (data, thunkApi) => {
    try {
      const res = await getChatsService(data)
      return res
    } catch (error) {
      thunkApi?.dispatch(setShowReportUserPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getLanguageRooms = createAsyncThunk(
  'fetch/language-rooms',
  async (data, thunkApi) => {
    try {
      const res = await getLanguageRoomsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getChatGroups = createAsyncThunk(
  'fetch/chat-groups',
  async (data, thunkApi) => {
    try {
      const res = await getChatGroupsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
